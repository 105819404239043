var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"background mb-1"},[_c('img',{staticClass:"img-fluid img-contact",attrs:{"src":require("@/assets/bg-contact.svg"),"alt":"Imagem"}}),_c('div',{staticClass:"bg-color"}),_c('div',{staticClass:"container py-5 h100"},[_c('div',{staticClass:"row justify-content-between contact pt-3"},[_c('div',{staticClass:"col-lg-7 img-hide"},[_c('img',{staticClass:"img-fluid img-intro-contact",attrs:{"src":require("@/assets/img-intro-contact.png"),"alt":"Imagem"}}),_c('div',{staticClass:"panelsite-link"},[(_vm.$store.getters.lang === 'en')?_c('span',[_vm._v(" Looking to join our survey panel? "),_c('a',{attrs:{"target":"_blank","href":"https://www.queopinas.com/?locale=en_US"}},[_vm._v("Click here.")])]):(_vm.$store.getters.lang === 'pt')?_c('span',[_vm._v(" Quer participar do nosso painel de pesquisa? "),_c('a',{attrs:{"target":"_blank","href":"https://www.voceopina.com.br"}},[_vm._v("Clique aqui.")])]):_c('span',[_vm._v(" ¿Buscas unirte a nuestro panel de encuestas? "),_c('a',{attrs:{"target":"_blank","href":"https://www.queopinas.com/?locale=es_US"}},[_vm._v("Haz click aquí.")])])])]),_c('div',{staticClass:"col-lg-5 survey"},[_c('div',{staticClass:"title pb-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.get_in_touch : "Get in touch!")+" ")])]),(_vm.$store.getters.lang === 'en')?_c('form',{on:{"submit":_vm.applyJob}},[_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"name","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.name
                  : 'Name'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.email
                  : 'E-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-2 mb-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-####'),expression:"'+# (###) ###-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"form-control p-4 input input-tel",attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.phone
                  : 'Phone'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control p-4 input input-country",attrs:{"type":"text","id":"country","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.country
                  : 'Country'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.company
                  : 'Company'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"mb-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"id":"msg","rows":"5","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.message
                  : 'Message'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}})]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true,"id":"recaptcha"},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"margin-bottom"},[_c('b-form-checkbox',{staticClass:"check text-input-checkbox",attrs:{"id":"request_panelbook","name":"checkbox-1","value":"accepted","unchecked-value":"0"},model:{value:(_vm.request_panelbook),callback:function ($$v) {_vm.request_panelbook=$$v},expression:"request_panelbook"}},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.request_our_panel_book : "Request our Panel Book")+" ")]),_c('button',{staticClass:"btn btn-send btn_bottom"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Message")+" ")])],1)]):_vm._e(),(_vm.$store.getters.lang === 'es')?_c('form',{on:{"submit":_vm.applyJob}},[_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"name","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.name
                  : 'Name'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.email
                  : 'E-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-2 mb-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+## ####-####-####-####'),expression:"'+## ####-####-####-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"form-control p-4 input input-tel",attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.phone
                  : 'Phone'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control p-4 input input-country",attrs:{"type":"text","id":"country","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.country
                  : 'Country'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.company
                  : 'Company'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"mb-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"id":"msg","rows":"5","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.message
                  : 'Message'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}})]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true,"id":"recaptcha"},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"margin-bottom"},[_c('b-form-checkbox',{staticClass:"check text-input-checkbox",attrs:{"id":"request_panelbook","name":"checkbox-1","value":"accepted","unchecked-value":"0"},model:{value:(_vm.request_panelbook),callback:function ($$v) {_vm.request_panelbook=$$v},expression:"request_panelbook"}},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.request_our_panel_book : "Request our Panel Book")+" ")]),_c('button',{staticClass:"btn btn-send btn_bottom"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Message")+" ")])],1)]):_vm._e(),(_vm.$store.getters.lang === 'pt')?_c('form',{on:{"submit":_vm.applyJob}},[_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"name","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.name
                  : 'Name'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.email
                  : 'E-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-2 mb-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+## (##) #####-####'),expression:"'+## (##) #####-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"form-control p-4 input input-tel",attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.phone
                  : 'Phone'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control p-4 input input-country",attrs:{"type":"text","id":"country","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.country
                  : 'Country'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.company
                  : 'Company'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"mb-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"id":"msg","rows":"5","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.message
                  : 'Message'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}})]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true,"id":"recaptcha"},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"margin-bottom"},[_c('b-form-checkbox',{staticClass:"check text-input-checkbox",attrs:{"id":"request_panelbook","name":"checkbox-1","value":"accepted","unchecked-value":"0"},model:{value:(_vm.request_panelbook),callback:function ($$v) {_vm.request_panelbook=$$v},expression:"request_panelbook"}},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.request_our_panel_book : "Request our Panel Book")+" ")]),_c('button',{staticClass:"btn btn-send btn_bottom"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Message")+" ")])],1)]):_vm._e(),_c('div',{staticClass:"panelsite-link mobile"},[(_vm.$store.getters.lang === 'en')?_c('span',[_vm._v(" Looking to join our survey panel? "),_c('a',{attrs:{"target":"_blank","href":"https://www.queopinas.com/?locale=en_US"}},[_vm._v("Click here.")])]):(_vm.$store.getters.lang === 'pt')?_c('span',[_vm._v(" Quer participar do nosso painel de pesquisa? "),_c('a',{attrs:{"target":"_blank","href":"https://www.voceopina.com.br"}},[_vm._v("Clique aqui")])]):_c('span',[_vm._v(" ¿Buscas unirte a nuestro panel de encuestas? "),_c('a',{attrs:{"target":"_blank","href":"https://www.queopinas.com/?locale=es_US"}},[_vm._v("Haz click aquí.")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }