<template>
  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center">
        <h2 class="title">
          {{
            $store.getters.trans
              ? $store.getters.trans.lgpd.personal_data_explanation.title
              : ""
          }}
        </h2>
      </div>
      <div
        class="row pt-5 justify-content-center align-items-center height-row"
      >
        <div class="col-lg-3">
          <div class="item">
            <h4 class="title-items">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.personal_data_explanation
                      .personal_data.title
                  : ""
              }}
            </h4>
            <p class="sub-titles">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.personal_data_explanation
                      .personal_data.sub_title
                  : ""
              }}
            </p>
            <p class="text">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.personal_data_explanation
                      .personal_data.text
                  : ""
              }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 img-personal">
          <img src="@/assets/personal-data.svg" />
        </div>
        <div class="col-lg-3 third-col">
          <div class="space">
            <div class="item">
              <h4 class="title-items">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.personal_data_explanation
                        .sensitive_personal_data.title
                    : ""
                }}
              </h4>
              <p class="sub-titles">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.personal_data_explanation
                        .sensitive_personal_data.sub_title
                    : ""
                }}
              </p>
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.personal_data_explanation
                        .sensitive_personal_data.text
                    : ""
                }}
              </p>
            </div>
          </div>
          <div>
            <div class="item">
              <h4 class="title-items">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.personal_data_explanation
                        .anonymized_data.title
                    : ""
                }}
              </h4>
              <p class="sub-titles">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.personal_data_explanation
                        .anonymized_data.sub_title
                    : ""
                }}
              </p>
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.personal_data_explanation
                        .anonymized_data.text
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-5 padding-secondRow justify-content-center">
        <div class="col-12">
          <h2 class="title">
            {{
              $store.getters.trans
                ? $store.getters.trans.lgpd.exigency_lgpd.title
                : ""
            }}
          </h2>
        </div>
        <p class="text-lgpd">
          {{
            $store.getters.trans
              ? $store.getters.trans.lgpd.exigency_lgpd.sub_title
              : ""
          }}
        </p>
      </div>
      <div class="row pt-5">
        <div class="col-md-6 col-xl-4 mb-5 d-flex justify-content-center">
          <div class="box">
            <div class="d-flex align-items-center content">
              <img src="@/assets/lock.svg" alt="lock" class="img-box" />
              <h5 class="title-box ml-2">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.exigency_lgpd.items
                        .privacy_warning.title
                    : ""
                }}
              </h5>
            </div>
            <p class="text-box">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.exigency_lgpd.items
                      .privacy_warning.text
                  : ""
              }}
            </p>
          </div>
        </div>

        <div class="col-md-6 col-xl-4 mb-5 d-flex justify-content-center">
          <div class="box">
            <div class="d-flex align-items-center content">
              <img src="@/assets/like.svg" alt="like" class="img-box" />
              <h5 class="title-box ml-2">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.exigency_lgpd.items
                        .user_consentment.title
                    : ""
                }}
              </h5>
            </div>
            <p class="text-box">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.exigency_lgpd.items
                      .user_consentment.text
                  : ""
              }}
            </p>
          </div>
        </div>

        <div class="col-md-6 col-xl-4 mb-5 d-flex justify-content-center">
          <div class="box">
            <div class="d-flex align-items-center content">
              <img src="@/assets/block.svg" alt="block" class="img-box" />
              <h5 class="title-box ml-2">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.exigency_lgpd.items
                        .revocation_consentment.title
                    : ""
                }}
              </h5>
            </div>
            <p class="text-box">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.exigency_lgpd.items
                      .revocation_consentment.text
                  : ""
              }}
            </p>
          </div>
        </div>

        <div class="col-md-6 col-xl-4 mb-5 d-flex justify-content-center">
          <div class="box">
            <div class="d-flex align-items-center content">
              <img src="@/assets/trash.svg" alt="trash" class="img-box" />
              <h5 class="title-box ml-2">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.exigency_lgpd.items
                        .data_exclusion.title
                    : ""
                }}
              </h5>
            </div>
            <p class="text-box">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.exigency_lgpd.items.data_exclusion
                      .text
                  : ""
              }}
            </p>
          </div>
        </div>

        <div class="col-md-6 col-xl-4 mb-5 d-flex justify-content-center">
          <div class="box">
            <div class="d-flex align-items-center content">
              <img src="@/assets/doc.svg" alt="doc" class="img-box" />
              <h5 class="title-box ml-2">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.exigency_lgpd.items
                        .data_transfer.title
                    : ""
                }}
              </h5>
            </div>
            <p class="text-box">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.exigency_lgpd.items.data_transfer
                      .text
                  : ""
              }}
            </p>
          </div>
        </div>

        <div class="col-md-6 col-xl-4 mb-5 d-flex justify-content-center">
          <div class="box">
            <div class="d-flex align-items-center content">
              <img
                src="@/assets/magnifying-glass.svg"
                alt="block"
                class="img-box"
              />
              <h5 class="title-box ml-2">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.exigency_lgpd.items.transparency
                        .title
                    : ""
                }}
              </h5>
            </div>
            <p class="text-box">
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.exigency_lgpd.items.transparency
                      .text
                  : ""
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 300;
  font-size: 2rem;
  text-align: center;
  color: #32363b;
}
.height-row {
  min-height: 722px;
}
.item {
  border: 1px dashed #ddd;
  padding: 8px 16px;
  .title-items {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 15px;
    line-height: 1.2;
    color: #4f8cb0;
  }
  .sub-titles {
    font-weight: 400;
    line-height: 1.2;
    font-size: 1rem;
    color: #32363b;
  }
  .text {
    font-weight: 400;
    font-size: 0.85rem;
    color: #666;
  }
}

.text-lgpd {
  margin-top: 30px;
  color: #666;
  text-align: center;
  max-width: 800px;
}

.box {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4), 0 0 25px rgba(0, 0, 0, 0.2);
  padding: 42px;
  border-radius: 15px;
  background: #fff;
  width: 332px;
  height: 203px;

  .content {
    height: 38px;
  }

  .img-box {
    width: 43px;
    height: 35px;
  }

  .title-box {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #4f8cb0;
  }

  .text-box {
    color: #32363b;
    font-weight: 400;
    font-size: 0.8rem;
    padding-top: 16px;
    line-height: 1.2;
  }
}

.padding-secondRow {
  padding: 0 15px;
}

.space {
  margin-bottom: 54px;
}

@media (max-width: 991.98px) {
  .img-personal {
    display: none;
  }
  .item {
    .title-items {
      text-align: center;
    }
    .sub-titles {
      text-align: center;
    }
    .text {
      text-align: center;
    }
  }
  .third-col {
    margin-top: 54px;
  }
}
@media (max-width: 767.98px) {
  .box {
    .text-box {
      text-align: center;
    }
  }
}
</style>
