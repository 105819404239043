<template>
  <section>
    <div class="container">
      <h2>1. Introduction</h2>
      <p>
        1.1. These Terms and Conditions ("Terms") set forth the terms and
        conditions under which <strong>OFFERWISE</strong> will provide market
        research services to clients.
      </p>

      <p>
        1.2. By contracting our services, the Client agrees to accept and fully
        adhere to these Terms.
      </p>

      <h2>2. Market Research Services</h2>

      <p>
        2.1. <strong>OFFERWISE</strong> is committed to providing market
        research services as outlined in the quotation issued to the Client.
      </p>

      <p>
        2.2. The budget is valid for the services and deliverables specified in
        the proposal. Should there be any changes in the project’s scope, both
        cost and delivery time will be subject to revision.
      </p>

      <h2>3. Confidentiality</h2>

      <p>
        3.1. All information, data and results obtained during the study will be
        treated as strictly confidential. <strong>OFFERWISE</strong> will not
        disclose, share, or use this information for purposes unrelated to the
        study in question, except with explicit authorization from the Client.
      </p>

      <p>
        3.2. The Client is required to seek authorization from
        <strong>OFFERWISE</strong> before publishing any data related to a study
        in the media.
      </p>

      <h2>4. Data Protection</h2>

      <p>
        4.1. <strong>OFFERWISE</strong> assures the Client that it has obtained
        the consent of its panelists/respondents for participation in market
        research studies and adheres to the applicable data protection laws.
      </p>

      <p>
        4.2. <strong>OFFERWISE</strong> will not, under any circumstances, share
        panelists' information with the Client. Should the Client wish to offer
        incentives to panelists eligible for the commissioned survey, with the
        intent of acquiring potential clients, such a request must be explicitly
        made. This ensures that the panelist can provide informed consent for
        this specific purpose, considering that the intended outcome is not
        related to the panelist's relationship with OFFERWISE.
      </p>

      <p>
        4.3. Survey data will be securely stored on our AWS server. Data
        collected during the study will be retained by OFFERWISE for a period of
        12 months following the conclusion of the study. If an extension of this
        retention period is required, we will arrange a prior agreement with the
        client, taking into account the project's specific requirements and any
        extra legal or contractual duties.
      </p>

      <h2>5. Payment Terms</h2>

      <p>
        5.1. The proposal shall be valid for 30 days from the date of its
        submission to the Client. Should the Client decide to accept the
        proposal after this period, contacting <strong>OFFERWISE</strong> to
        confirm the availability and the possibility of retaining the same terms
        and conditions will be required.
      </p>

      <p>
        5.2. Payments are to be made as per the terms and methods outlined in
        the contract. <strong>OFFERWISE</strong> will issue an invoice upon
        delivery of services and allow a 30-day window for payment, unless
        alternative payment arrangements have been previously agreed upon with
        the Client.
      </p>

      <p>
        5.3. For new Customers, an advance payment of 50% of the total cost will
        be required, with the remaining balance due upon completion of the
        delivery.
      </p>

      <p>
        5.4. For projects over US$ 7,500, an advance payment covering 25% of the
        total cost is required.
      </p>

      <p>
        5.5. All projects are subject to a minimum fee of USD 300, or its
        equivalent in local currency.
      </p>

      <h2>
        6. Late payment
      </h2>

      <p>
        6.1. <strong>OFFERWISE</strong> reserves the right to take legal action
        against customers who fail to make the promised payments.
      </p>

      <p>
        6.2. Should there be a delay in payment exceeding 100 days from the
        invoice's due date, the outstanding amount will incur a compound
        interest of 4% per month.
      </p>

      <div>
        <p class="main">
          6.3. Upon completion of the project, the client will have a 72-hour
          window to verify or amend the final metrics. If they fail to do so,
          they will be invoiced based on the information as recorded by
          <strong>OFFERWISE</strong>.
        </p>

        <p>
          6.3.1. If an invoice modification is requested due to discrepancies in
          the metrics information and the Client did not confirm the data within
          the allocated 72-hour period, an additional fee of 10% of the invoice
          value will be charged to the Client.
        </p>
      </div>

      <h2>7. Cancellation and Modification</h2>

      <p>
        7.1. Any cancellation or modification of the services must be agreed in
        writing between <strong>OFFERWISE</strong> and the Client.
      </p>

      <p>
        7.2. Should there be any alterations to the services agreed upon after
        the commercial proposal has been issued, both parties must mutually
        agree in writing on the revised billing terms. This ensures continued
        transparency and consensus between the parties involved.
      </p>

      <h3>7.3. Deviations:</h3>

      <div>
        <p>
          7.3.1. Any alteration in the metrics greater than 20% will imply
          reprice at the time of the soft launch or, if necessary, at the full
          launch.
        </p>

        <p>
          7.3.2. For filters applied that extend the survey duration beyond 3
          minutes, an additional charge of 20% of the Cost Per Interview (CPI)
          will be incurred.
        </p>

        <p>
          7.3.3. Should the project abandonment rate surpass the estimated 20%
          for an average field, a repricing adjustment will be made to the cost
          per completed interview.
        </p>

        <p>
          7.3.4. "Should the project be cancelled at any point after the link
          test phase, a minimum service fee of US$300 will be applicable.
        </p>
      </div>

      <h2>8. Service Specifications</h2>

      <h3>8.1. Questionnaire Design and Programming:</h3>

      <div>
        <p>
          8.1.1. After the questionnaire has been approved and scheduled,
          modifications of up to 10% of the document are permissible. Any
          changes beyond this threshold will incur an adjustment fee, calculated
          based on the initial design and/or programming value of the
          questionnaire.
        </p>

        <p>
          8.1.2. For tracker studies, any changes exceeding 20% in the
          scheduling must be indicated at the time of the quotation or upon
          approval of the new wave.
        </p>
      </div>

      <h3>
        8.2. Field
      </h3>

      <div>
        <p>
          8.2.1. Introducing or altering quotas may result in adjustments to the
          initially set deadlines for field closures.
        </p>
      </div>

      <h3>8.3. Processing</h3>

      <div>
        <p>
          8.3.1. Standard Processing includes up to three banners/variables.
          Should there be a need for more than this, it will be classified as
          advanced processing, necessitating adjustments to costs and timelines
          in accordance with the request.
        </p>
      </div>

      <h2>9. Withholdings</h2>

      <p>
        For projects requiring invoicing to the United States but commissioned
        from the countries listed below, it's important to factor in an increase
        in the total value due to applicable withholding rates or VAT.
      </p>

      <img
        src="@/assets/withholding-tax.png"
        alt="with holding tax table"
        class="img-fluid"
      />

      <p class="sample-quality-text">
        By accepting this offer, you accept the possibility of a sample/quality
        error of no more than 5% of the sample.
      </p>

      <span>
        Updated on November 2023
      </span>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
section {
  padding: 48px 15px;

  @media (max-width: 500px) {
    padding: 0 15px;
  }
}

.container {
  padding: 40px 0;

  div {
    .main {
      margin-bottom: 15px;
      margin-left: 20px;
    }

    p {
      margin-left: 45px;
      margin-bottom: 5px;
    }

    margin-bottom: 30px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #4d85d8;
  margin: 35px 0 15px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767.98px) {
    font-size: 20px;
  }
}

h3 {
  font-weight: 700;
  font-size: 18px;
  color: #707070;
  margin-bottom: 15px;
  margin-left: 20px;

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 30px;
  margin-left: 20px;

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

span {
  display: block;
  color: #f99746;
  font-style: italic;
  margin-top: 70px;
  font-size: 18px;
}

.sample-quality-text {
  font-weight: 700;
  font-size: 18px;
  color: #707070;

  font-style: italic;

  margin-top: 15px;

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}
</style>
