<template>
  <section class="py-5">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/dolar.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.statistical_methods
                  : "Statistical Methods"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.price_elasticity
                  : "Price Elasticity"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .know_which_are_the_most_elastic_products
                  : "Know which are the most elastic products of your market segment. Predict the consequences of alterations on the prices of your products and services."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Statistical Methods - Price Elasticity' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/rate.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.statistical_methods
                  : "Statistical Methods"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.conjoint
                  : "Conjoint"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.emulate_market
                  : "Emulate market share scenarios to predict the next movements of the market. The methodology also identifies consumers' preferences."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Statistical Methods - Conjoint' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/bus.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.statistical_methods
                  : "Statistical Methods"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.maxdiff
                  : "MaxDiff"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.compare_more_two
                  : "Compare more two attributes and verify which of them is more important to your target audience."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Statistical Methods - MaxDiff' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/megaphone.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.statistical_methods
                  : "Statistical Methods"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.nps
                  : "NPS"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .measure_level_of_satisfaction
                  : "Measure the level of satisfaction of your consumers. Identify  which consumers are loyal, neutral, or detractors."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Statistical Methods - NPS' }
                }"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  width: 288px;
  min-height: 360px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.1);
  padding: 40px 20px 25px 20px;
  margin-bottom: 40px;
  margin-left: calc(50% - 144px);
  .img-box {
    min-height: 84px;
  }
  .type {
    font-weight: 500;
    font-size: 0.625rem;
    color: #2d6187;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  h3 {
    font-weight: 700;
    font-size: 1.187rem;
    color: #3f4451;
  }
  .description {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #3f4451;
    min-height: 133px;
  }
  .getQuote a {
    font-weight: 600;
    font-size: 0.8rem;
    color: #2d6187;
  }
  .getQuote a:hover {
    text-decoration: none;
    color: #2d6187;
  }
}
</style>
