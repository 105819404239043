<template>
  <section class="background" id="communities">
    <img
      src="@/assets/bg-image-communities.png"
      class="background-white"
      alt=""
    />
    <div class="container">
      <div class="row justify-content-between pt-4">
        <div class="col-lg-5 communities pt-5">
          <div class="title pb-5">
            <img src="@/assets/voicesnewlogo.svg" class="img-owvoices" alt="" />
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products
                      .ow_voices_products
                  : "The platform was developed to approach customers and companies in an online environment, creating a new insight source"
              }}
            </p>
          </div>
          <div class="pb-4">
            <div class="retangle">
              <div class="ball">
                <img
                  src="@/assets/magnifying.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .the_respondents_are_profiled
                    : "The respondents are profiled, optimizing the sample for your study"
                }}.
              </p>
            </div>
          </div>
          <div class="pb-4">
            <div class="retangle">
              <div class="ball">
                <img
                  src="@/assets/message-communities.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle-midle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .voices_environment
                    : "The environment of Voices allows companies to approach sensible discussion topics"
                }}.
              </p>
            </div>
          </div>
          <div class="pb-5">
            <div class="retangle">
              <div class="ball">
                <img
                  src="@/assets/direction-communities.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .platform_can_be_used
                    : "The platform can be used to conduct different types of qualitative research"
                }}.
              </p>
            </div>
          </div>
          <router-link
            :to="{ name: 'owvoices' }"
            class="btn btn-getstarted-communities"
          >
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.request_a_demo
                : "Request a Demo"
            }}
          </router-link>
          <a
            href="https://www.youtube.com/watch?v=F38BY8WDlkQ"
            class="btn btn-getstarted-communities"
            target="_blank"
            v-if="$store.getters.lang === 'en'"
          >
            SEE HOW IT WORKS
          </a>
          <a
            href="https://www.youtube.com/watch?v=KN2zvntXADI"
            class="btn btn-getstarted-communities"
            target="_blank"
            v-if="$store.getters.lang === 'es'"
          >
            VE CÓMO FUNCIONA
          </a>
          <a
            href="https://www.youtube.com/watch?v=17N24pGfs4U"
            class="btn btn-getstarted-communities"
            target="_blank"
            v-if="$store.getters.lang === 'pt'"
          >
            VEJA COMO FUNCIONA
          </a>
        </div>
        <div class="col-lg-7">
          <img
            class="img-fluid img-communities img-animacao"
            src="@/assets/gif-owvoices-new.gif"
            alt="Imagem"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    option() {
      return this.$route.query.option;
    }
  },
  mounted() {
    if (this.$route.query.voices) {
      const element = document.getElementById("");
      element.scrollIntoView();
      window.scrollTo({ top: 2050 });

      //this.$router.push({ name: "product", query: { option: this.option } });
    }
  },
  updated() {
    if (this.$route.query.voices) {
      const element = document.getElementById("");
      element.scrollIntoView();
      window.scrollTo({ top: 2050 });

      //this.$router.push({ name: "product", query: { option: this.option } });
    }
  }
};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.background {
  background-color: #f7fafb;
  position: relative;
}

.background-white {
  position: absolute;
  top: 0;
  width: 100%;
}

.retangle {
  background: #ffffff;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  border-radius: 5080px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.text-retangle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.text-retangle-midle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.ball {
  width: 60px;
  height: 60px;
  background: #4b83d5;
  border-radius: 50%;
  position: absolute;
}

.img-magnifying {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  transform: translate(-50%, -50%);
}

.img-communities {
  margin-left: 48px;
  top: 112px;
  width: 98%;
  border-radius: 10px;
}

.img-animacao {
  position: relative;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
}

.img-survey {
  padding-bottom: 5%;
}

.img-cell {
  position: absolute;
  right: 20%;
  top: 20%;
  z-index: 1;
}

.img-focus {
  right: 20%;
  top: 15%;
}

.img-focus {
  padding-bottom: 5%;
}

.focus-p {
  font-size: 20px;
}

.section4 .item p {
  color: #707070;
  font-size: 12px;
}

.title {
  p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.title-focus {
  p {
    margin-bottom: 0;
    letter-spacing: 0.1em;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 14px;
  color: #3f4451;
  font-weight: bold;
}

.vertical-items {
  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 25px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

.btn-getstarted {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4b83d5;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
}

.btn-getstarted:hover {
  text-decoration: none;
  background: #4b83d5;
  transition: all 0.3s ease;
}

.btn-getstarted-communities {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4b83d5;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 22px 3px 22px;
  transition: all 0.3s ease;
  margin: 5px;
}

.btn-getstarted-communities:hover {
  text-decoration: none;
  color: #ffffff;
  background: #2771e0;
}

.btn-focus {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #0695fd;
  font-size: 0.8rem;
  background: #ffffff;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  border: 1px solid #3a70c0;
  margin-left: 20px;
  transition: all 0.3s ease;
}

.btn-focus:hover {
  text-decoration: none;
  color: #ffffff;
  background: #3a70c0;
}

.img-owvoices {
  width: 132px;
  margin-bottom: 27px;
}

@media (max-width: 991.98px) {
  .img-focus-cell {
    display: none;
  }

  .section-focus {
    display: none;
  }

  .img-owvoices {
    width: 132px;
    margin-bottom: 20px;
  }

  .focus {
    text-align: center;
    margin-top: -93px;
  }
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
  .img-communities {
    display: none;
  }
  .communities {
    text-align: center;
    margin-top: -5px;
    margin-bottom: -80px;
  }
}

@media (max-width: 991.98px) {
  .retangle {
    width: 70%;
    margin-left: calc(50% - 235.5px);
  }
}

@media (max-width: 767.98px) {
  .retangle {
    width: 90%;
    margin-left: calc(50% - 235.5px);
  }
}

@media (max-width: 575.98px) {
  .retangle {
    width: 100%;
    margin-left: 0px;
  }
}

@media (max-width: 414px) {
  .btn-focus {
    margin-left: 0;
  }
  .retangle {
    width: 100%;
    margin-left: 0px;
  }
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 359px) {
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
