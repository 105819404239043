<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="d-flex justify-content-between flex-wrap align-col">
          <div class="col-lg-5 space justify-content-center">
            <div class="onlinePlatforms">
              <img src="@/assets/dots.svg" class="dots" />
              <h2>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.online_platform.online
                    : "Online platform for"
                }}
                <span v-if="$store.getters.lang === 'pt'">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .online_platforms.online_platform.insights
                      : "Insights"
                  }}
                </span>
                <span v-if="$store.getters.lang === 'es'">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .online_platforms.online_platform.insights
                      : "Insights"
                  }}
                </span>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.online_platform.quality
                    : "Quality"
                }}
                <span v-if="$store.getters.lang === 'en'">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .online_platforms.online_platform.insights
                      : "Insights"
                  }}
                </span>
              </h2>
            </div>
            <div class="d-flex justify-content-center itemFocus">
              <div>
                <div id="focusGroup p-relative">
                  <div
                    class="line"
                    :class="{ active: option === 'focus' }"
                  ></div>
                  <h4
                    @click="option = 'focus'"
                    :class="{ active: option === 'focus' }"
                    class="align-focus"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.focus_group
                        : "Focus Group"
                    }}
                  </h4>
                  <div
                    class="focusCommunities"
                    :class="{ active: option === 'focus' }"
                    @click="option = 'focus'"
                  >
                    <div
                      class="ballG"
                      :class="{ active: option === 'communities' }"
                    ></div>
                    <img
                      :class="{ active: option === 'focus' }"
                      src="@/assets/user-white.svg"
                      alt="user white"
                      class="img-focus-communities"
                    />
                    <img
                      :class="{ active: option === 'communities' }"
                      src="@/assets/user-black.svg"
                      alt="user black"
                      class="img-focus-communities"
                    />
                  </div>
                </div>
              </div>
              <div class="space-focus">
                <div id="communities p-relative">
                  <div
                    class="line"
                    :class="{ active: option === 'communities' }"
                  ></div>
                  <h4
                    @click="option = 'communities'"
                    :class="{ active: option === 'communities' }"
                    class="align-communities"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.communities
                        : "Communities"
                    }}
                  </h4>
                  <div
                    class="focusCommunities"
                    :class="{ active: option === 'communities' }"
                    @click="option = 'communities'"
                  >
                    <div
                      class="ballC"
                      :class="{ active: option === 'focus' }"
                    ></div>
                    <img
                      :class="{ active: option === 'communities' }"
                      src="@/assets/calendar-white.svg"
                      alt="calendar white"
                      class="img-focus-communities"
                    />
                    <img
                      :class="{ active: option === 'focus' }"
                      src="@/assets/calendar-black.svg"
                      alt="calendar black"
                      class="img-focus-communities"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <p
                :class="{ active: option === 'communities' }"
                class="textFocus"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.ow_focus_explanation
                    : "OW Focus is an online platform developed to conduct focus groups and in-depth interviews"
                }}.
              </p>
              <p :class="{ active: option === 'focus' }" class="textFocus ">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.ow_voices_explanation
                    : "The platform was developed to approach customers and companies in an online environment, creating a new insight source"
                }}.
              </p>
            </div>
          </div>
          <div class="col-lg-5 item d-flex align-items-end">
            <div class="imgFocus" :class="{ active: option === 'focus' }">
              <img
                src="@/assets/focusnewlogo.svg"
                alt="Logo focus"
                class="logo img-fluid"
              />
              <img
                src="@/assets/focus-group.png"
                alt="Focus Group"
                class="imgGroup"
              />
            </div>
            <div class="imgFocus" :class="{ active: option === 'communities' }">
              <img
                src="@/assets/voicesnewlogo.svg"
                alt="Logo communities"
                class="logo img-fluid "
              />
              <img
                src="@/assets/ow-voices-img.png"
                alt="Communities"
                class="imgGroup"
              />
            </div>
          </div>
        </div>
        <div class="col mb-5 mt-5 d-flex justify-content-center knowMore">
          <router-link
            :to="{ name: 'product', query: { scroll: true } }"
            v-if="option === 'focus'"
            >{{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.know_more
                : "Learn more"
            }}</router-link
          >

          <router-link
            :to="{ name: 'product', query: { voices: true } }"
            v-if="option === 'communities'"
            >{{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.know_more
                : "Learn more"
            }}</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-between flex-wrap align-col">
          <div class="col-lg-5 item d-flex align-items-end">
            <div
              class="imgFocus align-items-end"
              :class="{ active: optionVision === 'eye-tracking' }"
            >
              <img
                src="@/assets/ow-vision-logo.svg"
                alt="Logo OW Vision"
                class="logo img-fluid"
              />
              <img
                src="@/assets/eye-tracking-img.svg"
                alt="OW Vision Eye Tracking"
                class="imgGroup"
              />
            </div>
            <div
              class="imgFocus align-items-end"
              :class="{ active: optionVision === 'facial-coding' }"
            >
              <img
                src="@/assets/ow-vision-logo.svg"
                alt="Logo OW Vision"
                class="logo img-fluid "
              />
              <img
                src="@/assets/facial-coding-img.svg"
                alt="OW Vision Facial Coding"
                class="imgGroup"
              />
            </div>
          </div>
          <div class="col-lg-5 space justify-content-center">
            <div class="onlinePlatforms">
              <img src="@/assets/dots.svg" class="dots" />

              <h2>
                <span
                  v-if="
                    $store.getters.lang === 'es' || $store.getters.lang === 'pt'
                  "
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .online_platforms.online_platform.solution
                      : "solution"
                  }}
                </span>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.online_platform.remote_eye_tracking
                    : "Remote Eye Tracking and Facial Coding solution"
                }}
                <span v-if="$store.getters.lang === 'en'">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .online_platforms.online_platform.solution
                      : "solution"
                  }}
                </span>
              </h2>
            </div>
            <div class="d-flex justify-content-center itemFocus">
              <div>
                <div id="focusGroup p-relative">
                  <div
                    class="line"
                    :class="{ active: optionVision === 'eye-tracking' }"
                  ></div>
                  <h4
                    @click="optionVision = 'eye-tracking'"
                    :class="{ active: optionVision === 'eye-tracking' }"
                    class="align-focus"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.eye_tracking
                        : "Eye Tracking"
                    }}
                  </h4>
                  <div
                    class="focusCommunities"
                    :class="{ active: optionVision === 'eye-tracking' }"
                    @click="optionVision = 'eye-tracking'"
                  >
                    <div
                      class="ballG"
                      :class="{ active: optionVision === 'facial-coding' }"
                    ></div>
                    <img
                      :class="{ active: optionVision === 'eye-tracking' }"
                      src="@/assets/eye-tracking-white.svg"
                      alt="user white"
                      class="img-focus-communities"
                    />
                    <img
                      :class="{ active: optionVision === 'facial-coding' }"
                      src="@/assets/eye-tracking.svg"
                      alt="user black"
                      class="img-focus-communities"
                    />
                  </div>
                </div>
              </div>
              <div class="space-focus">
                <div id="communities p-relative">
                  <div
                    class="line"
                    :class="{ active: optionVision === 'facial-coding' }"
                  ></div>
                  <h4
                    @click="optionVision = 'facial-coding'"
                    :class="{ active: optionVision === 'facial-coding' }"
                    class="align-communities"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.facial_coding
                        : "Facial coding"
                    }}
                  </h4>
                  <div
                    class="focusCommunities"
                    :class="{ active: optionVision === 'facial-coding' }"
                    @click="optionVision = 'facial-coding'"
                  >
                    <div
                      class="ballC"
                      :class="{ active: optionVision === 'eye-tracking' }"
                    ></div>
                    <img
                      :class="{ active: optionVision === 'facial-coding' }"
                      src="@/assets/facial-coding-white.svg"
                      alt="calendar white"
                      class="img-focus-communities"
                    />
                    <img
                      :class="{ active: optionVision === 'eye-tracking' }"
                      src="@/assets/facial-coding.svg"
                      alt="calendar black"
                      class="img-focus-communities"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <p
                :class="{ active: optionVision === 'eye-tracking' }"
                class="textFocus"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.ow_vision_enables
                    : "OW Vision enables the analysis of facial expressions to comprehend participants' emotions and reactions to a given stimulus."
                }}
              </p>
              <p
                :class="{ active: optionVision === 'facial-coding' }"
                class="textFocus"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .online_platforms.ow_vision_records
                    : "OW Vision records eye movements and collects accurate data on consumers' interest, engagement and attention levels."
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col mb-5 mt-5 d-flex justify-content-center knowMore">
          <router-link
            :to="{ name: 'product', query: { scroll: true } }"
            v-if="option === 'focus'"
            >{{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.know_more
                : "Learn more"
            }}</router-link
          >

          <router-link
            :to="{ name: 'product', query: { voices: true } }"
            v-if="option === 'communities'"
            >{{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.know_more
                : "Learn more"
            }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      option: "focus",
      optionVision: "eye-tracking"
    };
  }
};
</script>

<style lang="scss" scoped>
.onlinePlatforms {
  img {
    position: absolute;
    left: -40px;
    z-index: -10;
  }
  h2 {
    margin-left: -10px;
    padding-top: 25px;
    font-weight: 600;
    font-size: 2.6rem;
    color: black;
    line-height: 60px;
  }

  h2 span {
    color: #4f87da;
  }
}
.space-focus {
  margin-left: 0.75rem;
}

a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 5px 40px;
  background: #4f87da;
  border-radius: 25px;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
}

a:hover {
  text-decoration: none;
  color: #ffffff;
  background: #3c73c5;
}

h4 {
  margin-left: 45px;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  color: #a3a3a3;
  transition: all 1s ease;
  &.active {
    color: #000000;
  }
}

.knowMore {
  text-transform: uppercase;
}

.itemFocus {
  margin-top: 80px;
}

#focusGroup {
  position: relative;
  > img {
    position: absolute;
    margin-top: 11px;
  }
}

#communities {
  position: relative;
  > img {
    position: absolute;
    margin-top: 11px;
  }
}

p {
  font-weight: 400px;
  font-size: 0.8125rem;
  color: #707070;
  transition: all 1s ease;
  &.active {
    display: none;
  }
}

.focusCommunities {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ffffff;
  margin: 40px 0 0 50px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  transition: all 1s ease;
  &.active {
    background: #000000;
    box-shadow: 5px 9px 9px -1px rgba(0, 0, 0, 0.25);
    border: none;
  }
  img {
    display: none;
    transition: all 1s ease;
    &.active {
      display: block;
    }
  }
  .ballG {
    position: absolute;
    top: 0px;
    left: 100px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #000000;
    transition: all 1s ease;
    &.active {
      display: none;
    }
  }
  .ballC {
    position: absolute;
    bottom: 0px;
    right: 100px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000000;
    transition: all 1s ease;
    &.active {
      display: none;
    }
  }
}
.img-focus-communities {
  width: 50px;
  height: 45px;
}
.line {
  width: 30px;
  position: absolute;
  margin-top: 10px;
  border-bottom: 2px solid black;
  transition: all 1s ease;
  display: none;
  &.active {
    display: block;
  }
}

.imgFocus {
  //margin-top: calc(50% - 195px);
  display: none;
  animation: opacity 0.5s ease-in-out;
  &.active {
    display: flex;
    flex-direction: column;
  }
}

.img-voices {
  box-shadow: 7px 7px 22px 11px rgba(165, 187, 198, 0.35);
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.8;
  }
}

.textFocus {
  margin-top: 50px;
  padding-left: 25px;
  width: 420px;
  text-align: center;
}

.logo {
  width: 160px;
  margin-bottom: 10px;
  margin-right: 41px;
}

.imgGroup {
  //width: 540px;
  height: 380px;
  border-radius: 8px;
}

@media (max-width: 1199.98px) {
  .align-col {
    justify-content: unset !important;
  }

  h4 {
    margin-left: 0px;
  }
  .line {
    width: 0;
    transition: none;
    display: none;
    &.active {
      display: none;
    }
  }
  .focusCommunities {
    margin: 40px 0 0 16px;
  }
  .textFocus {
    padding-left: 0px;
    width: 310px;
  }

  .item {
    margin-top: 200px;
  }
}
@media (max-width: 991.98px) {
  section {
    margin-bottom: -35px;
  }

  .space-focus {
    margin-left: 3rem;
  }

  h4 {
    margin-left: 35px;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    color: #a3a3a3;
    transition: all 1s ease;
    &.active {
      color: #000000;
    }
  }

  .align-focus {
    margin-left: -0px;
  }

  .align-communities {
    margin-left: -0px;
  }

  .line {
    width: 30px;
    position: absolute;
    margin-left: -35px;
    margin-top: 10px;
    border-bottom: 2px solid black;
    transition: all 1s ease;
    display: none;
    &.active {
      display: block;
    }
  }
  .focusCommunities {
    margin-left: calc(50% - 47px);
  }

  .textFocus {
    width: 320px;
  }

  .item {
    display: none !important;
  }
  .onlinePlatforms {
    h2 {
      width: 100%;
      text-align: center;
      margin-left: 0px;
    }
    .dots {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  #focusGroup {
    margin-left: calc(50% - 40px);
  }

  .space-focus {
    margin-left: 3.2rem;
  }

  .focusCommunities {
    margin-left: calc(50% - 55px);
  }

  #communities {
    h4 {
      margin-top: 220px;
    }
  }

  .textFocus {
    width: 320px;
    padding-left: 0px;
    margin-left: -20px;
  }

  .knowMore {
    margin-left: 35px;
  }

  .item {
    display: none;
  }
}
@media (max-width: 575.98px) {
  section {
    margin-top: 42px;
  }
  .dots {
    display: none;
  }
  .onlinePlatforms {
    h2 {
      width: 100%;
      font-size: 2rem;
      line-height: 40px;
      text-align: center;
      margin-left: 0px;
    }
  }
  h4 {
    margin-left: 0px;
  }

  .focusCommunities {
    margin: 40px 0 0 16px;
  }

  .textFocus {
    width: 310px;
    margin-left: 0;
  }

  .line {
    width: 0;
    transition: none;
    display: none;
    &.active {
      display: none;
    }
  }
  .space {
    margin-bottom: 0px;
  }
  .item {
    display: none;
  }
  .knowMore {
    margin-left: 0px;
  }
}
</style>
