<template>
  <section class="py-3 intro">
    <div class="intro-banner"></div>
    <div class="container p-relative">
      <img src="@/assets/style-banner.svg" class="style-banner" />
      <div class="row">
        <header
          class="col d-flex align-items-center justify-content-betwen header p-relative"
        >
          <div>
            <img src="@/assets/logo-landpage.svg" alt="" />
            <p>Powered by <span>Offerwise</span></p>
          </div>
          <nav class="col-lg-8 col-xl-6 d-flex test">
            <div class="nav-link">
              <a href="#sample" v-smooth-scroll="{ duration: 1000 }">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.features
                    : "Features"
                }}
              </a>
            </div>
            <div class="nav-link">
              <a href="#features" v-smooth-scroll="{ duration: 1000 }">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.buttons_and_inputs.benefits
                    : "Benefits"
                }}
              </a>
            </div>
            <div class="nav-link">
              <a href="#how-work" v-smooth-scroll="{ duration: 1000 }">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.buttons_and_inputs
                        .how_it_works
                    : "How it works"
                }}
              </a>
            </div>
            <div class="nav-link">
              <a href="#contact" v-smooth-scroll="{ duration: 1000 }">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.buttons_and_inputs
                        .request_a_demo
                    : "Request a demo"
                }}
              </a>
            </div>
          </nav>
          <div class="col-md-2 drop-lang">
            <div class="dropdown-language" id="dropLanguage">
              <a @click="handleDrop('dropLanguage')">
                <img
                  src="@/assets/arrow-down-language.svg"
                  class="arrow-down"
                />
                <img
                  src="@/assets/icon-language.svg"
                  :alt="language"
                  class="flag"
                />
                <img
                  src="@/assets/arrow-down-language-white.svg"
                  class="arrow-down-white"
                />
                <img
                  src="@/assets/icon-language-white.svg"
                  :alt="language"
                  class="flag-white"
                />

                {{ language }}
              </a>
              <div class="drop-language-item">
                <div class="triangle">
                  <div class="triangleUp"></div>
                </div>
                <div class="language-button">
                  <button
                    @click="language = 'pt'"
                    :class="{ active: language === 'pt' }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.lang_menu.portuguese
                        : "Portuguese"
                    }}
                  </button>
                  <button
                    @click="language = 'es'"
                    :class="{ active: language === 'es' }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.lang_menu.spanish
                        : "Espanhol"
                    }}
                  </button>
                  <button
                    @click="language = 'en'"
                    :class="{ active: language === 'en' }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.lang_menu.english
                        : "English"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <header class="d-lg-none">
          <div class="logo-mobile">
            <img src="@/assets/logo-landpage.svg" alt="" />
            <p>Powered by <span>Offerwise</span></p>
          </div>
          <Slide width="282" right>
            <nav class="nav-mobile">
              <div>
                <a href="#sample" v-smooth-scroll="{ duration: 1000 }">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.landpage.features
                      : "Features"
                  }}
                </a>
              </div>
              <div>
                <a href="#features" v-smooth-scroll="{ duration: 1000 }">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.landpage.buttons_and_inputs
                          .benefits
                      : "Benefits"
                  }}
                </a>
              </div>
              <div>
                <a href="#how-work" v-smooth-scroll="{ duration: 1000 }">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.landpage.buttons_and_inputs
                          .how_it_works
                      : "How it works"
                  }}
                </a>
              </div>
              <div>
                <a href="#contact" v-smooth-scroll="{ duration: 1000 }">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.landpage.buttons_and_inputs
                          .request_a_demo
                      : "Request a demo"
                  }}
                </a>
              </div>
            </nav>
            <div class="line"></div>
            <div class="language">
              <div class="language-preferences d-flex align-items-center">
                <img src="@/assets/icon-language.svg" />
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.language_preferences
                      : "Language Preferences"
                  }}
                </p>
              </div>
              <div class="choose-language">
                <div class="d-flex align-items-center mb-2">
                  <p
                    @click="language = 'pt'"
                    :class="{ active: language === 'pt' }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.lang_menu.portuguese
                        : "Portuguese"
                    }}
                  </p>
                  <div
                    class="ball-language"
                    :class="{ active: language === 'pt' }"
                  ></div>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <p
                    @click="language = 'en'"
                    :class="{ active: language === 'en' }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.lang_menu.english
                        : "English"
                    }}
                  </p>
                  <div
                    class="ball-language"
                    :class="{ active: language === 'en' }"
                  ></div>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <p
                    @click="language = 'es'"
                    :class="{ active: language === 'es' }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.lang_menu.spanish
                        : "Espanhol"
                    }}
                  </p>
                  <div
                    class="ball-language"
                    :class="{ active: language === 'es' }"
                  ></div>
                </div>
              </div>
            </div>
          </Slide>
        </header>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center p-relative second-row"
      >
        <div class="col-xl-5 firstCol">
          <h1 class="title">
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.simple_automated_surveys
                : "Simple automated surveys"
            }}
          </h1>
          <div class="align-btn">
            <a
              href="#contact"
              class="btn btn-book"
              v-smooth-scroll="{ duration: 1000 }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.buttons_and_inputs
                      .request_a_demo
                  : "Request a Demo"
              }}
            </a>
          </div>
        </div>
        <div class="secondCol col-xl-7">
          <div class="videoCompuiter d-flex justify-content-center">
            <img src="@/assets/tablet.gif" class="gif-tablet" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  components: {
    Slide
  },
  data() {
    return {
      linkResearch: "1",
      language: this.$store.getters.lang,
      option: ""
    };
  },
  watch: {
    language() {
      this.$store.commit("lang", this.language);
      this.$store.commit("trans", this.language);
    }
  },
  methods: {
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    },
    outsideClick(element, events, callback) {
      const html = document.documentElement;
      const outside = "data-outside";
      if (!element.hasAttribute(outside)) {
        events.forEach(userEvent => {
          setTimeout(() => {
            html.addEventListener(userEvent, () => {
              if (!element.contains(event.target)) {
                element.removeAttribute(outside);
                events.forEach(userEvent => {
                  html.removeEventListener(userEvent, this.handleOutsideClick);
                });
                callback();
              }
            });
          });
        });
        element.setAttribute(outside, "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.intro {
  position: relative;
  overflow: hidden;
  height: calc(440px);
  .style-banner {
    width: 900px;
    position: absolute;
    z-index: 0;
    top: -480px;
    right: -350px;
    transform: rotate(7deg) !important;
  }
}

.intro-banner {
  position: absolute;
  width: 100% !important;
  height: calc(100vh + 10px) !important;
  top: 0;
  background: url(../../assets/banner-landpage.png);
}
header {
  p {
    margin: 0 0 0 67px;
    font-weight: 400;
    font-size: 0.5rem;
    color: #000;
    span {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.8rem;
      color: #000;
      margin-left: 10px;
    }
  }
  nav .nav-link {
    padding: 0 14px;
    a {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
  .dropdown-language {
    > a {
      text-transform: uppercase;
      color: #707070;
      font-size: 0.75rem;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.3s ease;
      padding: 20px 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        margin-right: 7px;
      }
      .arrow-down {
        width: 10px;
        height: 7px;
        margin-right: 5px;
      }
      .flag {
        width: 16px;
        height: 16px;
      }
      .arrow-down-white {
        width: 10px;
        height: 7px;
        margin-right: 5px;
        display: none;
      }
      .flag-white {
        width: 16px;
        height: 16px;
        display: none;
      }
    }
    &:hover {
      color: #8e8e8e;
      .drop-language-item {
        display: block;
      }
      .arrow-down {
        transform: rotate(180deg) !important;
        transition: all 0.5s ease;
      }
    }
  }

  .drop-language-item {
    width: 150px;
    margin-top: 20px;
    padding: 10px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    box-shadow: 5px 5px 5px rgba(42, 109, 172, 0.4);
    transform-origin: top center;
    background: #e9e9e9;
    position: absolute;
    text-align: center;
    z-index: 56;
    top: 35px;
    right: 40px;
    a {
      display: block;
      color: #495057;
      padding: 2px 10px;
      &:hover,
      &.active {
        color: #666;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &.active {
      display: block;
    }
  }
  .triangleUp {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #e9e9e9;
    position: absolute;
    top: -13px;
    left: calc(50% - 15px);
  }
}
.drop-lang {
  margin-left: 200px !important;
}

.language-button {
  button {
    border: none;
    background: transparent;
    font-size: 0.9rem !important;
    outline: 0;
    cursor: pointer;
    width: 100px;
    &.active {
      color: #3a70c0;
    }
  }
}

.intro-style {
  position: absolute;
  bottom: -2px;
  width: 100%;
  img {
    width: 100%;
  }
}

.arrow-btn {
  position: absolute;
  bottom: 52px;
  right: calc(50% - 28px);
  cursor: pointer;
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.second-row {
  height: 390px;
}

.firstCol {
  margin-bottom: 30px;
  padding-left: 50px;
  .title {
    font-weight: 700;
    color: #fff;
  }
  .btn-book {
    background: #fff;
    color: #0695fd;
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-radius: 50px;
    padding: 5px 20px;
    outline: none !important;
    margin-top: 25px;
    &:hover,
    &:focus {
      background: #0695fd;
      color: #fff;
    }
  }
  .btn:focus,
  .btn.focus {
    outline: none;
    box-shadow: none;
  }
}

.line {
  border-bottom: 0.5px solid #d1d3d4;
  position: absolute;
  width: 282px;
  left: 0;
}
.language {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0;
  .language-preferences {
    padding: 20px 0 0 14px;
    img {
      width: 18px;
      height: 18px;
    }
    p {
      margin: 0 0 0 10px;
      font-weight: 400;
      font-size: 0.9rem;
      color: #9c9c9c;
    }
  }
  .choose-language {
    margin-top: 10px;
    padding: 14px;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 1.125rem;
      color: #9c9c9c;
      transition: all 0.5s ease;
      cursor: pointer;
      &.active {
        color: #3a70c0;
      }
    }
    .ball-language {
      width: 5px !important;
      height: 5px !important;
      border-radius: 50%;
      background: #3a70c0;
      margin-left: 10px;
      display: none;
      animation: opacity 0.5s ease-in-out;
      &.active {
        display: block;
      }
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.8;
  }
}

.secondCol {
  position: relative;
  z-index: 3;
  top: calc(50% - 210px);
  .videoCompuiter {
    .gif-tablet {
      width: 65%;
      border-radius: 30px;
      box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
    }
  }
}

@media (max-width: 1199.98px) {
  .intro {
    height: 600px;
  }
  .arrow-btn {
    bottom: 46px;
  }
  .firstCol {
    margin-top: 50px;
    padding-left: 15px;
    h1 {
      text-align: center;
    }
    .contact {
      width: 100%;
      .align-btn {
        margin-left: 50%;
      }
    }
    .firstCol {
      padding-left: 50px;
      .title {
        font-weight: 700;
        color: #fff;
      }
    }
  }
  .align-btn {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }
  .secondCol {
    display: none;
  }
  .style-banner {
    display: none;
  }

  .test {
    justify-content: center !important;
  }

  .nav-link {
    margin: 0 5px;
  }

  .dropdown-language {
    > a {
      .arrow-down {
        display: none;
      }
      .flag {
        display: none;
      }
      .arrow-down-white {
        display: block !important;
      }
      .flag-white {
        display: block !important;
      }
    }
  }

  .drop-language-item {
    width: 150px;
    margin-top: 20px;
    padding: 10px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    box-shadow: 5px 5px 5px rgba(42, 109, 172, 0.4);
    transform-origin: top center;
    background: #e9e9e9;
    position: absolute;
    text-align: center;
    z-index: 5;
    top: 35px;
    right: 9px !important;
    a {
      display: block;
      color: #495057;
      padding: 2px 10px;
      &:hover,
      &.active {
        color: #666;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &.active {
      display: block;
    }
  }

  .dropdown-language {
    > a {
      color: #fff !important;
    }
  }
  .drop-lang {
    margin-left: -10px !important;
  }
}
@media (max-width: 991.98px) {
  .header {
    display: none !important;
  }
  .nav-mobile {
    display: block !important;
    div {
      padding: 10px 0;
      a {
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1rem;
        &:hover,
        &:focus {
          color: #707070;
        }
      }
    }
  }
  .arrow-btn {
    bottom: 37px;
  }
}
@media (max-width: 767.98px) {
  .arrow-btn {
    bottom: 26px;
  }
}
@media (max-width: 575.98px) {
  .intro {
    position: relative;
    overflow: hidden;
    height: 500px;
  }
  .intro-style {
    bottom: -3px !important;
  }
  .arrow-btn {
    bottom: 30px !important;
  }
  .firstCol {
    margin-top: 0px;
    input {
      max-width: 100% !important;
      padding-right: 20px;
    }
    .input {
      max-width: 300px !important;
      margin-left: calc(50% - 150px) !important;
    }
  }
  header {
    .logo-mobile {
      img {
        margin-left: 20px;
      }
      p {
        margin-left: 87px;
      }
    }
  }
  .intro-style {
    bottom: 32px;
  }
  .arrow-btn {
    bottom: 47px;
  }
  .intro-banner {
    height: 630px !important;
  }
}

@media (min-width: 1400px) {
  .intro-style {
    bottom: -12px;
  }
  .arrow-btn {
    bottom: 64px;
  }
}
</style>
