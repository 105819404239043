<template id="1">
  <section class="background mb-1">
    <img
      class="img-fluid img-contact"
      src="@/assets/bg-contact.svg"
      alt="Imagem"
    />
    <div class="bg-color"></div>
    <div class="container py-5 h100">
      <div class="row justify-content-between contact pt-3">
        <div class="col-lg-7">
          <div class="justify-content-center logo">
            <img
              src="@/assets/ow-vision-logo.svg"
              class="img-fluid img-survey img-logo"
              alt=""
            />
          </div>
          <div class="logo-diy img-hide">
            <img class="dots-1" src="@/assets/dots.svg" alt="Imagem" />
            <img
              class="img-fluid img-intro-contact"
              src="@/assets/laptop-ow-vision.png"
              alt="Imagem"
            />
          </div>
        </div>
        <div class="col-lg-5 survey">
          <div class="title pb-4">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products.request_a_demo
                  : "Request a Demo"
              }}
            </p>
          </div>
          <form @submit="applyJob" v-if="$store.getters.lang === 'en'">
            <div class="input mb-3">
              <input
                type="text"
                class="form-control p-4"
                id="name"
                v-model="name"
                required
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.name
                    : 'Name'
                "
              />
            </div>
            <div class="input mb-3">
              <input
                type="email"
                class="form-control p-4"
                id="email"
                v-model="email"
                required
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.email
                    : 'E-mail'
                "
              />
            </div>
            <div class="input-2 mb-3">
              <input
                type="text"
                v-mask="'+# (###) ###-####'"
                class="form-control p-4 input input-tel"
                id="phone"
                v-model="tel"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.phone
                    : 'Phone'
                "
              />
              <input
                type="text"
                class="form-control p-4 input input-country"
                id="country"
                v-model="country"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.country
                    : 'Country'
                "
              />
            </div>

            <div class="input mb-3">
              <input
                type="text"
                class="form-control p-4"
                id="company"
                v-model="company"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.company
                    : 'Company'
                "
              />
            </div>
            <div class="mb-5">
              <textarea
                class="form-control"
                id="msg"
                rows="5"
                v-model="msg"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.message
                    : 'Message'
                "
              ></textarea>
            </div>
            <div class="d-flex justify-content-center p-0 mb-4">
              <vue-recaptcha
                sitekey="6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7"
                :loadRecaptchaScript="true"
                @verify="mxVerify"
              ></vue-recaptcha>
            </div>
            <div class="d-flex button-align">
              <button class="btn btn-request">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.send
                    : "Request"
                }}
              </button>
            </div>
          </form>

          <form @submit="applyJob" v-if="$store.getters.lang === 'es'">
            <div class="input mb-3">
              <input
                type="text"
                class="form-control p-4"
                id="name"
                v-model="name"
                required
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.name
                    : 'Name'
                "
              />
            </div>
            <div class="input mb-3">
              <input
                type="email"
                class="form-control p-4"
                id="email"
                v-model="email"
                required
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.email
                    : 'E-mail'
                "
              />
            </div>
            <div class="input-2 mb-3">
              <input
                type="text"
                v-mask="'+## ###-##-##-##'"
                class="form-control p-4 input input-tel"
                id="phone"
                v-model="tel"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.phone
                    : 'Phone'
                "
              />
              <input
                type="text"
                class="form-control p-4 input input-country"
                id="country"
                v-model="country"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.country
                    : 'Country'
                "
              />
            </div>

            <div class="input mb-3">
              <input
                type="text"
                class="form-control p-4"
                id="company"
                v-model="company"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.company
                    : 'Company'
                "
              />
            </div>
            <div class="mb-5">
              <textarea
                class="form-control"
                id="msg"
                rows="5"
                v-model="msg"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.message
                    : 'Message'
                "
              ></textarea>
            </div>
            <div class="d-flex justify-content-center mb-4">
              <vue-recaptcha
                sitekey="6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7"
                :loadRecaptchaScript="true"
                @verify="mxVerify"
              ></vue-recaptcha>
            </div>
            <div class="d-flex button-align">
              <button class="btn btn-request">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.send
                    : "Request"
                }}
              </button>
            </div>
          </form>

          <form @submit="applyJob" v-if="$store.getters.lang === 'pt'">
            <div class="input mb-3">
              <input
                type="text"
                class="form-control p-4"
                id="name"
                v-model="name"
                required
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.name
                    : 'Name'
                "
              />
            </div>
            <div class="input mb-3">
              <input
                type="email"
                class="form-control p-4"
                id="email"
                v-model="email"
                required
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.email
                    : 'E-mail'
                "
              />
            </div>
            <div class="input-2 mb-3">
              <input
                type="text"
                v-mask="'+## (##) #####-####'"
                class="form-control p-4 input input-tel"
                id="phone"
                v-model="tel"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.phone
                    : 'Phone'
                "
              />
              <input
                type="text"
                class="form-control p-4 input input-country"
                id="country"
                v-model="country"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.country
                    : 'Country'
                "
              />
            </div>

            <div class="input mb-3">
              <input
                type="text"
                class="form-control p-4"
                id="company"
                v-model="company"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.company
                    : 'Company'
                "
              />
            </div>
            <div class="mb-5">
              <textarea
                class="form-control"
                id="msg"
                rows="5"
                v-model="msg"
                :placeholder="
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.message
                    : 'Message'
                "
              ></textarea>
            </div>
            <div class="d-flex justify-content-center mb-4">
              <vue-recaptcha
                sitekey="6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7"
                :loadRecaptchaScript="true"
                @verify="mxVerify"
              ></vue-recaptcha>
            </div>
            <div class="d-flex button-align">
              <button class="btn btn-request">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.send
                    : "Request"
                }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { api } from "@/services.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { VueRecaptcha },

  data() {
    return {
      loader: false,
      name: "",
      email: "",
      tel: "",
      country: "",
      company: "",
      demo: "Ow Vision",
      msg: "",
      recaptcha: null
    };
  },
  methods: {
    mxVerify(response) {
      this.recaptcha = response;
    },
    applyJob(e) {
      this.$gtag.event("conversion", {
        send_to: "AW-1072735680/U3xwCJi7is0BEMDLwv8D"
      });

      this.$gtag.event("conversion", {
        send_to: "AW-404271196/mL-hCKvXvskZENzg4sAB"
      });

      if (!this.loader && this.email !== "" && this.recaptcha !== null) {
        this.loader = true;
        api
          .post(
            "mail/demo",
            {
              name: this.name,
              email: this.email,
              tel: this.tel,
              country: this.country,
              company: this.company,
              demo: this.demo,
              msg: this.msg
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.name = "";
              this.email = "";
              this.tel = "";
              this.country = "";
              this.company = "";
              this.msg = "";
              window.grecaptcha.reset();
              this.$toast.open(
                `${
                  this.$store.getters.trans
                    ? this.$store.getters.trans.menu_contact.error
                        .successfully_sent
                    : "Successfully sent!"
                }`
              );
            }
            this.loader = false;
          })
          .catch(() => {
            this.$toast.error(
              `${
                this.$store.getters.trans
                  ? this.$store.getters.trans.menu_contact.error
                      .something_went_wrong
                  : "Something went wrong!"
              }`
            );
            this.loader = false;
          });
      } else {
        this.$toast.error(
          `${
            this.$store.getters.trans
              ? this.$store.getters.trans.menu_contact.error.check_captcha
              : "Please check the captcha!"
          }`
        );
      }
      e.preventDefault();
    },

    resetInput() {
      this.email = "";
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.contact {
  margin-top: 78px;
}

.img-contact {
  position: absolute;
  top: 0;
  left: 40%;
  height: 100%;
}

label {
  margin-left: 0;
}

textarea.form-control {
  height: auto;
  padding: 20px 20px 20px 20px;
  border-radius: 35px;
  box-shadow: 1px 1px 2px rgba(45, 97, 135, 0.3);
}

textarea::-webkit-input-placeholder {
  color: #6b6b6b;
  font-size: 0.8em;
}

.check {
  position: relative;
  top: calc(50% - 16px);
  font-size: 1em;
  right: -15px;
}

.text-input-checkbox {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-size: 0.8rem;
  color: #666666;
}

.input {
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #6b6b6b;
  border-radius: 33px;
  position: relative;
  width: 100%;
  box-shadow: 1px 1px 2px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  ::placeholder {
    font-size: 0.8em;
  }
}
input {
  font-size: 15px;
  width: 100%;
  border: none;
  border-radius: 33px;
}

.input-2 {
  display: flex;
  align-items: center;
  color: #6b6b6b;
  border-radius: 33px;
  position: relative;
  width: 100%;
  font-size: 15px;
  border-radius: 100px;
  ::placeholder {
    font-size: 0.8em;
  }
}
input-2 {
  width: 100%;
  font-size: 15px;
  border: none;
  border-radius: 33px;
}

.input-message {
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #6b6b6b;
  border-radius: 33px;
  width: 100%;
  height: 150px;
  box-shadow: 1px 1px 2px rgba(45, 97, 135, 0.3);
  border-radius: 40px;
  ::placeholder {
    font-size: 0.8em;
  }
}

.img-intro-contact {
  position: relative;
  padding-top: 150px;
  right: 60px;
}

.title {
  text-align: center;
  p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
    font-size: 30px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.btn-request {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  position: relative;
  top: -4px;
  color: #ffffff;
  font-size: 0.8rem;
  background: #0686e0;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  padding: 3px 100px 3px 100px;
  transition: all 0.3s ease;
}

.btn-request:hover {
  text-decoration: none;
  color: #ffffff;
  background: #0875ce;
}

.bg-color {
  height: 100%;
  right: 0;
  width: 35%;
  background-color: #f7f9fc;
  position: absolute;
}

.background {
  position: relative;
}

.form-control {
  border: none;
}

.img-owvoices {
  width: 130px;
}

.img-logo {
  margin-top: 40px;
  width: 30%;
  position: absolute;
}

.checkbox-diy-one {
  margin-left: 45px;
  margin-top: 20px;
}

.checkbox-diy-second {
  margin-left: 110px;
  margin-top: 20px;
}

.checkbox-diy-sample {
  margin-left: 115px;
  margin-top: 20px;
}

.dots-1 {
  right: 88%;
  position: absolute;
  margin-top: -4%;
  z-index: -5;
}

@media (max-width: 1268px) {
  .background {
    background-color: #f7f9fc;
  }
}

.names_inputs_country {
  font-size: 0.8em;
  position: relative;
  left: 22px;
  top: 10px;
  margin-left: 190px;
  color: gray;
}

.names_inputs {
  font-size: 0.8em;
  position: relative;
  left: 22px;
  top: 10px;
  color: gray;
}

.names_inputs_phone {
  font-size: 0.8em;
  position: relative;
  left: 22px;
  top: 10px;
  color: gray;
}

@media (max-width: 1199.98px) {
  .bg-color {
    display: none;
  }
  .logo-diy {
    text-align: center;
  }
  .names_inputs_country {
    margin-left: 153px;
  }
  .dots-1 {
    display: none;
  }
  .checkbox-diy-one[data-v-2873a150] {
    margin-left: 18px;
    margin-top: 20px;
  }
}

@media (max-width: 991.98px) {
  .img-hide {
    display: none;
  }
  .title {
    margin-top: 50px;
  }
  .survey {
    text-align: center;
  }
  .contact {
    margin-top: 0;
  }
  .names_inputs_country {
    margin-left: 317px;
  }
  .img-logo {
    margin-top: 0px;
    width: 25%;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .logo {
    display: flex;
  }
}

@media (max-width: 767.98px) {
  .names_inputs_country {
    margin-left: 228px;
  }
  .img-logo {
    margin-top: 0px;
    width: 30%;
  }
}

@media (max-width: 575.98px) {
  .checkbox-diy-one[data-v-2873a150][data-v-2873a150] {
    margin-left: 10px;
    margin-top: 20px;
  }
  .names_inputs_country {
    margin-left: 198px;
  }
  .img-logo {
    margin-top: 0px;
    width: 30%;
  }
}

@media (max-width: 540px) {
  .names_inputs_country {
    margin-left: 165px;
  }
  .img-logo {
    margin-top: 0px;
    width: 40%;
  }
}

@media (max-width: 414px) {
  .names_inputs_country {
    margin-left: 162px;
  }
}

@media (max-width: 375px) {
  .names_inputs_country {
    margin-left: 143px;
  }
}

@media (max-width: 360px) {
  .names_inputs_country {
    margin-left: 133px;
  }
}

@media (max-width: 320px) {
  .names_inputs_country {
    margin-left: 113px;
  }
}

.telandCountr {
  ::placeholder {
    font-size: 0.8em;
  }
}

.input-tel {
  margin-right: 10px;
}

.input-country {
  margin-left: 10px;
}

.button-align {
  justify-content: center;
}

.names_inputs {
  font-size: 0.8em;
  position: relative;
  left: 22px;
  top: 10px;
  color: gray;
}
.names_inputs {
  font-size: 0.8em;
  position: relative;
  left: 22px;
  top: 10px;
  color: gray;
}
.names_inputs_phone {
  font-size: 0.8em;
  position: relative;
  left: 22px;
  top: 10px;
  color: gray;
}
</style>
