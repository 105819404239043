<template>
  <section class="background mb-1">
    <div class="container py-5">
      <div class="row justify-content-between pb-5 pt-5">
        <img
          src="@/assets/line-communities.svg"
          class="line-communities"
          alt=""
        />

        <div class="col-lg-6 pt-5">
          <img
            class="img-fluid img-sample-diy"
            src="@/assets/gif-vision.gif"
            alt="Imagem"
          />
        </div>
        <div class="col-lg-5 sample pt-5 pb-3">
          <div class="title pb-4">
            <img src="@/assets/ow-vision-logo.svg" class="img-survey" alt="" />
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products.on_ow_vision
                  : "By combining Eye Tracking and Facial Coding, OW Vision facilitates a comprehensive examination of consumer behavioral patterns and emotional reactions when engaging with visual stimuli such as images, videos, or websites"
              }}
            </p>
          </div>
          <div class="vertical-items survey-p pb-5">
            <div class="pb-2 pt-3">
              <div class="retangle">
                <div class="ball-0">
                  <img
                    src="@/assets/vision-1.svg"
                    class="img-magnifying"
                    alt=""
                  />
                </div>
                <p class="text-retangle">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .set_up_the_study
                      : "Set up the study online, without having to download any software."
                  }}
                </p>
              </div>
            </div>
            <div class="pb-2">
              <div class="retangle">
                <div class="ball-0">
                  <img
                    src="@/assets/vision-2.svg"
                    class="img-magnifying"
                    alt=""
                  />
                </div>
                <p class="text-retangle-midle">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .integrate_ow_vision
                      : "Integrate OW Vision with your preferred research platform via a redirect link."
                  }}
                </p>
              </div>
            </div>
            <div class="">
              <div class="retangle">
                <div class="ball-0">
                  <img
                    src="@/assets/vision-3.svg"
                    class="img-magnifying"
                    alt=""
                  />
                </div>
                <p class="text-retangle-last">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .the_tool_processes
                      : "The tool processes individual and aggregated results, exporting valuable metrics, detailed heat maps, and comprehensive histograms."
                  }}
                </p>
              </div>
            </div>
          </div>
          <router-link :to="{ name: 'owvision' }" class="btn btn-getstarted">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.request_a_demo
                : "Request a Demo"
            }}
          </router-link>
          <a
            href="https://www.youtube.com/watch?v=serrusoOcVk"
            class="btn btn-getstarted"
            target="_blank"
          >
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.see_how_it_works
                : "See how it works"
            }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.retangle {
  background: #ffffff;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  border-radius: 5080px;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 90%;
}

.text-retangle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 5px 0 5px 80px;
  padding: 10px 15px 10px 0;
}

.text-retangle-midle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 5px 0 5px 80px;
  padding: 5px 15px 5px 0;
}

.text-retangle-last {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 5px 0 5px 80px;
  padding: 5px 20px 5px 0;
}

.ball-0 {
  width: 60px;
  height: 60px;
  background: #275982;
  border-radius: 50%;
  position: absolute;
}

.img-magnifying {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 35px;
  transform: translate(-50%, -50%);
}

.img-communities {
  margin-left: 48px;
  top: 112px;
  width: 98%;
  border-radius: 10px;
}

.background {
  background-color: #f7fafb;
}

.img-survey-animated {
  position: relative;
}

.img-sample-diy {
  position: relative;
  filter: drop-shadow(14.4051px 15.3055px 12.6045px rgba(204, 221, 229, 0.25));
  border-radius: 10.8039px;
  margin-top: 63px;
}

.img-survey {
  width: 132px;
  margin-bottom: 27px;
}

@media (max-width: 991.98px) {
  .img-sample-diy {
    display: none;
  }
  .sample {
    text-align: center;
    margin-top: -70px;
  }
  .sample-p {
    text-align: center;
    margin-top: 20px;
  }
  .line-communities {
    margin-top: 56px;
  }
  .retangle {
    width: 70%;
    margin-left: calc(50% - 235.5px);
  }
  .img-survey {
    width: 132px;
    margin-bottom: 20px;
  }
}

.dots-1 {
  right: -8%;
  position: absolute;
  margin-top: -11%;
}

.img-animacao {
  position: relative;
}

.line-communities {
  align-items: center;
  padding-bottom: 5%;
  width: 100%;
}

.section4 .text h3 {
  font-weight: 700;
  font-size: 2rem;
  color: black;
}

.survey-p {
  font-size: 14px;
}

.survey-p {
  font-size: 20px;
}

.item {
  display: flex;
}

.section4 .item p {
  color: #707070;
  font-size: 12px;
}

.title {
  p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.vertical-items {
  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 30px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 13px;

      margin-bottom: 0;
    }
  }
}

.btn-getstarted {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #275982;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 20px 3px 20px;
  transition: all 0.3s ease;
  margin: 5px;
}

.btn-getstarted:hover {
  text-decoration: none;
  color: #ffffff;
  background: #487da8;
}
@media (max-width: 767.98px) {
  .retangle {
    width: 90%;
    margin-left: calc(50% - 235.5px);
  }
}

@media (max-width: 575.98px) {
  .retangle {
    width: 100%;
    margin-left: 0px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .text-retangle-last {
    font-size: 13px;
    position: relative;
    max-width: 340px;
    margin: 0px 0 0px 80px;
  }

  .text-retangle-midle {
    font-size: 13px;
    position: relative;
    max-width: 340px;
    margin: 0px 0 0px 80px;
  }

  .text-retangle-last {
    font-size: 13px;
    position: relative;
    max-width: 340px;
    margin: 0px 0 0px 80px;
  }
}

@media (max-width: 414px) {
  .btn-focus {
    margin-left: 0;
  }
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 359px) {
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
}

.img-owvoices {
  width: 132px;
  margin-bottom: 27px;
}
</style>
