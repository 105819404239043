import { render, staticRenderFns } from "./second-section.vue?vue&type=template&id=08856ef6&scoped=true"
import script from "./second-section.vue?vue&type=script&lang=js"
export * from "./second-section.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08856ef6",
  null
  
)

export default component.exports