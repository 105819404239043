<template>
  <section class="py-5 intro">
    <div class="container py-5">
      <div class="row py-5 p-relative">
        <div class="col-xl-5 d-flex justify-content-center">
          <div class="height-title">
            <h1 class="title-align">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .market_research_solutions
                  : "Market research solutions"
              }}
            </h1>
          </div>
        </div>
        <div class="col-xl-6 h100 banner">
          <img src="@/assets/banner-cellphone.png" class="img-banner" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.intro {
  position: relative;
  height: 420px;
  background: linear-gradient(
    133.2deg,
    rgba(236, 240, 248, 0.43) 38.39%,
    #ccdde5 93.86%
  );
}
h1 {
  font-weight: 700;
  font-size: 1.8rem;
  color: #000;
  //text-align: center;
  align-self: center;
  margin: 40px 0 0 48px;
  width: 450px;
}

.img-banner {
  height: 400px;
  position: relative;
  top: -150px;
  left: 0px;
}

.title-align {
  margin-left: 100px;
}

.height-title {
  position: relative;
  left: 60px;
  top: 30px;
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

@media (max-width: 1199.98px) {
  h1 {
    text-align: center;
    margin-left: 0px;
  }

  .banner {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .img-banner {
    height: 370px;
    position: relative;
    transform: rotate(-0deg);
    top: -90px;
    left: -80px;
  }

  .height-title {
    position: relative;
    left: 60px;
    top: -30px;
  }

  .intro {
    position: relative;
    height: 570px;
    background: linear-gradient(
      133.2deg,
      rgba(236, 240, 248, 0.43) 38.39%,
      #ccdde5 93.86%
    );
  }

  .title-align {
    margin-left: -125px;
    max-width: 300px;
  }

  .height-title {
    position: relative;
    bottom: 50px;
  }
}

@media (max-width: 575.98px) {
  .intro {
    position: relative;
    height: 570px;
    background: linear-gradient(
      133.2deg,
      rgba(236, 240, 248, 0.43) 38.39%,
      #ccdde5 93.86%
    );
  }
  .img-banner {
    img {
      width: 450px;
      position: absolute !important;
      z-index: 0;
      top: -110px;
      right: 10px;
    }
  }
  .title-align {
    margin-left: -120px;
  }
}

@media (min-width: 1400px) {
  .intro {
    height: 425px;
    background: linear-gradient(182.79deg, #f7fafb 34.8%, #ccdde2 125.74%);
    padding-top: 125px;
    position: relative;
    overflow: hidden;
    .container {
      position: relative;
    }
  }

  .img-banner {
    height: 370px;
    position: relative;
    top: -120px;
    left: 0px;
  }

  .title-align {
    margin-left: 100px;
  }

  .height-title {
    position: relative;
    bottom: 30px;
    left: 60px;
  }
}
</style>
