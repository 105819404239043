<template>
  <div>
    <section class="py-5 footer">
      <div class="container">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-lg-3">
            <router-link :to="'/'">
              <img
                src="@/assets/logo-ow.svg"
                alt="Logo Offerwise"
                id="logo-ow"
              />
            </router-link>
          </div>
          <div class="col-lg-3 headquarters">
            <div class="bh-hq">
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'en'"
              >
                Brazil Headquarters
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'pt'"
              >
                Sede Brasil
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'es'"
              >
                Sede Brasil
              </p>
              <p>Av. Barão Homem de Melo 4484, 2º, 4º e 5º andares</p>
              <p>CEP: 30494-270 - Estoril</p>
              <p>Belo Horizonte – MG</p>
            </div>
            <div class="dc-hq">
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'en'"
              >
                USA - Virginia
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'pt'"
              >
                EUA - Virgínia
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'es'"
              >
                EUA - Virginia
              </p>
              <p>44679 Endicott Drive</p>
              <p>Suite 300</p>
              <p>Ashburn, VA 20147</p>
            </div>

            <div class="dc-hq">
              <p class="font-weight-bold mb-1">
                Argentina
              </p>
              <p>Humboldt 1967, C1414 Cdad.</p>
              <p>Autónoma de Buenos Aires, Argentina</p>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="col-hq">
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'en'"
              >
                Colombia
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'pt'"
              >
                Colômbia
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'es'"
              >
                Colombia
              </p>
              <p>Cll 77#11-19 oficina 702</p>
              <p v-if="$store.getters.lang === 'en'">Bogota, Colombia</p>
              <p v-if="$store.getters.lang === 'pt'">Bogotá, Colômbia</p>
              <p v-if="$store.getters.lang === 'es'">Bogotá, Colombia</p>
            </div>
            <div class="mx-hq">
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'en'"
              >
                Mexico
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'pt'"
              >
                México
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'es'"
              >
                México
              </p>
              <p>
                Londres 61-Piso 14 Oficina 04, Juárez, Cuauhtémoc, 06600 Ciudad
                de México, CDMX
              </p>
            </div>
            <div class="dc-hq">
              <p class="font-weight-bold mb-1">
                Chile
              </p>
              <p>General Holley 133, Providencia</p>
            </div>
            <div class="sales">
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'en'"
              >
                LOCAL OFFICES:
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'pt'"
              >
                ESCRITÓRIOS LOCAIS:
              </p>
              <p
                class="font-weight-bold mb-1"
                v-if="$store.getters.lang === 'es'"
              >
                OFICINAS LOCALES:
              </p>
              <div class="d-flex justify-content-start">
                <div class="col p-0">
                  <p v-if="$store.getters.lang === 'en'">Peru</p>
                  <p v-if="$store.getters.lang === 'pt'">Peru</p>
                  <p v-if="$store.getters.lang === 'es'">Perú</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="DPLaws">
              <router-link :to="{ name: 'dpl' }" class="router">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.data_protection.data_protection_laws
                    : "Data protection laws"
                }}
              </router-link>
            </div>
            <div class="followUs">
              <p class="font-weight-bold m-0">FOLLOW US</p>
              <div class="d-flex align-items-center mt-3">
                <a
                  href="https://br.linkedin.com/company/offerwise"
                  class="align-icons"
                  target="_blank"
                  ><img src="@/assets/icon-linkedin.svg"
                /></a>
                <a
                  href="https://www.instagram.com/offerwiseresearch"
                  class="align-icons"
                  target="_blank"
                  ><img src="@/assets/icon-instagram.svg"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UCvLRefUzMyTai4sAXPu0gKw"
                  class="align-icons"
                  target="_blank"
                  ><img src="@/assets/icon-youtube.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="col-md-12 footerWhite py-2 d-flex justify-content-center">
      <p>© Offerwise 2024</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

section {
  background: #f4f8fc;
}
.footer {
  font-family: "Roboto", sans-serif;
}

p {
  margin: 0;
  font-size: 0.75rem;
  color: #7d9bad;
  font-weight: 400;
  line-height: 14.06px;
}
.bh-hq,
.dc-hq,
.mx-hq,
.col-hq {
  margin-bottom: 22px;
}

#contact p {
  margin-left: 60px;
}
.sales p {
  font-weight: 500;
}
.DPLaws {
  margin-bottom: 70px;
  .router {
    font-weight: 700;
    font-size: 0.6875rem;
    color: #000;
    text-transform: uppercase;
    cursor: pointer;
  }
}
.followUs p {
  font-size: 1rem;
  font-weight: 700;
  color: #000000;
  margin-left: 30px;
}
.align-icons {
  margin-right: 10px;
}

.footerWhite {
  background: #ffffff;
  p {
    font-weight: bold;
    font-size: 0.75rem;
    color: #9c9c9c;
  }
  a {
    text-decoration: underline;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  #logo-ow {
    margin-bottom: 15px;
  }

  .headquarters {
    margin: 15px 0;
  }
  .bh-hq,
  .dc-hq,
  .mx-hq,
  .col-hq {
    margin-bottom: 15px;
  }
  .sales {
    margin: 15px 0;
  }
  .DPLaws {
    margin: 5px 0 30px 0;
  }
  .footerWhite {
    p {
      font-size: 0.65rem;
    }
    a {
      font-size: 0.65rem;
    }
  }
}
</style>
