<template>
  <section class="py-5" id="secondSectionProduct">
    <div class="container p-relative">
      <div class="row">
        <div class="col fullService w-100">
          <h2>
            {{
              $store.getters.trans
                ? $store.getters.trans.research_solutions.end_to_end_solutions
                    .title
                : "End-to-end Solutions"
            }}
          </h2>
          <p>
            {{
              $store.getters.trans
                ? $store.getters.trans.research_solutions.end_to_end_solutions
                    .text
                : "We provide end-to-end solutions for your market research projects"
            }}
          </p>
        </div>
      </div>
      <div class="row">
        <div
          class="col d-flex justify-content-center align-items-center mt-5 icons"
        >
          <div class="item">
            <div
              @mouseover="programming = true"
              @mouseleave="programming = false"
              :class="{ active: programming }"
            >
              <img src="@/assets/programming.svg" alt="" />
              <h6>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.scripting
                    : "Scripting"
                }}
              </h6>
            </div>
            <div class="textHoverP" :class="{ active: programming }">
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.scripting_info
                    : "Program question using various methodologies including MaxDiff, Conjoint, Heat Map, Drag-and-Drop, and Interactive Test The responsive design works on all electronic devices"
                }}.
              </p>
              <div class="triangle">
                <div class="triangleUp-P"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="item">
            <div
              @mouseover="dataCollection = true"
              @mouseleave="dataCollection = false"
              :class="{ active: dataCollection }"
            >
              <img src="@/assets/data-collection.svg" alt="" />
              <h6>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.data_collection
                    : "Data Collection"
                }}
              </h6>
            </div>
            <div class="textHoverDC" :class="{ active: dataCollection }">
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions
                        .data_collection_info
                    : "The profiled panel maximizes the number of interviews and reduces the period necessary to the field. Our artificial intelligence software, FRAUD AI, guarantee the integrity and high-quality of the data collected "
                }}.
              </p>
              <div class="triangle">
                <div class="triangleUp"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="item">
            <div
              @mouseover="dataProcessing = true"
              @mouseleave="dataProcessing = false"
              :class="{ active: dataProcessing }"
            >
              <img src="@/assets/data-processing.svg" alt="" />
              <h6>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.data_processing
                    : "Data Processing"
                }}
              </h6>
            </div>
            <div class="textHoverDP" :class="{ active: dataProcessing }">
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions
                        .data_processing_info
                    : "Data processing uses the best tools and methodologies available on the market to provide insights rapidly."
                }}.
              </p>
              <div class="triangle">
                <div class="triangleUp"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="item itemS">
            <div
              @mouseover="statistics = true"
              @mouseleave="statistics = false"
              :class="{ active: statistics }"
            >
              <img src="@/assets/statistical.svg" alt="" />
              <h6>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions
                        .statistical_analysis
                    : "Statistical Analysis"
                }}
              </h6>
            </div>
            <div class="textHoverS" :class="{ active: statistics }">
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions
                        .statistical_analysis_info
                    : "Statistical analysis helps each client to make the best decisions and to meet the market research goals."
                }}.
              </p>
              <div class="triangle">
                <div class="triangleUp"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="item">
            <div
              @mouseover="report = true"
              @mouseleave="report = false"
              :class="{ active: report }"
            >
              <img src="@/assets/report.svg" alt="" id="icon-report" />
              <h6>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.final_report
                    : "Final Report"
                }}
              </h6>
            </div>
            <div class="textHoverR" :class="{ active: report }">
              <p class="text">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.final_report_info
                    : "The layouts have a modern design that facilitates comprehension. The dashboard with the results explains all the statistical findings of the research."
                }}.
              </p>
              <div class="triangle">
                <div class="triangleUp-rf"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bgSecond">
        <img src="@/assets/bgSecond.svg" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      programming: false,
      dataCollection: false,
      dataProcessing: false,
      statistics: false,
      report: false
    };
  }
};
</script>

<style lang="scss" scoped>
.fullService {
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    color: #000;
  }
  p {
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
    color: #666;
  }
}
.line {
  width: 71px;
  height: 0;
  border-bottom: 2px solid #d1d3d4;
  margin-top: -40px;
}
.icons {
  #icon-report {
    max-height: 76px;
  }
  img {
    margin-left: calc(50% - 38px);
    width: 75px;
    min-height: 76px;
  }
  h6 {
    font-weight: 500;
    font-size: 0.8125rem;
    color: #7d9bad;
    text-align: center;
  }
}
.item {
  position: relative;
  width: 150px;
}
.text {
  width: 100%;
  font-weight: 400;
  font-size: 0.8rem;
  padding: 10px;
  margin-bottom: 0;
  text-align: center;
}

.textHoverP {
  background-color: #eeeeee;
  border-radius: 3px;
  width: 290px;
  position: absolute;
  z-index: 5;
  left: -47px;
  margin-top: 20px;
  display: none;
  animation: opacity 0.5s ease-in-out;
  &.active {
    display: block;
  }
}

.textHoverDC {
  background-color: #eeeeee;
  border-radius: 3px;
  width: 290px;
  position: absolute;
  z-index: 5;
  left: -70px;
  margin-top: 20px;
  display: none;
  animation: opacity 0.5s ease-in-out;
  &.active {
    display: block;
  }
}

.textHoverDP {
  background-color: #eeeeee;
  border-radius: 3px;
  width: 290px;
  position: absolute;
  z-index: 5;
  left: -72px;
  margin-top: 20px;
  display: none;
  animation: opacity 0.5s ease-in-out;
  &.active {
    display: block;
  }
}
.textHoverS {
  background-color: #eeeeee;
  border-radius: 3px;
  width: 290px;
  position: absolute;
  z-index: 5;
  right: -70px;
  margin-top: 20px;
  display: none;
  animation: opacity 0.5s ease-in-out;
  &.active {
    display: block;
  }
}

.textHoverR {
  background-color: #eeeeee;
  border-radius: 3px;
  width: 290px;
  position: absolute;
  z-index: 5;
  right: -47px;
  margin-top: 20px;
  display: none;
  animation: opacity 0.5s ease-in-out;
  &.active {
    display: block;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.8;
  }
}

.triangleUp {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #eeeeee;
  position: absolute;
  top: -14px;
  left: calc(50% - 15px);
}

.triangleUp-rf {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #eeeeee;
  position: absolute;
  top: -14px;
  left: calc(50% + 10px);
}

.triangleUp-P {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #eeeeee;
  position: absolute;
  top: -14px;
  left: calc(50% - 38px);
}

#bgSecond {
  img {
    position: absolute;
    z-index: -2;
    top: 20px;
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .textHoverP {
    left: -57px;
  }

  .textHoverDC {
    left: -78px;
  }

  .textHoverDP {
    left: -80px;
  }
  .textHoverS {
    right: -78px;
  }

  .textHoverR {
    right: -55px;
  }
}

@media (max-width: 991.98px) {
  section {
    margin-bottom: 70px;
  }
  .icons {
    #icon-report {
      height: 66px;
    }
    img {
      margin-left: calc(50% - 32.5px);
      width: 65px;
      min-height: 66px;
    }
    h6 {
      font-size: 0.75rem;
    }
  }
  .itemS {
    padding-top: 15px;
  }
  .line {
    width: 51px;
    margin-top: -30px;
  }
  .text {
    width: 100%;
  }
  .textHoverP {
    width: 290px;
    left: -36px;
  }

  .textHoverDC {
    width: 290px;
    left: -89px;
  }

  .textHoverDP {
    width: 290px;
    left: -95px;
  }
  .textHoverS {
    width: 290px;
    right: -92px;
    margin-top: 20px;
  }

  .textHoverR {
    width: 290px;
    right: -36px;
  }
  .triangleUp-rf {
    left: calc(50% + 40px);
  }

  .triangleUp-P {
    left: calc(50% - 70px);
  }
}
@media (max-width: 767.98px) {
  .icons {
    display: block !important;
    #icon-report {
      height: 66px;
    }
    img {
      width: 66px;
      min-height: 66px;
    }
    h6 {
      font-size: 0.9rem;
    }
    .item {
      display: flex;
      justify-content: center;
      margin-left: calc(50% - 177px);
    }
  }
  .line {
    width: 40px;
    margin: 50px 0;
    margin-left: calc(50% - 122.5px);
    transform: rotate(90deg);
  }
  #bgSecond {
    display: none;
  }
  .triangleUp {
    position: absolute;
    top: calc(50% - 7.5px);
    left: -22px;
    transform: rotate(270deg);
  }
  .triangleUp-rf {
    position: absolute;
    top: calc(50% - 7.5px);
    left: -22px;
    transform: rotate(270deg);
  }

  .triangleUp-P {
    position: absolute;
    top: calc(50% - 7.5px);
    left: -22px;
    transform: rotate(270deg);
  }
  .text {
    text-align: center;
    width: 100%;
    font-size: 0.65rem;
  }
  .textHoverP {
    width: 200px;
    left: 170px;
    margin-top: -10px;
    display: block;
  }
  .textHoverDC {
    width: 200px;
    left: 170px;
    margin-top: -26px;
    display: block;
  }

  .textHoverDP {
    width: 200px;
    left: 170px;
    margin-top: 0px;
    display: block;
  }
  .textHoverS {
    width: 200px;
    left: 170px;
    margin-top: 0px;
    display: block;
  }

  .textHoverR {
    width: 200px;
    left: 170px;
    margin-top: -15px;
    display: block;
  }
}

@media (max-width: 576.98px) {
  .triangleUp {
    position: absolute;
    top: calc(50% - 7.5px);
    left: -22px;
    transform: rotate(270deg);
  }
  .triangleUp-rf {
    position: absolute;
    top: calc(50% - 7.5px);
    left: -22px;
    transform: rotate(270deg);
  }

  .triangleUp-P {
    position: absolute;
    top: calc(50% - 7.5px);
    left: -22px;
    transform: rotate(270deg);
  }
  .text {
    text-align: center;
    width: 180px;
    font-size: 0.55rem;
  }
  .textHoverP {
    width: 180px;
    left: 150px;
    margin-top: 5px;
    display: block;
  }
  .textHoverDC {
    width: 180px;
    left: 150px;
    margin-top: -23.5px;
    display: block;
  }

  .textHoverDP {
    width: 180px;
    left: 150px;
    margin-top: 10px;
    display: block;
  }
  .textHoverS {
    width: 180px;
    left: 150px;
    margin-top: 10px;
    display: block;
  }

  .textHoverR {
    width: 180px;
    left: 150px;
    margin-top: 0px;
    display: block;
  }
}
</style>
