var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-wrap"},[_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .communication_and_branding : "Communication and Branding")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.brand_health : "Brand Health")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .identify_positionings : "Identify brand positionings that should have been enforced or modified. Optimize your communication efforts.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Brand Health' }
              }}},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .communication_and_branding : "Communication and Branding")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .pre_test : "Pre/post Communication Test")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .test_efficiency : "Test the efficiency of an advertising campaign before launching it, and analyze the performance after airing it.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Pre Test' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(2),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.indicators : "Indicators")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .omnibus_survey : "Omnibus Survey")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .obtain_information_in_one_market_research : "Obtain information about different subjects in one market research. With low-cost budgets, it is possible to conduct omnibuses monthly.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Omnibus Survey' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(3),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.indicators : "Indicators")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.public_affairs : "Public Affairs")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .comprehend_relationship : "Comprehend the relationship your brand has with the target audience. Plan your efforts to build a closer brand-consumer relationship.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Public Affairs' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(4),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.indicators : "Indicators")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.ad_hoc_tracker : "Ad hoc/Tracker")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .create_personalized_research : "Create personalized research to meet the necessities of your business. Repeat it over time and monitor the results.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Ad hoc Tracker' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(5),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative.test : "Tests")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.ihut : "IHUT")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .send_physical_products : "Send physical products to the home of a group of consumers and receive feedbacks about design and usability.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - IHUT' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(6),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative.test : "Tests")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .concept_test : "Concept Test")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .identify_necessities : "Identify the necessities, wishes, and preferences of your consumers regarding new products.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Concept Test' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(7),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative.test : "Tests")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.task_panel : "Task Panel")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .create_tasks : "Create tasks that consumers will accomplish by consumers and comprehend their habits.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Task Performance' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+">")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(8),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative.test : "Tests")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .packing_and_product : "Packaging and Product Tests")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .consult_consumer : " Consult your consumer's opinions about the new packaging of a product and design changes. Meet their expectations e become a standout in the market.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Quantitative - Packaging and Product Tests' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+">")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/health.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/rate.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/bus.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/megaphone.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/graphic.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/ihut.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/search.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/bottle.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/computer-quantative.svg"),"alt":""}})])
}]

export { render, staticRenderFns }