var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pt-5",attrs:{"id":"contact"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center justify-content-between items"},[_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.landpage.no_license_fees : "No license fees")+" ")]),_c('div',{staticClass:"ball"}),_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.landpage.unlimited : "Unlimited users")+" ")]),_c('div',{staticClass:"ball"}),_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.landpage.tailored : "Tailored bundled services")+" ")]),_c('div',{staticClass:"ball"}),_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.landpage.pay : "Pay using your local currency")+" ")])])]),_c('div',{staticClass:"row py-5 justify-content-center second-row"},[_c('div',{staticClass:"col-10 p-relative d-flex justify-content-center"},[_c('div',{staticClass:"box"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.landpage.buttons_and_inputs .request_a_demo : "Request a Demo")+" ")]),_c('form',{on:{"submit":_vm.sendLP}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"type":"text","id":"name","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.name
                      : 'name:',"required":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","id":"email","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.email
                      : 'E-mail:',"required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.$store.getters.lang === 'en')?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-####'),expression:"'+# (###) ###-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.phone
                      : 'Phone:'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}):_vm._e(),(_vm.$store.getters.lang === 'es')?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+## ###-##-##-##'),expression:"'+## ###-##-##-##'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.phone
                      : 'Phone:'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}):_vm._e(),(_vm.$store.getters.lang === 'pt')?_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+## (##) #####-####'),expression:"'+## (##) #####-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.phone
                      : 'Phone:'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.company
                      : 'Company:'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"col-lg-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],attrs:{"type":"country","id":"country","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.country
                      : 'Country:'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"text-area",attrs:{"id":"message","placeholder":_vm.$store.getters.trans
                      ? _vm.$store.getters.trans.menu_contact.message
                      : 'Message:'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}}),_c('div',{staticClass:"d-flex justify-content-end captcha"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"d-flex justify-content-end align-btn"},[(!_vm.loader)?_c('button',{staticClass:"btn btn-send"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Send")+" ")]):_vm._e()])])])])])])])]),_c('div',{staticClass:"bg"})])
}
var staticRenderFns = []

export { render, staticRenderFns }