import Vue from "vue";
import Vuex from "vuex";
import { api } from "@/services.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: null,
    trans: null
  },
  mutations: {
    lang(state, lang) {
      state.lang = lang;
      window.localStorage.setItem("lang", lang);
    },
    trans(state, lang) {
      api.get(`translation?lang=${lang}`).then(r => {
        if (r.data) {
          state.trans = JSON.stringify(r.data);
          window.localStorage.setItem("trans", JSON.stringify(r.data));
        }
      });
    }
  },
  getters: {
    lang: state => state.lang || window.localStorage.getItem("lang"),
    trans: state =>
      JSON.parse(state.trans) ||
      JSON.parse(window.localStorage.getItem("trans"))
  }
});
