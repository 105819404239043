<template>
  <div id="app">
    <offerwise-header />
    <main id="main">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
    <offerwise-footer />
  </div>
</template>

<script>
import offerwiseHeader from "@/components/general/offerwise-header.vue";
import offerwiseFooter from "@/components/general/offerwise-footer.vue";

export default {
  components: {
    offerwiseHeader,
    offerwiseFooter
  },
  computed: {
    lang() {
      return this.$route.query.lang;
    }
  },
  created() {
    if (this.lang) {
      if (this.lang === "es" || this.lang === "pt" || this.lang === "en") {
        this.$store.commit("lang", this.lang);
        this.$store.commit("trans", this.lang);
      }
    } else {
      const lang = (navigator.language || navigator.userLanguage).split("-")[0];
      if (lang === "es" || lang === "pt" || lang === "en") {
        this.$store.commit("lang", lang);
        this.$store.commit("trans", lang);
        const query = { ...this.$route.query, lang: lang };
        this.$router.replace({ query });
      }
    }

    if (this.$route.query?.utm_term) {
      sessionStorage.setItem("owsite-utm_term", this.$route.query.utm_term);
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

#app {
  display: flex;
  flex-direction: column;
  min-height: 110vh;
  font-family: "Poppins", sans-serif;
  transition: all 1s ease;
  overflow: hidden;
}

#main {
  flex: 1;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter {
  transform: translate3d(0, -20px, 0);
}

.v-leave-to {
  transform: translate3d(0, 20px, 0);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.bm-menu {
  background-color: #000 !important;
}

.bm-burger-button {
  top: 15px !important;
  right: 20px !important;
  z-index: 5;
}

.bm-burger-bars {
  background-color: #000 !important;
}

.bm-cross {
  background: #fff !important;
}

.h100 {
  height: 100%;
}

.p-relative {
  position: relative;
}

.carousel-caption {
  text-align: left !important;
  h4,
  p {
    color: #666666;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 50px;
  height: 50px;
}

.carousel-control-prev-icon {
  background-image: url("../src/assets/arrow-left.svg") !important;
}

.carousel-control-next-icon {
  background-image: url("../src/assets/arrow-right.svg") !important;
}

.carousel-indicators .active {
  opacity: 1;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  outline: none;
}
.VueCarousel-navigation-prev {
  left: 15px !important;
  transform: none;
  font-family: "system";
}
.VueCarousel-navigation-next {
  right: 15px !important;
  transform: none;
  font-family: "system";
}
.VueCarousel-navigation-next::after {
  content: url("../src/assets/arrow-next.png");
}
.VueCarousel-navigation-prev::after {
  content: url("../src/assets/arrow-back.png");
}
.VueCarousel-navigation-button[data-v-453ad8cd]:focus {
  outline: none !important;
}
.carouselProduct {
  .VueCarousel-navigation-next::after {
    content: url("../src/assets/arrow-next.svg");
  }
  .VueCarousel-navigation-prev::after {
    content: url("../src/assets/arrow-prev.svg");
  }
  .VueCarousel-navigation-prev {
    left: 20px !important;
    bottom: 130px !important;
    transform: none;
    font-family: "system";
  }
  .VueCarousel-navigation-next {
    right: 20px !important;
    bottom: 130px !important;
    transform: none;
    font-family: "system";
  }
  .VueCarousel-pagination[data-v-438fd353] {
    display: none;
  }
}

.carousel-Careers {
  .VueCarousel-pagination[data-v-438fd353] {
    position: absolute;
    bottom: 0px;
  }

  .VueCarousel-dot--active {
    background-color: #4f87da !important;
    outline: none !important;
  }

  .VueCarousel-dot {
    outline: none !important;
  }
}

.bm-menu {
  background-color: #fff !important;
}

.ytp-impression-link-logo {
  display: none !important;
}

.ytp-impression-link-text {
  display: none !important;
}
.ytp-impression-link-content {
  display: none !important;
}
.html5-video-player a {
  display: none !important;
}
.ytp-impression-link {
  display: none !important;
}
.bm-item-list {
  margin-left: 10px;
}
.bm-menu {
  padding-top: 30px !important;
}
.carousel-item[data-v-42e3562f],
.carousel-inner[data-v-42e3562f] {
  height: 480px;
  position: relative;
}
.carousel-caption {
  max-height: 210px !important;
  max-width: 100% !important;
  margin-bottom: 40px;
  position: relative;
  left: 0;
  bottom: -214px;
}

// carousel LGPD
.carousel-lgpd {
  .VueCarousel-dot--active {
    background-color: #4f87da !important;
    outline: none !important;
  }

  .VueCarousel-dot {
    outline: none !important;
    width: 15px !important;
    height: 15px !important;
    background: #ddd;
  }

  .VueCarousel-inner {
    align-items: center !important;
    flex-basis: 1000px;
    height: 400px !important;
  }

  .VueCarousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1200px) and (max-width: 1270px) {
  .carouselProduct {
    .VueCarousel-navigation-prev {
      left: 35px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: 35px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
  }
}

@media (max-width: 1199.98px) {
  .carouselProductCareers {
    .VueCarousel-navigation-prev {
      left: 150px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: 150px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
  }
}

@media (max-width: 991.98px) {
  .carouselProductCareers {
    .VueCarousel-navigation-prev {
      left: 80px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: 80px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
  }
}

@media (min-width: 992px) and (max-width: 1080px) {
  .carouselProduct {
    .VueCarousel-navigation-prev {
      left: 60px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: 60px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
  }
}
@media (max-width: 829.98px) {
  .carouselProduct {
    .VueCarousel-navigation-prev {
      left: 160px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: 160px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
  }
}
@media (max-width: 767.98px) {
  .carouselProduct {
    .VueCarousel-navigation-prev {
      left: 90px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: 90px !important;
      bottom: 150px !important;
      transform: none;
      font-family: "system";
    }
  }
  .carouselProductCareers {
    .VueCarousel-navigation-prev {
      left: -10px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: -10px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
  }
}

@media (max-width: 671px) {
  .carouselProductCareers {
    .VueCarousel-navigation-prev {
      display: none;
    }
    .VueCarousel-navigation-next {
      display: none;
    }
  }
  .carouselProductCareers .VueCarousel-pagination[data-v-438fd353] {
    display: block;
  }
  .VueCarousel-pagination[data-v-438fd353] {
    position: static;
    z-index: 10;
    display: block;
    margin-top: -28px;
  }
  .VueCarousel-dot--active {
    background-color: #4f87da !important;
    outline: none !important;
  }
  .VueCarousel-dot {
    outline: none !important;
  }
}

@media (max-width: 575.98px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 40px;
    height: 40px;
  }
  .carousel-control-prev-icon {
    margin-left: -20px !important;
  }
  .carousel-control-next-icon {
    margin-right: -20px !important;
  }
  .carouselProduct {
    .VueCarousel-navigation-prev {
      display: none;
    }
    .VueCarousel-navigation-next {
      display: none;
    }
    .VueCarousel-pagination[data-v-438fd353] {
      position: static;
      z-index: 10;
      display: block;
      margin-top: -68px;
    }
    .VueCarousel-dot--active {
      background-color: #4f87da !important;
      outline: none !important;
    }
    .VueCarousel-dot {
      outline: none !important;
    }
  }
  .carousel-caption {
    max-height: 200px !important;
    margin-bottom: 40px;
  }

  .carousel-indicators {
    bottom: -70px;
  }
}

.custom-control-label::before {
  position: absolute;
  top: -1px;
  left: -30px;
  display: block;
  width: 23px;
  height: 23px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: -1px;
  left: -29px;
  display: block;
  width: 23px;
  height: 23px;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

@media (min-width: 1200px) {
  .carouselProductCareers {
    .VueCarousel-navigation-prev {
      left: -10px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
    .VueCarousel-navigation-next {
      right: -10px !important;
      bottom: 70px !important;
      transform: none;
      font-family: "system";
    }
  }
}
</style>
