var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"background mb-1"},[_c('img',{staticClass:"img-fluid img-contact",attrs:{"src":require("@/assets/bg-contact.svg"),"alt":"Imagem"}}),_c('div',{staticClass:"bg-color"}),_c('div',{staticClass:"container py-5 h100"},[_c('div',{staticClass:"row justify-content-between contact pt-3"},[_vm._m(0),_c('div',{staticClass:"col-lg-5 survey"},[_c('div',{staticClass:"title pb-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.page_products.request_a_demo : "Request a Demo")+" ")])]),(_vm.$store.getters.lang === 'en')?_c('form',{on:{"submit":_vm.applyJob}},[_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"name","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.name
                  : 'Name'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.email
                  : 'E-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-2 mb-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-####'),expression:"'+# (###) ###-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"form-control p-4 input input-tel",attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.phone
                  : 'Phone'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control p-4 input input-country",attrs:{"type":"text","id":"country","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.country
                  : 'Country'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.company
                  : 'Company'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"mb-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"id":"msg","rows":"5","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.message
                  : 'Message'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}})]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"d-flex button-align"},[_c('button',{staticClass:"btn btn-request"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Request")+" ")])])]):_vm._e(),(_vm.$store.getters.lang === 'es')?_c('form',{on:{"submit":_vm.applyJob}},[_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"name","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.name
                  : 'Name'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.email
                  : 'E-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-2 mb-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+## ###-##-##-##'),expression:"'+## ###-##-##-##'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"form-control p-4 input input-tel",attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.phone
                  : 'Phone'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control p-4 input input-country",attrs:{"type":"text","id":"country","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.country
                  : 'Country'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.company
                  : 'Company'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"mb-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"id":"msg","rows":"5","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.message
                  : 'Message'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}})]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"d-flex button-align"},[_c('button',{staticClass:"btn btn-request"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Request")+" ")])])]):_vm._e(),(_vm.$store.getters.lang === 'pt')?_c('form',{on:{"submit":_vm.applyJob}},[_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"name","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.name
                  : 'Name'},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","required":"","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.email
                  : 'E-mail'},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"input-2 mb-3"},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+## (##) #####-####'),expression:"'+## (##) #####-####'"},{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],staticClass:"form-control p-4 input input-tel",attrs:{"type":"text","id":"phone","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.phone
                  : 'Phone'},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.country),expression:"country"}],staticClass:"form-control p-4 input input-country",attrs:{"type":"text","id":"country","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.country
                  : 'Country'},domProps:{"value":(_vm.country)},on:{"input":function($event){if($event.target.composing)return;_vm.country=$event.target.value}}})]),_c('div',{staticClass:"input mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company),expression:"company"}],staticClass:"form-control p-4",attrs:{"type":"text","id":"company","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.company
                  : 'Company'},domProps:{"value":(_vm.company)},on:{"input":function($event){if($event.target.composing)return;_vm.company=$event.target.value}}})]),_c('div',{staticClass:"mb-5"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.msg),expression:"msg"}],staticClass:"form-control",attrs:{"id":"msg","rows":"5","placeholder":_vm.$store.getters.trans
                  ? _vm.$store.getters.trans.menu_contact.message
                  : 'Message'},domProps:{"value":(_vm.msg)},on:{"input":function($event){if($event.target.composing)return;_vm.msg=$event.target.value}}})]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('vue-recaptcha',{attrs:{"sitekey":"6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7","loadRecaptchaScript":true},on:{"verify":_vm.mxVerify}})],1),_c('div',{staticClass:"d-flex button-align"},[_c('button',{staticClass:"btn btn-request"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.menu_contact.send : "Request")+" ")])])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-7"},[_c('div',{staticClass:"justify-content-center logo"},[_c('img',{staticClass:"img-fluid img-focus img-logo",attrs:{"src":require("@/assets/focusnewlogo.svg"),"alt":""}})]),_c('div',{staticClass:"logo-diy img-hide"},[_c('img',{staticClass:"dots-1",attrs:{"src":require("@/assets/dots.svg"),"alt":"Imagem"}}),_c('img',{staticClass:"img-fluid img-intro-contact",attrs:{"src":require("@/assets/owFocus.png"),"alt":"Imagem"}})])])
}]

export { render, staticRenderFns }