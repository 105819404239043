<template>
  <section class="pt-3 pb-2">
    <div class="container" if="partners.length">
      <div class="item mb-5">
        <h3>
          {{
            $store.getters.trans
              ? $store.getters.trans.home.partner_associations
              : "Partner associations"
          }}
        </h3>
        <carousel
          :perPage="1"
          :perPageCustom="[
            [576, 2],
            [767, 3],
            [992, 4],
            [1024, 4],
            [1200, 5]
          ]"
          :navigationEnabled="true"
          :paginationEnabled="false"
          navigation-next-label=""
          navigation-prev-label=""
        >
          <slide v-for="(partners, index) in partners" :key="index">
            <img :src="partners.logo" alt="" />
          </slide>

          <slide>
            <img src="@/assets/logo-AIM.svg" alt="" class="apeim" />
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      partners: []
    };
  },
  methods: {
    getPartners() {
      api.get("partners").then(r => {
        this.partners = r.data;
      });
    },
    init() {
      this.getPartners();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.VueCarousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.item {
  border-top: 0.5px solid #ccdde5;
  padding-top: 40px;
}
img {
  min-width: 104px;
  min-height: 39px;
  filter: grayscale(100%);
}
.apeim {
  width: 144px;
  height: 60px;
}
h3 {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;
  color: #666666;
  margin-bottom: 60px;
}

@media (max-width: 600px) {
  section {
    margin-top: -20px;
    margin-bottom: 40px;
  }
  h3 {
    font-size: 1rem;
  }
}
</style>
