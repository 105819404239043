<template>
  <div class="panelbook">
    <intro />
  </div>
</template>

<script>
import intro from "@/components/panelbook/intro.vue";

export default {
  name: "Panelbook",
  components: {
    intro
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
