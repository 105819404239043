<template>
  <section class="py-5 bg" id="how-work">
    <div class="container">
      <div
        class="row"
        @click="option = 'clickOut'"
        :class="{ active: option === 'clickOut' }"
      >
        <div class="col how-works">
          <h3>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.how_it_works
                : "How it works"
            }}
          </h3>
          <div class="text">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.ow_survey_definite
                  : "OW Survey is your definite DIY survey platform to operationalize all your market research projects"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="row second-row">
        <div class="col-xl-6 p-relative">
          <div
            class="box"
            @click="option = 'upload'"
            :class="{ active: option === 'upload' }"
          >
            <div class="d-flex item">
              <img src="@/assets/upload-blue.svg" alt="" class="blue" />
              <img
                src="@/assets/upload-white.svg"
                alt=""
                class="white"
                :class="{ active: option === 'upload' }"
              />
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.send_text_file
                    : "Upload a text file (TXT or DOC), or create your questionnaire from scratch"
                }}
              </p>
            </div>
            <p
              class="white text-active"
              :class="{ active: option === 'upload' }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.auto_survey
                  : "AutoSurvey artificial intelligence will program your questionnaire automatically after reading a TXT or DOC file. In less than 1 minute, your questionnaire will be ready"
              }}
            </p>
          </div>
          <div
            class="box"
            @click="option = 'logic'"
            :class="{ active: option === 'logic' }"
          >
            <div class="d-flex item">
              <img src="@/assets/logic-blue.svg" alt="" class="blue" />
              <img
                src="@/assets/logic-white.svg"
                alt=""
                class="white"
                :class="{ active: option === 'logic' }"
              />
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.modify
                    : "Modify the logic, flow and add complex questions"
                }}
              </p>
            </div>
            <p
              class="white text-active"
              :class="{ active: option === 'logic' }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.you_can_modify
                  : "You can modify the structure of a questionnaire and create complex questions to collect precise data"
              }}
            </p>
          </div>
          <div
            class="box"
            @click="option = 'sample'"
            :class="{ active: option === 'sample' }"
          >
            <div class="d-flex item">
              <img src="@/assets/sample-blue.svg" alt="" class="blue" />
              <img
                src="@/assets/sample-white.svg"
                alt=""
                class="white"
                :class="{ active: option === 'sample' }"
              />
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.field_you_survey
                    : "Field you survey and monitor the results of the projects online in real-time"
                }}
              </p>
            </div>
            <p
              class="white text-active"
              :class="{ active: option === 'sample' }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.use_our_respondent_panel
                  : "Use our respondent panel to collect the answers. You can also choose another panel or database to field your survey"
              }}
            </p>
          </div>
          <div
            class="box"
            @click="option = 'report'"
            :class="{ active: option === 'report' }"
          >
            <div class="d-flex item">
              <img
                src="@/assets/report-landpage-blue.svg"
                alt=""
                class="blue"
              />
              <img
                src="@/assets/report-landpage-white.svg"
                alt=""
                class="white"
                :class="{ active: option === 'report' }"
              />
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.visualize
                    : "Visualize the results using charts or cross tables"
                }}
              </p>
            </div>
            <p
              class="white text-active"
              :class="{ active: option === 'report' }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.visualize_online
                  : "Visualize the online data in real-time and export them in cross tables in Excel or PPT charts according to your need"
              }}
            </p>
          </div>
        </div>
        <div
          class="col-xl-6 d-flex align-items-center img-how-work"
          @click="option = 'clickOut'"
          :class="{ active: option === 'clickOut' }"
        >
          <img src="@/assets/img-how-work.svg" alt="how it work image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      option: ""
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.bg {
  background: #f7fafb;
}
.how-works {
  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
  }
  p {
    font-weight: 400;
    font-size: 0.875rem;
    color: #707070;
    text-align: center;
    width: 486px;
  }
}
.text {
  display: flex;
  justify-content: center;
}

.second-row {
  margin-top: 70px;
}
.box {
  width: 478px;
  min-height: 88px;
  background: #fff;
  box-shadow: 0px 4px 13px rgba(45, 97, 135, 0.15);
  padding: 25px 15px 15px 15px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 1s ease;
  img {
    width: 35px;
    height: 35px;
  }
  p {
    font-weight: 700;
    font-size: 0.875rem;
    color: #666;
    margin-left: 20px;
    min-height: 42px;
  }
  .text-active {
    font-weight: 400;
    font-size: 0.875rem;
    color: #fff;
    margin: 0 0 0 57px;
    min-height: 90px;
  }
  .white {
    display: none;
  }
  &.active {
    background: #4f87da;
    p {
      color: white;
    }
    .blue {
      display: none;
    }
    .white {
      display: block;
    }
  }
}
@media (max-width: 1199.98px) {
  .img-how-work {
    display: none !important;
  }
  .box {
    margin-left: calc(50% - 239px);
  }
}
@media (max-width: 575.98px) {
  .second-row {
    margin-top: 40px;
  }
  .box {
    max-width: 300px;
    margin-left: calc(50% - 150px);
    img {
      margin-left: calc(50% - 17.5px);
    }
    p {
      text-align: center;
      min-height: 57px;
      margin-top: 10px;
      margin-left: 0;
    }
    .text-active {
      margin-left: 0;
      min-height: 100px;
    }
    .item {
      display: block !important;
    }
  }
}
</style>
