<template>
  <section class="py-5">
    <img class="ball-bg" src="@/assets/ball-4.png" alt="" />

    <div class="container py-5 py-md-0">
      <div class="row align-items-center justify-content-between">
        <div class="col-md-6">
          <h1 class="title">Términos y Condiciones</h1>

          <p class="paragraphy">
            Términos y Condiciones de Offerwise para contratación de proyectos
            de Investigación de Mercados
          </p>
        </div>

        <div class="col-md-6 d-md-flex d-none justify-content-end">
          <img class="img-intro" src="@/assets/sales-terms-intro.png" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
section {
  position: relative;
  background: linear-gradient(
    273.32deg,
    #7d9bad 0.82%,
    #ccdde5 44.3%,
    #ffffff 88.78%
  );
}

.ball-bg {
  position: absolute;
  top: 0;
  left: 0;

  height: 85%;
}

.title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 20px;

  @media (max-width: 991.98px) {
    font-size: 24px;
  }

  @media (max-width: 767.98px) {
    text-align: center;
  }
}

.paragraphy {
  color: #707070;
  font-size: 20px;
  margin-bottom: 0;

  max-width: 400px;
  width: 100%;

  @media (max-width: 767.98px) {
    text-align: center;
    max-width: unset;
  }
}

.img-intro {
  position: relative;
  height: 520px;
  top: 91px;

  @media (max-width: 991.98px) {
    height: 400px;
    top: 82px;
  }

  @media (max-width: 767.98px) {
    display: none;
  }
}
</style>
