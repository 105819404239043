<template>
  <section class="py-5 p-relative">
    <div class="container h100">
      <div class="row h100 align-items-center mt-5">
        <div class="col-lg-6">
          <h1 class="title">
            {{
              $store.getters.trans
                ? $store.getters.trans.lgpd.title
                : "LGPD - Entenda como essa Lei se aplica a Pesquisa de Mercado"
            }}
          </h1>
        </div>
        <div class="col-lg-6 d-flex justify-content-end banner">
          <img
            src="@/assets/banner-img.svg"
            alt="computer"
            class="img-fluid img-banner"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  height: 550px;
  background: linear-gradient(182.79deg, #f7fafb 34.8%, #ccdde2 125.74%);
}
.title {
  font-weight: 400;
  font-size: 2.5rem;
  color: #000000;
}

.img-banner {
  position: relative;
  right: 0;
  bottom: -55px;
  width: 480px;
}

@media (max-width: 991.98px) {
  .banner {
    display: none !important;
  }
  .title {
    text-align: center;
  }
}
</style>
