<template>
  <div v-if="resources.length">
    <section
      class="intro intro-2"
      :style="{ backgroundImage: `url(${resources[0].image_file})` }"
    >
      <div class="img-blog"></div>
      <div class="container h100">
        <div class="row align-items-center h100">
          <h1 class="title">Data Processing</h1>
        </div>
      </div>
      <button class="arrow-btn down">
        <a href="#intro" v-smooth-scroll="{ duration: 1000 }">
          <img src="@/assets/arrow.svg" alt="arrow"
        /></a>
      </button>
      <div class="intro-style">
        <img src="@/assets/intro-style.svg" alt="style" />
      </div>
    </section>
  </div>
</template>

<script>
import { api } from "@/services.js";
export default {
  data() {
    return { resources: [] };
  },

  methods: {
    getNews() {
      api.get("resources").then(r => {
        this.resources = r.data;
      });
    },
    init() {
      this.getNews();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.intro {
  height: calc(100vh - 10px);
  max-height: 500px;
  padding-top: 125px;
  position: relative;
  //background: url("../../assets/img-blog-2.png") no-repeat center;
  background-size: cover;
}

.img-blog {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  background: linear-gradient(
    103.04deg,
    #ffffff 22.95%,
    rgba(255, 255, 255, 0.58) 77.33%
  );
}
.intro-style {
  position: absolute;
  bottom: -3px;
  width: 100%;
  img {
    width: 100%;
  }
}

.arrow-btn {
  position: absolute;
  bottom: 47px;
  width: 60px;
  right: calc(50% - 30px);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.title {
  font-weight: 500;
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  margin-left: 110px;
  position: relative;
  z-index: 5;
  margin-bottom: 100px;
}

.img-banner {
  position: relative;
  width: 2000px;
  height: 450px;
  top: -130px;
}

@media (max-width: 1199.98px) {
  .row {
    justify-content: center;
  }
  .title {
    font-weight: 500;
    color: #000000;
    font-size: 30px;
    font-weight: bold;
    margin-left: 0;
    position: relative;
    z-index: 5;
    margin-bottom: 100px;
  }
}
</style>
