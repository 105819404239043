<template>
  <section class="intro">
    <div class="container h100">
      <div class="row align-items-center h100">
        <transition mode="out-in">
          <owVision v-if="option === 4" />
          <owVoices v-if="option === 3" />
          <owFocus v-if="option === 2" />
          <owSurvey v-if="option === 1" />
        </transition>
      </div>
    </div>
    <div class="retangle d-flex justify-content-center align-items-center">
      <div class="items-vertical d-flex justify-content-between">
        <div
          class="ball"
          @click="changeTab(1)"
          :class="{ active: option === 1 }"
        ></div>
        <div
          class="ball"
          @click="changeTab(2)"
          :class="{ active: option === 2 }"
        ></div>
        <div
          class="ball"
          @click="changeTab(3)"
          :class="{ active: option === 3 }"
        ></div>
        <div
          class="ball"
          @click="changeTab(4)"
          :class="{ active: option === 4 }"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import owVoices from "@/components/product/ow-voices.vue";
import owFocus from "@/components/product/ow-focus.vue";
import owVision from "@/components/product/ow-vision.vue";
import owSurvey from "@/components/product/ow-survey.vue";

export default {
  components: {
    owVoices,
    owFocus,
    owVision,
    owSurvey
  },
  data() {
    return {
      loader: false,
      interval: null,
      temp: 1,
      option: 1
    };
  },
  computed: {},
  methods: {
    move() {
      this.interval = setInterval(this.changeOption, 5000);
    },
    changeOption() {
      this.changeTab(this.temp);
      if (this.temp === 4) this.temp = 1;
      else this.temp++;
    },
    changeTab(option) {
      this.option = option;
    }
  },
  created() {
    this.move();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
.intro {
  height: 420px;
  background: linear-gradient(182.79deg, #f7fafb 34.8%, #ccdde2 125.74%);
  padding-top: 125px;
  position: relative;
  overflow: hidden;
  .container {
    position: relative;
  }
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(221, 221, 221);
  &.active {
    background: rgb(43, 43, 43);
  }
}

.retangle {
  width: 100%;
  height: 40px;
  position: absolute;
  z-index: 99;
  background: white;
  bottom: 0;
}

.items-vertical {
  position: absolute;
  background: none;
  display: flex;
  z-index: 100;
  width: 70px;
}

.intro-style {
  position: absolute;
  bottom: -4px;
  width: 100%;
  img {
    width: 100%;
  }
}

.arrow-btn {
  position: absolute;
  bottom: 53px;
  right: calc(50% - 28px);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.btn-mobile {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #2d6187;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  width: 35px;
  height: 35px;
  padding: 1px 10px 1px 10px;
  display: none;
}

.btn-solution {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.7rem;
  background: #2d6187;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  padding: 5px 23px 5px 23px;
}

.btn-solution:hover {
  background: #234c69;
}

.form-control {
  font-size: 0.8rem;
}

@media (max-width: 1199.98px) {
  .intro-img {
    display: none;
  }
  .bg-ball {
    display: none;
  }
  .intro {
    height: calc(540px);
  }
  .intro {
    height: 820px;
    background: linear-gradient(182.79deg, #f7fafb 34.8%, #ccdde2 125.74%);
    padding-top: 125px;
    position: relative;
    overflow: hidden;
    .container {
      position: relative;
    }
  }
}

@media (max-width: 991.98px) {
  .input {
    width: 60%;
  }
}

@media (max-width: 767.98px) {
  .btn-solution {
    display: none;
  }
  .btn-mobile {
    display: inline;
  }
  .title {
    font-size: 36px;
  }
}

@media (max-width: 575.98px) {
  .title {
    font-size: 36px;
    margin-bottom: -30px;
  }
  .title-align {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: -webkit-center;
  }
  .input {
    width: 90%;
  }
}

@media (min-width: 1400px) {
  .arrow-btn {
    bottom: 52px;
  }
}
@media (min-width: 1800px) {
  .arrow-btn {
    bottom: 69px;
  }
}

@media (min-width: 1400px) {
  .intro {
    height: 470px;
    background: linear-gradient(182.79deg, #f7fafb 34.8%, #ccdde2 125.74%);
    padding-top: 125px;
    position: relative;
    overflow: hidden;
    .container {
      position: relative;
    }
  }
}
</style>
