<template>
  <section class="py-5"></section>
</template>
<script>
export default {
  components: {}
};
</script>

<style lang="scss" scoped></style>
