<template>
  <section class="pt-5 pb-4">
    <div class="container">
      <div class="row padding-firstRow justify-content-center">
        <h2 class="title mb-4">
          {{
            $store.getters.trans
              ? $store.getters.trans.lgpd.what_we_are_doing.title
              : ""
          }}
        </h2>
        <p class="text mt-1">
          {{
            $store.getters.trans
              ? $store.getters.trans.lgpd.what_we_are_doing.sub_title
              : ""
          }}
        </p>
      </div>
      <div class="row justify-content-center mt-5 carousel-lgpd">
        <carousel
          :perPage="1"
          :navigationEnabled="false"
          :paginationEnabled="true"
          :speed="1000"
        >
          <slide>
            <div class="box">
              <div class="d-flex justify-content-center">
                <img
                  src="@/assets/computer.svg"
                  alt="computer"
                  class="img-carousel"
                />
              </div>
              <h6 class="title-carousel mt-5">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .panel_registry.title
                    : ""
                }}
              </h6>
              <p class="text-carousel mt-3">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .panel_registry.text
                    : ""
                }}
              </p>
            </div>
          </slide>
          <slide>
            <div class="box">
              <div class="d-flex justify-content-center">
                <img
                  src="@/assets/tablet.svg"
                  alt="computer"
                  class="img-carousel"
                />
              </div>
              <h6 class="title-carousel mt-5">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .survey_participation.title
                    : ""
                }}
              </h6>
              <p class="text-carousel mt-3">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .survey_participation.text
                    : ""
                }}
              </p>
            </div>
          </slide>
          <slide>
            <div class="box">
              <div class="d-flex justify-content-center">
                <img
                  src="@/assets/video.svg"
                  alt="computer"
                  class="img-carousel"
                />
              </div>
              <h6 class="title-carousel mt-5">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .image_video_use.title
                    : ""
                }}
              </h6>
              <p class="text-carousel mt-3">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .image_video_use.text
                    : ""
                }}
              </p>
            </div>
          </slide>
          <slide>
            <div class="box">
              <div class="d-flex justify-content-center">
                <img
                  src="@/assets/cracha.svg"
                  alt="computer"
                  class="img-carousel"
                />
              </div>
              <h6 class="title-carousel mt-5">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .pseudo_data.title
                    : ""
                }}
              </h6>
              <p class="text-carousel mt-3">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .pseudo_data.text
                    : ""
                }}
              </p>
            </div>
          </slide>
          <slide>
            <div class="box">
              <div class="d-flex justify-content-center">
                <img
                  src="@/assets/cloud.svg"
                  alt="computer"
                  class="img-carousel"
                />
              </div>
              <h6 class="title-carousel mt-5">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .data_storage.title
                    : ""
                }}
              </h6>
              <p class="text-carousel mt-3">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.what_we_are_doing.carrousel
                        .data_storage.text
                    : ""
                }}
              </p>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  }
};
</script>

<style lang="scss" scoped>
.padding-firstRow {
  padding: 0 15px;
}

.title {
  font-weight: 600;
  font-size: 1.75rem;
  text-align: center;
  color: #4f8cb0;
  max-width: 700px;
  text-transform: uppercase;
}
.text {
  max-width: 900px;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: #666;
}

.box {
  width: 500px;
  height: 343px;
  padding: 48px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4), 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  .img-carousel {
    height: 100px;
  }
  .title-carousel {
    font-weight: 600;
    font-size: 1rem;
    color: #4f8cb0;
    text-align: center;
  }

  .text-carousel {
    font-weight: 400;
    font-size: 0.875rem;
    color: #32363b;
    text-align: center;
  }
}

.bg-gray {
  background: #f7f8f9;
}
.title-process {
  font-weight: 600;
  font-size: 1.75rem;
  color: #4f8cb0;
  max-width: 700px;
  text-transform: uppercase;
  margin: 0;
}

@media (max-width: 991.98px) {
  .img-ow {
    display: none;
  }
  .title-process {
    padding: 20px 0;
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .box {
    width: 280px;
    height: 360px;
    padding: 25px;
  }
}
</style>
