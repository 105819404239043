import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import About from "../views/About.vue";
import Research from "../views/Research.vue";
import Careers from "../views/Careers.vue";
import Contact from "../views/Contact.vue";
import Panelbook from "../views/Panelbook.vue";
import Resources from "../views/Resources.vue";
import OpenMatter from "../views/OpenMatter.vue";
import quickServeyDetails from "../views/quick-servey-details.vue";
import BrandHealthDetails from "../views/BrandHealthDetails.vue";
import DetailsPreTest from "../views/DetailsPreTest.vue";
import DetailsPostTest from "../views/DetailsPostTest.vue";
import LandPage from "../views/LandPage.vue";
import owVision from "../views/owVision.vue";
import owFocus from "../views/owFocus.vue";
import owSurvey from "../views/owSurvey.vue";
import owVoices from "../views/owVoices.vue";
import dpl from "../views/dpl.vue";
import RequestBid from "../views/RequestBid.vue";
import SalesTerms from "../views/SalesTerms.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/product",
    name: "product",
    component: Product,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/about",
    name: "about",
    component: About,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/research",
    name: "research",
    component: Research,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/jobs",
    name: "careers",
    component: Careers,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/panelbook",
    name: "panelbook",
    component: Panelbook,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/resources",
    name: "resources",
    component: Resources,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/openmatter",
    name: "openmatter",
    component: OpenMatter,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/details-quick-servey",
    name: "details-quick-servey",
    component: quickServeyDetails,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/details-brand-health",
    name: "details-brand-health",
    component: BrandHealthDetails,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/details-pre-test",
    name: "details-pre-test",
    component: DetailsPreTest,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/details-post-test",
    name: "details-post-test",
    component: DetailsPostTest,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/survey",
    name: "survey",
    component: LandPage,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/owvision",
    name: "owvision",
    component: owVision,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/owfocus",
    name: "owfocus",
    component: owFocus,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/owvoices",
    name: "owvoices",
    component: owVoices,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/owsurvey",
    name: "owsurvey",
    component: owSurvey,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/dataProtectionLaws",
    name: "dpl",
    component: dpl,
    meta: { title: "Offerwise: Latin America Market Research" }
  },
  {
    path: "/requestabid",
    name: "requestabid",
    component: RequestBid,
    meta: { title: "Offerwise: Request a Bid" }
  },
  {
    path: "/sales-terms",
    name: "salesterms",
    component: SalesTerms,
    meta: { title: "Offerwise: Latin America Market Research" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (from.query.lang && !to.query.lang) {
    next({
      name: to.name,
      query: Object.assign(to.query, { lang: from.query.lang })
    });
  } else {
    next();
  }
});

export default router;
