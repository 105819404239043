<template>
  <section>
    <div class="container h100">
      <div class="row align-items-center h100">
        <div class="col-xl-6 height-title">
          <img
            src="@/assets/ow-vision-logo.svg"
            width="135px"
            class="logo-title mb-4"
            alt="logo survey"
          />
          <div class="title-align pb-4">
            <h1 class="title">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products
                      .assess_consumer
                  : "Assess consumer interest, engagement, and attention using Eye Tracking and Facial Coding"
              }}
            </h1>
          </div>
          <div class="button_request">
            <router-link :to="{ name: 'owvision' }" class="btn btn-survey">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products.request_a_demo
                  : "Request a Demo"
              }}
            </router-link>
          </div>
        </div>
        <div class="col-xl-6 h100 intro-img">
          <img
            class="img-banner-ow-vision"
            src="@/assets/laptop-ow-vision.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}

.title {
  font-weight: 500;
  color: #000000;
  width: 500px;
  font-size: 1.8rem;
  font-weight: bold;
}

.btn-survey {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4b83d5;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  transition: all 0.3s ease;
  margin: 5px;
}

.btn-survey:hover {
  text-decoration: none;
  background: #246edd;
}

.button_request {
  margin-left: 100px;
}

.height-title {
  margin-bottom: 120px;
}

.img-banner-ow-vision {
  height: 200px;
  position: relative;
  top: -40px;
  left: 100px;
}

.title-align {
  margin-left: 100px;
}

.logo-title {
  margin-left: 100px;
}

.bg-ball {
  position: absolute;
  z-index: 0;
  top: -60px;
  right: -80px;
  width: 704px;
  height: 704px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
}

.items-vertical {
  position: absolute;
  right: 0;
  p {
    font-weight: 700;
    font-size: 0.5rem;
    color: #c4c9cb;
    margin-bottom: 90px;
    transform: rotate(-90deg);
    cursor: pointer;
    &.active {
      color: #707070;
    }
  }
}

.intro-style {
  position: absolute;
  bottom: -4px;
  width: 100%;
  img {
    width: 100%;
  }
}

.arrow-btn {
  position: absolute;
  bottom: 53px;
  right: calc(50% - 28px);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.btn-mobile {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #2d6187;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  width: 35px;
  height: 35px;
  padding: 1px 10px 1px 10px;
  display: none;
}

.btn-solution {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.7rem;
  background: #2d6187;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  padding: 5px 23px 5px 23px;
}

.btn-solution:hover {
  background: #234c69;
}

.form-control {
  font-size: 0.8rem;
}

@media (max-width: 1199.98px) {
  .title-align {
    margin-left: 0px;
  }
  .button_request {
    text-align: center;
    margin-left: 0;
  }
  .intro-img {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .img-banner-ow-vision {
    height: 250px;
    position: relative;
    top: -40px;
    left: 0px;
  }
  .title-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-align {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 85px;
  }
  .bg-ball {
    display: none;
  }
  .title {
    width: 960px;
    font-size: 2.5rem;
    text-align: center;
  }
  .logo-title {
    display: none;
  }
  .input {
    width: 50%;
  }
  .intro {
    height: calc(100vh - 20px);
  }
}

@media (max-width: 991.98px) {
  .input {
    width: 60%;
  }
}

@media (max-width: 767.98px) {
  .btn-solution {
    display: none;
  }
  .btn-mobile {
    display: inline;
  }
  .title {
    font-size: 36px;
  }
  .img-banner-ow-vision {
    height: 250px;
    position: relative;
    top: -70px;
  }
}

@media (max-width: 575.98px) {
  .title {
    width: 100%;
    font-size: 36px;
    margin-bottom: -30px;
  }
  .title-align {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: -webkit-center;
  }
  .height-title {
    padding-bottom: 0px;
  }
  .input {
    width: 90%;
  }
  .height-title {
    padding-bottom: 0px;
    position: relative;
    max-width: 600px;
  }
  .button_request {
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
}

@media (max-width: 499px) {
  .height-title {
    padding-bottom: 0px;
    position: relative;
    max-width: 380px;
  }
  .img-banner-ow-vision {
    height: 250px;
    position: relative;
    top: -80px;
  }
}

@media (max-width: 414px) {
  .height-title {
    padding-bottom: 0px;
    position: relative;
    max-width: 400px;
  }
  .img-banner-ow-vision {
    height: 200px;
    position: relative;
    top: -90px;
    left: 0px;
  }
}

@media (max-width: 375px) {
  .height-title {
    padding-bottom: 0px;
    position: relative;
    max-width: 368px;
  }
  .img-banner-ow-vision {
    position: relative;
    top: -90px;
    left: -10px;
  }
}

@media (max-width: 360px) {
  .height-title {
    padding-bottom: 0px;
    position: relative;
    max-width: 350px;
  }
  .img-banner-ow-vision {
    height: 200px;
    position: relative;
    top: -90px;
    left: -20px;
  }
}

@media (max-width: 320px) {
  .height-title {
    padding-bottom: 0px;
    position: relative;
    max-width: 320px;
  }
  .img-banner-ow-vision {
    height: 200px;
    position: relative;
    top: -90px;
    left: -10px;
  }
}

@media (min-width: 1400px) {
  .arrow-btn {
    bottom: 52px;
  }
}
@media (min-width: 1800px) {
  .arrow-btn {
    bottom: 69px;
  }
}

@media (min-width: 1400px) {
  .img-banner-ow-vision {
    height: 250px;
    position: relative;
    top: -50px;
    left: 130px;
  }
}
</style>
