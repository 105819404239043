<template>
  <section class="py-5 mt-5 p-relative" id="third">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div class="col-lg-10 d-flex justify-content-around w-100 menu">
            <h3
              @click="changeTab('quantitatives')"
              :class="{ active: option === 'quantitatives' }"
              id="quantitatives"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .quantitative
                  : "Quantitative"
              }}
            </h3>
            <h3
              @click="changeTab('qualitatives')"
              :class="{ active: option === 'qualitatives' }"
              id="qualitatives"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.qualitative
                  : "Qualitative"
              }}
            </h3>
            <h3
              @click="changeTab('statistical')"
              :class="{ active: option === 'statistical' }"
              id="statistical"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.statistical_methods
                  : "Statistical Methods"
              }}
            </h3>
            <h3
              @click="changeTab('passive-data')"
              :class="{ active: option === 'passive-data' }"
              id="passive-data"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.passive_data
                  : "Passive Data"
              }}
            </h3>
            <h3
              class="ml-0"
              @click="changeTab('quick-surveys')"
              :class="{ active: option === 'quick-surveys' }"
              id="quick-surveys"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ready_to_go_surveys
                  : "Ready to go Surveys"
              }}
            </h3>
            <div class="select-product">
              <h2 class="ourProduct">Our Products</h2>
              <div>
                <b-form-select v-model="selected" class="mb-3">
                  <b-form-select-option :value="null"
                    >Choose...</b-form-select-option
                  >
                  <b-form-select-option value="quick-surveys">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ready_to_go_surveys
                        : "Ready to go Surveys"
                    }}
                  </b-form-select-option>
                  <b-form-select-option value="quantitatives">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions.quantitative
                            .quantitative
                        : "Quantitative"
                    }}
                  </b-form-select-option>
                  <b-form-select-option value="qualitatives">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.qualitative
                        : "Qualitative"
                    }}
                  </b-form-select-option>
                  <b-form-select-option value="statistical">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions
                            .statistical_methods
                        : "Statistical Methods"
                    }}
                  </b-form-select-option>
                  <b-form-select-option value="passive-data">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.passive_data
                        : "Passive Data"
                    }}
                  </b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex justify-content-center">
        <div class="col-10 line"></div>
      </div>
    </div>
    <transition mode="out-in">
      <quickSurveys v-if="option === 'quick-surveys'" />
      <quantitative v-if="option === 'quantitatives'" />
      <qualitative v-if="option === 'qualitatives'" />
      <statisticalModels v-if="option === 'statistical'" />
      <passiveData v-if="option === 'passive-data'" />
    </transition>
    <div class="container">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center linkTalk">
          <router-link :to="{ name: 'contact' }">
            {{
              $store.getters.trans
                ? $store.getters.trans.research_solutions.talk_to_an_expert
                : "Talk to an expert"
            }}
          </router-link>
        </div>
      </div>
    </div>
    <div id="bgFourth">
      <img src="@/assets/bgFourth.svg" alt="" />
    </div>
  </section>
</template>

<script>
import quickSurveys from "@/components/research/quickSurveys.vue";
import quantitative from "@/components/research/quantitative.vue";
import qualitative from "@/components/research/qualitative.vue";
import statisticalModels from "@/components/research/statisticalModels.vue";
import passiveData from "@/components/research/passiveData.vue";
export default {
  components: {
    quickSurveys,
    quantitative,
    qualitative,
    statisticalModels,
    passiveData
  },
  data() {
    return {
      selected: null
    };
  },
  computed: {
    option() {
      return this.$route.query.option;
    }
  },
  watch: {
    selected() {
      this.changeTab(this.selected);
    }
  },
  methods: {
    changeTab(option) {
      this.$router.push({ query: { option: option } });
    }
  },
  mounted() {
    if (this.$route.query.scroll) {
      const element = document.getElementById("third");
      element.scrollIntoView();
      window.scrollTo({ top: 650 });
      //this.$router.push({ name: "research", query: { option: this.option } });
    }
  },
  updated() {
    if (this.$route.query.scroll) {
      // const element = document.getElementById("third");
      //element.scrollIntoView();
      window.scrollTo({ top: 650, behavior: "smooth" });
      //this.$router.push({ name: "research", query: { option: this.option } });
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  h3 {
    text-align: center;
    margin-top: -20px;
    font-size: 0.9rem;
    color: #707070;
    cursor: pointer;
    padding: 4px 4px 8px 4px;
  }
  #quick-surveys {
    &.active {
      color: #7d9bad;
      border-bottom: 3px solid #7d9bad;
      z-index: 1;
    }
  }
  #quantitatives {
    &.active {
      color: #4f87da;
      border-bottom: 3px solid #4f87da;
      z-index: 1;
    }
  }
  #qualitatives {
    &.active {
      color: #e77c85;
      border-bottom: 3px solid #e77c85;
      z-index: 1;
    }
  }
  #statistical {
    &.active {
      color: #285a83;
      border-bottom: 3px solid #285a83;
      z-index: 1;
    }
  }
  #passive-data {
    &.active {
      color: #ffa020;
      border-bottom: 3px solid #fa9b5f;
      z-index: 1;
    }
  }
}

.line {
  border-bottom: 1px solid #d1d3d4;
  position: relative;
  top: -10px;
}

.linkTalk {
  a {
    text-transform: uppercase;
    padding: 7px 40px;
    color: #fff;
    background: #3a70c0;
    box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
    border-radius: 100px;
    font-size: 0.8rem;
  }
  a:hover {
    text-decoration: none;
    background: #3166b6;
  }
}
#bgFourth {
  img {
    position: absolute;
    bottom: 0px;
    right: -30px;
    z-index: -1;
  }
}

.dropdown-product {
  width: 260px;
  margin: 50px;
  background: #7d9bad;
  padding: 10px 20px;
  border-radius: 10px;
  > a {
    text-transform: uppercase;
    font-size: 1.3rem;
    color: #fff;
    cursor: pointer;
  }
  &:hover,
  &:focus {
    color: #fff;
    background: #678aa0;
    border-radius: 10px 10px 0 0;
    .drop-product-item {
      display: block;
    }
  }
}
.drop-product-item {
  margin: 10px 0 0 -20px;
  border-radius: 0 0 10px 10px;
  width: 260px;
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  background: #c6c6c6;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  a {
    display: block;
    color: #495057;
    padding: 2px 10px;
    &:hover,
    &.active {
      color: #495057;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  &.active {
    display: block;
  }
  p {
    text-align: center;
    font-size: 1.3rem;
    padding: 5px;
  }
  #quick-surveys {
    padding-top: 20px;
    &:hover {
      background: #7d9bad;
    }
  }
  #quantitatives:hover {
    background: #4b83d5;
  }
  #qualitatives:hover {
    background: #e77c85;
  }
  #statistical:hover {
    background: #285a83;
  }
  #passive-data:hover {
    background: #285a83;
  }
}
.ourProduct {
  font-weight: 700;
  font-size: 1.325rem;
  color: #000;
  text-align: center;
  margin-bottom: 40px;
}
.select-product {
  width: 100% !important;
  display: none;
}
.custom-select {
  padding: 0.375rem 1.75rem 0.375rem 1.1rem;
  font-size: 1rem;
  color: #495057;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/18px 18px;
}

.custom-select:focus {
  border-color: #ced4da;
  outline: none;
  box-shadow: none;
}
@media (max-width: 1199.98px) {
  #bgFourth {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .menu h3 {
    display: none;
  }
  .line {
    display: none;
  }
  .select-product {
    display: block;
  }
  .ourProduct {
    margin-top: -70px;
  }
}
</style>
