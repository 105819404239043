import { render, staticRenderFns } from "./brand-health-details.vue?vue&type=template&id=ecd2e1aa&scoped=true"
import script from "./brand-health-details.vue?vue&type=script&lang=js"
export * from "./brand-health-details.vue?vue&type=script&lang=js"
import style0 from "./brand-health-details.vue?vue&type=style&index=0&id=ecd2e1aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecd2e1aa",
  null
  
)

export default component.exports