<template>
  <div class="quick-servey-details">
    <intro />
  </div>
</template>

<script>
import intro from "@/components/requestBid/intro.vue";

export default {
  name: "RequestBid",
  components: {
    intro
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
