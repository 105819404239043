<template>
  <div class="container h100">
    <div class="row py-5 align-items-center h100">
      <div class="col-xl-6 height-title">
        <img
          src="@/assets/survey.svg"
          class="logo-title mb-3"
          alt="logo survey"
        />
        <div class="title-align pb-4">
          <h1 class="title">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products
                    .simple_automated_survey
                : "Simple automated surveys"
            }}
          </h1>
        </div>
        <div class="button_request">
          <router-link :to="{ name: 'owsurvey' }" class="btn btn-survey">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.request_a_demo
                : "Request a Demo"
            }}
          </router-link>
        </div>
      </div>
      <div class="col-xl-6 h100 banner">
        <img class="img-banner" src="@/assets/tablete-c-sombra.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.height-title {
  margin-bottom: 75px;
  top: -30px;
}

.title {
  font-weight: 500;
  color: #000000;
  width: 500px;
  font-size: 1.8rem;
  font-weight: bold;
  max-width: 350px;
}

.button_request {
  margin-left: 100px;
}

.btn-survey {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4b83d5;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  transition: all 0.3s ease;
  margin: 5px;
}

.btn-survey:hover {
  text-decoration: none;
  background: #246edd;
}

.title-align {
  margin-left: 100px;
}

.logo-title {
  margin-left: 100px;
}

.img-banner {
  height: 370px;
  position: relative;
  transform: rotate(-7deg);
  top: -90px;
  left: 0px;
}

.bg-ball {
  position: absolute;
  z-index: 0;
  top: -40px;
  right: -67px;
  width: 630px;
  height: 630px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.btn-mobile {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #2d6187;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  width: 35px;
  height: 35px;
  padding: 1px 10px 1px 10px;
  display: none;
}

.btn-solution {
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.7rem;
  background: #2d6187;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  padding: 5px 23px 5px 23px;
}

.btn-solution:hover {
  background: #234c69;
}

.form-control {
  font-size: 0.8rem;
}

@media (max-width: 1199.98px) {
  .title-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button_request {
    text-align: center;
    margin-left: 0;
  }
  .title {
    width: 960px;
    font-size: 2.5rem;
  }
  .height-title {
    padding-bottom: 50px;
  }
  .bg-ball {
    display: none;
  }
  .title {
    text-align: center;
  }
  .logo-title {
    display: none;
  }
  .intro {
    height: calc(100vh - 20px);
  }

  .title-align {
    margin-left: 0px;
  }
  .banner {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .img-banner {
    height: 370px;
    position: relative;
    transform: rotate(-0deg);
    top: -90px;
    left: 20px;
  }
}

@media (max-width: 991.98px) {
  .input {
    width: 60%;
  }
  .banner {
    align-items: center;
    text-align: center;
  }
  .img-banner {
    height: 370px;
    position: relative;
    transform: rotate(-0deg);
    top: -90px;
  }
}

@media (max-width: 767.98px) {
  .btn-solution {
    display: none;
  }
  .btn-mobile {
    display: inline;
  }
  .title {
    font-size: 36px;
  }
  .img-banner {
    height: 370px;
    position: relative;
    transform: rotate(-0deg);
    top: -90px;
  }
  .banner {
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .title {
    width: 100%;
    font-size: 36px;
    margin-bottom: -30px;
    margin-top: 10px;
  }
  .button_request {
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
  .img-banner {
    height: 370px;
    position: relative;
    transform: rotate(-0deg);
    top: -90px;
  }
  .banner {
    align-items: center;
    text-align: center;
  }
  .title-align {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: -webkit-center;
  }
  .input {
    width: 90%;
  }
}

@media (max-width: 500.98px) {
  .img-banner {
    height: 300px;
    position: relative;
    transform: rotate(-0deg);
    top: -120px;
    left: 0px;
  }
  .banner {
    align-items: center;
    text-align: center;
  }
  .button_request {
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
}

@media (max-width: 414px) {
  .img-banner {
    height: 340px;
    position: relative;
  }
}

@media (max-width: 411px) {
  .img-banner {
    height: 335px;
    position: relative;
  }
}

@media (max-width: 375px) {
  .img-banner {
    height: 300px;
    position: relative;
  }
}

@media (max-width: 320px) {
  .img-banner {
    height: 250px;
    position: relative;
  }
}

@media (min-width: 1400px) {
  .arrow-btn {
    bottom: 52px;
  }
}
@media (min-width: 1800px) {
  .arrow-btn {
    bottom: 69px;
  }
}

@media (min-width: 1400px) {
  .img-banner {
    height: 370px;
    position: relative;
    transform: rotate(-7deg);
    top: -90px;
    left: 0px;
  }
}
</style>
