<template>
  <div class="research">
    <intro />
    <secondSection />
    <thirdSection />
    <!--<fourthSection />-->
  </div>
</template>

<script>
import intro from "@/components/research/intro.vue";
import secondSection from "@/components/research/second-section.vue";
import thirdSection from "@/components/research/third-section.vue";
/*import fourthSection from "@/components/research/fourth-section.vue";*/

export default {
  name: "research",
  components: {
    intro,
    secondSection,
    thirdSection
    /* fourthSection*/
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
