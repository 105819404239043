<template>
  <section class="py-5">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/quick-computer.svg" alt="" />
            </div>
            <p class="type mb-4 mt-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ready_to_go_surveys
                  : "Ready to go Surveys"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.a_b_tests
                  : "A/B Tests"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .conduct_comparative_tests
                  : "Conduct comparative tests between advertisements and know which one is more effective for your target."
              }}.
            </p>
            <div class="d-flex btnSurveys">
              <router-link
                :to="{ name: 'details-quick-servey' }"
                class="btnDetails"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.details
                    : "Details"
                }}
              </router-link>
              <router-link :to="{ name: 'contact' }" class="btnGet">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.get_started
                    : "Get Started"
                }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box img-surveys">
              <img src="@/assets/health.png" alt="" />
            </div>
            <p class="type mb-4 mt-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ready_to_go_surveys
                  : "Ready to go Surveys"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.brand_health
                  : "Brand Health"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .identify_positionings
                  : "Identify brand positionings that should have been enforced or modified and optimize your communication efforts."
              }}.
            </p>
            <div class="d-flex btnSurveys">
              <router-link
                :to="{ name: 'details-brand-health' }"
                class="btnDetails"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.details
                    : "Details"
                }}
              </router-link>
              <router-link :to="{ name: 'contact' }" class="btnGet">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.get_started
                    : "Get Started"
                }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box img-surveys">
              <img src="@/assets/pre-test.png" alt="" />
            </div>
            <p class="type mb-4 mt-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ready_to_go_surveys
                  : "Ready to go Surveys"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .pre_test
                  : "Pre Test"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .test_efficiency
                  : "Test the efficiency of an advertising campaign before launching it and identify what can be improved."
              }}.
            </p>
            <div class="d-flex btnSurveys">
              <router-link
                :to="{ name: 'details-pre-test' }"
                class="btnDetails"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.details
                    : "Details"
                }}
              </router-link>
              <router-link :to="{ name: 'contact' }" class="btnGet">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.get_started
                    : "Get Started"
                }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box img-surveys">
              <img src="@/assets/pos-test.png" alt="" />
            </div>
            <p class="type mb-4 mt-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ready_to_go_surveys
                  : "Ready to go Surveys"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .post_test
                  : "Post Test"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .analyse_advertisement_performance
                  : "Analyze the performance of an advertisement after airing it to measure your target acceptance."
              }}.
            </p>
            <div class="d-flex btnSurveys">
              <router-link
                :to="{ name: 'details-post-test' }"
                class="btnDetails"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.details
                    : "Details"
                }}
              </router-link>
              <router-link :to="{ name: 'contact' }" class="btnGet">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.get_started
                    : "Get Started"
                }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  width: 288px;
  min-height: 370px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.12);
  padding: 40px 20px 25px 20px;
  margin-bottom: 40px;
  margin-left: calc(50% - 144px);
  .img-box {
    min-height: 73px;
  }

  .type {
    font-weight: 500;
    font-size: 0.625rem;
    color: #7d9bad;
    text-transform: uppercase;
  }
  h3 {
    font-weight: 700;
    font-size: 1.187rem;
    color: #3f4451;
  }
  .description {
    font-weight: 400;
    font-size: 0.75rem;
    color: #3f4451;
    min-height: 75px;
  }
  .btnSurveys {
    .btnDetails {
      text-decoration: none;
      height: 30px;
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.65rem;
      border-radius: 3px;
      margin-top: 20px;
      border: 1px solid #eb858c;
      color: #eb858c;
    }
    .btnDetails:hover {
      border-color: #eb858c;
      color: #fff;
      background: #eb858c;
    }
    .btnDetails:focus {
      border-color: #d8636b;
      background: #d8636b;
      color: #fff;
    }
    .btnGet {
      text-decoration: none;
      height: 30px;
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 0.65rem;
      border-radius: 3px;
      margin-top: 20px;
      margin-left: 30px;
      border-color: #eb858c;
      background: #eb858c;
      color: #fff;
    }
    .btnGet:hover {
      border-color: #d8636b;
      background: #d8636b;
      color: #fff;
    }
    .btnGet:focus {
      border-color: #d8636b;
      background: #d8636b;
      color: #fff;
    }
  }
}
</style>
