<template>
  <div class="careers">
    <intro />
    <secondSection />
    <thirdSection />
  </div>
</template>

<script>
import intro from "@/components/careers/intro.vue";
import secondSection from "@/components/careers/second-section.vue";
import thirdSection from "@/components/careers/third-section.vue";

export default {
  name: "careers",
  components: {
    intro,
    secondSection,
    thirdSection
  },
  data() {
    return {};
  },
  methods: {
    init() {
      window.scrollTo({ top: 0 });
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped></style>
