<template>
  <div class="Product">
    <intro />
    <secondSection />
    <thirdSection />
    <voicesSection />
    <fourthSection />
  </div>
</template>

<script>
import intro from "@/components/product/intro.vue";
import secondSection from "@/components/product/second-section.vue";
import thirdSection from "@/components/product/third-section.vue";
import fourthSection from "@/components/product/fourth-section.vue";
import voicesSection from "@/components/product/voices-section.vue";

export default {
  name: "Product",
  components: {
    intro,
    secondSection,
    thirdSection,
    voicesSection,
    fourthSection
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style></style>
