<template>
  <div class="Resources">
    <intro />
    <second-section />
    <third-section />
  </div>
</template>

<script>
import intro from "@/components/resources/intro.vue";
import secondSection from "@/components/resources/second-section.vue";
import thirdSection from "@/components/resources/third-section.vue";

export default {
  name: "resources",
  components: {
    intro,
    secondSection,
    thirdSection
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
