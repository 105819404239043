<template>
  <section class="pt-5" id="contact">
    <div class="container">
      <div class="row">
        <div
          class="col d-flex align-items-center justify-content-between items"
        >
          <p>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.no_license_fees
                : "No license fees"
            }}
          </p>
          <div class="ball"></div>
          <p>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.unlimited
                : "Unlimited users"
            }}
          </p>
          <div class="ball"></div>
          <p>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.tailored
                : "Tailored bundled services"
            }}
          </p>
          <div class="ball"></div>
          <p>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.pay
                : "Pay using your local currency"
            }}
          </p>
        </div>
      </div>
      <div class="row py-5 justify-content-center second-row">
        <div class="col-10 p-relative d-flex justify-content-center">
          <div class="box">
            <h2>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.buttons_and_inputs
                      .request_a_demo
                  : "Request a Demo"
              }}
            </h2>
            <form @submit="sendLP">
              <div class="content">
                <div class="col-lg-6">
                  <input
                    type="text"
                    id="name"
                    v-model="name"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.name
                        : 'name:'
                    "
                    required
                  />
                  <input
                    type="email"
                    id="email"
                    v-model="email"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.email
                        : 'E-mail:'
                    "
                    required
                  />
                  <input
                    v-if="$store.getters.lang === 'en'"
                    type="text"
                    v-mask="'+# (###) ###-####'"
                    id="phone"
                    v-model="tel"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.phone
                        : 'Phone:'
                    "
                  />
                  <input
                    v-if="$store.getters.lang === 'es'"
                    type="text"
                    v-mask="'+## ###-##-##-##'"
                    id="phone"
                    v-model="tel"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.phone
                        : 'Phone:'
                    "
                  />
                  <input
                    v-if="$store.getters.lang === 'pt'"
                    type="text"
                    v-mask="'+## (##) #####-####'"
                    id="phone"
                    v-model="tel"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.phone
                        : 'Phone:'
                    "
                  />
                  <input
                    type="text"
                    id="company"
                    v-model="company"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.company
                        : 'Company:'
                    "
                  />
                </div>
                <div class="col-lg-6">
                  <input
                    type="country"
                    id="country"
                    v-model="country"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.country
                        : 'Country:'
                    "
                  />
                  <textarea
                    id="message"
                    class="text-area"
                    v-model="msg"
                    :placeholder="
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.message
                        : 'Message:'
                    "
                  ></textarea>

                  <div class="d-flex justify-content-end captcha">
                    <vue-recaptcha
                      sitekey="6LftqroaAAAAALd8sznQebEoKMWzde_qSLIuJzd7"
                      :loadRecaptchaScript="true"
                      @verify="mxVerify"
                    ></vue-recaptcha>
                  </div>

                  <div class="d-flex justify-content-end align-btn">
                    <button class="btn btn-send" v-if="!loader">
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.menu_contact.send
                          : "Send"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="bg"></div>
  </section>
</template>

<script>
import { api } from "@/services.js";
import VueRecaptcha from "vue-recaptcha";
export default {
  components: { VueRecaptcha },
  data() {
    return {
      name: "",
      email: "",
      tel: "",
      country: "",
      company: "",
      msg: "",
      loader: false,
      recaptcha: null,
      demo: "Ow Survey"
    };
  },
  methods: {
    mxVerify(response) {
      this.recaptcha = response;
    },

    sendLP(e) {
      if (!this.loader && this.email !== "" && this.recaptcha !== null) {
        api
          .post(
            "mail/demo",
            {
              name: this.name,
              email: this.email,
              tel: this.tel,
              country: this.country,
              company: this.company,
              msg: this.msg,
              demo: this.demo
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.name = "";
              this.email = "";
              this.tel = "";
              this.country = "";
              this.company = "";
              this.msg = "";
              window.grecaptcha.reset();
              this.$toast.open(
                `${
                  this.$store.getters.trans
                    ? this.$store.getters.trans.menu_contact.error
                        .successfully_sent
                    : "Successfully sent!"
                }`
              );
            }
            this.loader = false;
          })
          .catch(() => {
            this.loader = false;
            this.$toast.error(
              `${
                this.$store.getters.trans
                  ? this.$store.getters.trans.menu_contact.error
                      .something_went_wrong
                  : "Something went wrong!"
              }`
            );
          });
      } else {
        this.$toast.error(
          `${
            this.$store.getters.trans
              ? this.$store.getters.trans.menu_contact.error.check_captcha
              : "Please check the captcha!"
          }`
        );
      }
      e.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  p {
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: #0695fd;
    margin: 0;
    width: 165px;
  }
  .ball {
    margin: 0;
    width: 5px;
    height: 5px;
    background: #0695fd;
    border-radius: 50%;
  }
}

.second-row {
  margin-top: 430px;
}

.box {
  width: 100%;
  min-height: 379px;
  box-shadow: 0px 4px 13px rgba(45, 97, 135, 0.15);
  background: #f7fafb;
  position: absolute;
  bottom: -150px;
  border-radius: 3px;
  padding: 40px 30px;
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .content {
    display: flex;
  }
  p {
    margin: 0 0 5px 5px;
    font-weight: 500;
    font-size: 0.8rem;
    color: #9e9e9e;
  }
  input {
    border: none;
    background: #ecf0f3;
    width: 100%;
    height: 60px;
    padding: 5px 20px;
    margin-bottom: 20px;
    border-radius: 2px;
    outline: none;
    font-size: 0.8rem;
    &::placeholder {
      font-weight: 500;
      font-size: 0.8rem;
      color: #9e9e9e;
    }
  }
  .text-area {
    width: 100%;
    min-height: 171px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    background: #ecf0f3;
    border-radius: 2px;
    padding: 20px 15px;
    &::placeholder {
      font-weight: 500;
      font-size: 0.875rem;
      color: #9e9e9e;
    }
  }
}

.btn-send {
  color: #fff;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 3px 40px;
  background: #0695fd;
  outline: none;
  border-radius: 30px;
  text-transform: uppercase;
  margin-top: 12px;
  box-shadow: 5px 5px 5px rgba(42, 109, 172, 0.2);
  &:focus,
  &:hover {
    background: #0285e2;
  }
}
.btn:focus,
.btn.focus {
  outline: none;
  box-shadow: none;
}
.bg {
  width: 100%;
  height: 241px;
  margin-bottom: 0;
  background: #4880d1;
}

@media (max-width: 991.98px) {
  .items {
    display: block !important;
    p {
      margin: 15px 0;
      width: 300px;
      margin-left: calc(50% - 150px);
    }
    .ball {
      margin: 15px 0 15px calc(50% - 2.5px);
    }
  }
  .second-row {
    margin-top: 700px;
  }
  .box {
    h2 {
      text-align: center;
    }
    .content {
      display: block !important;
    }
  }
  .align-btn {
    justify-content: center !important;
  }

  .captcha {
    justify-content: center !important;
  }
}
@media (max-width: 575.98px) {
  section {
    margin-top: -28px;
  }

  .container {
    padding: 0 !important;
  }

  .second-row {
    margin-top: 700px;
  }

  .box {
    width: 100%;
    padding: 40px 0px;
    h2 {
      font-size: 1.2rem;
    }
  }
}
</style>
