<template>
  <section class="py-5 p-relative" id="second">
    <div class="container">
      <div class="row align-items-center p-relative">
        <div class="col-xl-6 survey">
          <img src="@/assets/logo-landpage.svg" alt="" class="logo-landpage" />
          <div class="title">
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.ow_survey_is_your
                  : "OW Survey is your definite DIY survey platform to manage all your market research projects"
              }}
            </h3>
          </div>
          <div class="text">
            <p class="mt-5">
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.use_high_tech
                  : "Use high-tech tools to facilitate survey scripting, field management, and result analysis"
              }}
            </p>
          </div>
          <div class="text">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.your_company
                  : "Your company will execute researches in various levels of complexity and use several methods to obtain the data you need to speed your decisions"
              }}
            </p>
          </div>
        </div>
        <div class="col-xl-6 img-survey">
          <img
            src="@/assets/img-survey.png"
            alt="image Sample"
            class="img-fluid img-sample"
          />
          <img src="@/assets/dots.svg" class="dots" />
        </div>
      </div>
      <div class="row align-items-center secondRow p-relative pt-5" id="sample">
        <div class="Functionalities">
          <h3 class="mb-2">
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.features
                : "Features"
            }}
          </h3>
          <h4>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.obtain_advanced
                : "Obtain advanced insights in a short time"
            }}
          </h4>
        </div>
        <div class="col-12 items d-flex justify-content-between flex-wrap">
          <div class="col-md-6 col-xl-3 box d-flex justify-content-center">
            <div class="content">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.program_questionnaires
                    : "Program questionnaires automatically"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.using_artificial
                    : "Using artificial intelligence and machine learning, program the questionnaire in less than one minute"
                }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-xl-3 box d-flex justify-content-center">
            <div class="content">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.monitor_results
                    : "Monitor the results in real-time"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.monitor_online_field
                    : "Monitor online field progress and export the data obtained in tables, cross-tables, or PPT charts"
                }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-xl-3 box d-flex justify-content-center">
            <div class="content">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.manage_samples
                    : "Manage your samples"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage
                        .access_offerwise_panel_directly
                    : "Access Offerwise panel directly, with over 10 million people across 20 countries. You can also use other panels, databases and conduct CAPI or CATI researches"
                }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-xl-3 box d-flex justify-content-center">
            <div class="content">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.create_complex
                    : "Create complex questions and logic"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.you_can_program
                    : "You can program advanced logic and complex questions such as MaxDiff, Conjoint, Heat map, virtual shelves, etc"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col line py-5"></div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  margin-top: 20px;
}
.logo-landpage {
  width: 126px;
}
.survey {
  h3 {
    margin-top: 25px;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
  }

  p {
    width: 510px;
    margin-top: 25px;
    font-weight: 400;
    font-size: 0.875rem;
    color: #707070;
  }
}
.dots {
  position: absolute;
  z-index: -10;
  right: -35px;
  top: -90px;
}

.secondRow {
  margin-top: 100px;
  .Functionalities {
    margin: 0 auto;

    h3 {
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.5em;
      color: #666666;
    }
    h4 {
      text-align: center;
      font-weight: 600;
      font-size: 1.5rem;
      color: #000;
    }
  }
  .items {
    margin-top: 100px;
    .box {
      padding: 0px 10px 15px;
      height: auto;
      .content {
        border: 1px solid #ccdde5;
        border-radius: 5px;
        padding: 30px 10px 15px 10px;

        h4 {
          font-weight: 700;
          font-size: 1rem;
          color: #0695fd;
          line-height: 24px;
          min-height: 48px;
          text-align: center;
        }
        p {
          margin-top: 20px;
          font-weight: 400;
          font-size: 0.875rem;
          color: #707070;
          margin-bottom: 0;
          min-height: 128px;
          text-align: center;
        }
      }
    }
  }
}

.line {
  border-bottom: 1px solid #ccdde5;
}
@media (max-width: 1199.98px) {
  .img-survey {
    display: none;
  }
  .img-sample {
    display: none;
  }
  .dots {
    display: none;
  }
  .survey {
    img {
      margin-left: calc(50% - 63px);
    }
    .title {
      display: flex;
      justify-content: center;
      h3 {
        text-align: center;
        max-width: 600px;
      }
    }
    .text {
      display: flex;
      justify-content: center;
      p {
        text-align: center;
      }
    }
  }
  .secondRow {
    .content {
      width: 360px;
      min-height: 200px;
      margin-bottom: 30px;
      p {
        min-height: 105px !important;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .img-sample {
    display: none;
  }
}

@media (max-width: 575.98px) {
  section {
    margin-top: 0;
  }

  .line {
    margin-top: -50px;
  }
  .contents {
    margin-top: 22px;
    h3 {
      margin-bottom: 32px;
    }
  }

  .secondRow {
    .content {
      width: 300px;
      min-height: 200px;
      margin-bottom: 30px;
      p {
        min-height: 126px !important;
      }
    }
  }
}
</style>
