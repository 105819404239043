<template>
  <section class="py-5 p-relative">
    <img src="@/assets/bg-culture.png" class="bg-1" />
    <div class="container p-relative">
      <div class="row justify-content-center">
        <div class="titleSection">
          <h3>
            {{
              $store.getters.trans
                ? $store.getters.trans.culture_page.culture
                : "Culture"
            }}
          </h3>
        </div>
        <div
          class="col-md-11 d-flex flex-wrap justify-content-between elements"
        >
          <div class="col-xl-5 p-0">
            <div class="d-flex align-items-center content">
              <img src="@/assets/img-vision.svg" alt="vision" />
              <div class="text">
                <h3>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.culture_page.vision
                      : "Vision"
                  }}
                </h3>
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.culture_page
                          .make_the_world_of_consumer
                      : "Make the world of consumer insights and surveys exciting."
                  }}.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-5 p-0">
            <div class="d-flex align-items-center content">
              <img src="@/assets/img-mission.svg" alt="vision" />
              <div class="text">
                <h3>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.culture_page.mission
                      : "Mission"
                  }}
                </h3>
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.culture_page.to_be_a_leader
                      : "To be a leader in delivering high-quality and cost-efficient insights to our customers using technology and innovation."
                  }}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align">
        <div class="d-flex justify-content-center item">
          <div class="title-values">
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.core_values
                  : "Core Values"
              }}
            </h3>
          </div>

          <div class="item">
            <img
              src="@/assets/culture.png"
              width="800px"
              class="img-fluid"
              alt="culture"
            />
            <div class="items item1">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.togetherness
                        .togetherness
                    : "Togetherness"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.togetherness.motivated
                    : "Motivated to be the best at what we do, we strengthen ourselves through our cultural diversity. We value constructive and cooperative interpersonal relationships."
                }}.
              </p>
            </div>
            <div class="items item2">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.preparedness
                        .preparedness
                    : "Preparedness"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.preparedness.we_are
                    : "We are constantly developing to be prepared to offer increasingly efficient and innovative products and services."
                }}.
              </p>
            </div>
            <div class="items item3">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.passion.passion
                    : "Passion"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.passion.if_we
                    : "If we are spending one-third of our lives doing something, why not have passion and pride in doing it?"
                }}
              </p>
            </div>
            <div class="items item4">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.critical_thinking
                        .critical_thinking
                    : "Critical Thinking"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.critical_thinking
                        .we_create
                    : "We create personalized and efficient solutions through interaction, articulation of ideas, and critical analysis of information."
                }}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  height: 1158px;
  overflow: hidden;
  margin-bottom: 80px;
  .bg-1 {
    position: absolute;
    top: 180px;
    width: 100%;
  }
}

.titleSection {
  margin-bottom: 70px;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 1.9rem;
    color: #000000;
  }
}

.align {
  margin-top: 55px;
}

.title-values {
  top: 23px;
  position: absolute;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    color: #000000;
  }
}

.content {
  background: #fff;
  box-shadow: 3px 3px 17px rgba(58, 112, 192, 0.13);
  border-radius: 80px;
  padding: 15px 20px;
  width: 432.5px;
  min-height: 127px;
  .text {
    margin-left: 20px;
    h3 {
      font-weight: 700;
      font-size: 0.875rem;
      color: #3a70c0;
      margin-bottom: 5px;
    }
    p {
      font-weight: 400;
      font-size: 0.8125rem;
      color: #000;
      margin-bottom: 0;
      width: 270px;
    }
  }
}
.item {
  position: relative;
  width: 1000px;
  img {
    margin-left: calc(50% - 400px);
  }
  .items {
    width: 300px;
    h4 {
      font-weight: 700;
      font-size: 0.875rem;
      color: #000000;
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: 0.8125rem;
      color: #000;
    }
  }
  .item1 {
    text-align: end;
    position: absolute;
    top: 240px;
    left: -80px;
  }
  .item2 {
    text-align: end;
    position: absolute;
    top: 440px;
    left: -80px;
  }
  .item3 {
    position: absolute;
    top: 240px;
    right: -80px;
  }
  .item4 {
    position: absolute;
    top: 440px;
    right: -80px;
  }
}
.donut {
  position: absolute;
  margin-top: -130px;
  left: -30px;
  img {
    width: 167px;
    height: 167px;
  }
}

@media (max-width: 1280px) {
  .donut {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  section {
    height: 1298px;
  }

  .bg-1 {
    display: none;
  }
  .img-fluid {
    width: 700px;
  }

  .content {
    margin-left: calc(50% - 216.25px);
    margin-bottom: 50px;
  }

  .item {
    margin-bottom: 20px;
    position: relative;
    img {
      margin-left: calc(50% - 350px);
    }
    .items {
      width: 240px;
      h4 {
        font-weight: 700;
        font-size: 0.9rem;
        color: #000000;
        margin-bottom: 5px;
      }
      p {
        font-weight: 400;
        font-size: 0.8rem;
        color: #000;
        margin: 0 auto;
      }
    }
    .item1 {
      text-align: end;
      position: absolute;
      top: 200px;
      left: 14px;
    }
    .item2 {
      text-align: end;
      position: absolute;
      top: 376px;
      left: 14px;
    }
    .item3 {
      position: absolute;
      top: 200px;
      right: 14px;
    }
    .item4 {
      position: absolute;
      top: 376px;
      right: 14px;
    }
  }
}
@media (max-width: 991px) {
  section {
    height: 1098px;
  }
  .title-values {
    top: 0px;
  }
  .item {
    margin-bottom: 20px;
    position: relative;
    img {
      display: none;
    }
    .items {
      margin: 100px 0 50px 0;
      width: 650px;
      position: relative;
      h4 {
        font-weight: 700;
        font-size: 0.975rem;
        color: #000000;
        margin-bottom: 5px;
      }
      p {
        font-weight: 400;
        font-size: 0.8625rem;
        color: #000;
        width: 250px;
        min-height: 120px;
        margin: 0 auto;
      }
    }
    .item1 {
      text-align: center;
      position: static;
    }
    .item2 {
      text-align: center;
      position: static;
    }
    .item3 {
      text-align: center;
      position: absolute;
      top: 0px;
      right: -10px;
    }
    .item4 {
      text-align: center;
      position: absolute;
      top: 243px;
      right: -10px;
    }
  }
}

@media (max-width: 767.98px) {
  section {
    height: 1258px;
  }
  .item {
    margin-top: 0px;
    position: relative;
    max-width: 360px;
    .items {
      margin: 15px 0 30px 0;
      width: 100%;
      h4 {
        font-weight: 700;
        font-size: 0.975rem;
        color: #000000;
        margin-bottom: 5px;
      }
      p {
        font-weight: 400;
        font-size: 0.8625rem;
        color: #000;
        margin: 0 auto;
        width: 300px;
        min-height: 110px;
      }
    }
    .item1 {
      margin-top: 90px;
      text-align: center;
      position: static;
    }
    .item2 {
      text-align: center;
      position: static;
    }
    .item3 {
      text-align: center;
      position: static;
    }
    .item4 {
      text-align: center;
      position: static;
    }
  }
}

@media (max-width: 575.98px) {
  section {
    height: 1300px;
  }
  .content {
    max-width: 320px;
    min-height: 137px;
    margin-left: calc(50% - 160px);
    margin-bottom: 50px;
    padding: 10px 15px;

    img {
      width: 70px;
    }
    .text {
      margin-left: 10px;
      p {
        width: 200px;
      }
    }
  }
}
</style>
