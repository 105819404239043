<template>
  <section class="py-5">
    <div class="container p-relative">
      <div class="row justify-content-center">
        <div class="col-12 title d-flex justify-content-center">
          <h3>
            {{
              $store.getters.trans
                ? $store.getters.trans.lgpd.know_laws
                : "CONHEÇA ALGUMAS LEIS DE PROTEÇÃO DE DADOS"
            }}
          </h3>
        </div>
        <div class="col-10 d-flex">
          <p class="text mt-4">
            {{
              $store.getters.trans
                ? $store.getters.trans.lgpd.know_text
                : "Na Offerwise, segurança de dados e privacidade são prioridades constantes. Como lidamos com dados privados e sensíveis, temos processos que foram implementados e recursos tecnológicos que asseguram a proteção dos dados dos nossos clientes e usuários. Nós aderimos a todas as legislações vigentes que regulamentam a forma como os dados privados dos cidadãos devem ser manipulados. Cumprir a lei é uma responsabilidade séria, portanto medidas de segurança foram tomadas para garantir a privacidade dos respondentes de pesquisas em nossos painéis e a proteção de suas informações pessoais."
            }}
          </p>
        </div>
        <img src="@/assets/mapa.svg" class="map" />
      </div>
    </div>

    <div class="second-row bg-gray">
      <div class="container">
        <div class="row justif-content-center">
          <div class="col-12 sub-title d-flex justify-content-center mb-5">
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.lgpd.apply_consumers.title
                  : "MUNDIALMENTE, EXISTEM TRÊS LEIS IMPORTANTES QUE SE APLICAM AOS NOSSOS CONSUMIDORES:"
              }}
            </h3>
          </div>
          <div class="items col-12 d-flex justify-content-center flex-wrap">
            <div class="col-lg-5">
              <h4 class="">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.apply_consumers.california_ccpa
                        .title
                    : "California Consumer Privacy Act (CCPA)"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.apply_consumers.california_ccpa
                        .text
                    : ""
                }}
              </p>
            </div>
            <div class="col-lg-5">
              <h4 class="">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.apply_consumers.eu_gdpr.title
                    : ""
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.apply_consumers.eu_gdpr.text
                    : ""
                }}
              </p>
            </div>
            <div class="col-lg-5 lgpd">
              <h4 class="">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.apply_consumers.brasil_lgpd
                        .title
                    : ""
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lgpd.apply_consumers.brasil_lgpd.text
                    : ""
                }}
              </p>
            </div>
            <div class="col-lg-5 imgLgpd">
              <img
                src="@/assets/img-lgpd.svg"
                alt=""
                class="img-fluid img-lgpd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 50px;
  h3 {
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: 600;
    color: #4f8cb0;
    max-width: 500px;
    text-align: center;
  }
}
.text {
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}
.map {
  position: absolute;
  z-index: -1;
  top: 0px;
  right: -60px;
  height: 300px;
}

.bg-gray {
  background: #f7f8f9;
}
.second-row {
  margin-top: 70px;
  padding: 50px 0;
  .sub-title {
    h3 {
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: 600;
      color: #4f8cb0;
      max-width: 600px;
      text-align: center;
    }
  }

  .items div {
    h4 {
      color: #4f8cb0;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2;
    }
    p {
      font-size: 0.9rem;
      color: #666;
      line-height: 1.5rem;
    }
  }
  .lgpd {
    margin-top: 80px;
  }
  .img-lgpd {
    margin-top: 45px;
    max-height: 280px;
  }
}

@media (max-width: 991.98px) {
  .imgLgpd {
    display: none;
  }
  .lgpd {
    margin-top: 0px !important;
  }
  .items {
    p {
      margin-bottom: 30px;
    }
  }
  .map {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .items div {
    h4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
}
</style>
