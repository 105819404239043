<template>
  <div class="landPage">
    <intro />
    <secondSection />
    <thirdSection />
    <fourthSection />
    <fifthSection />
  </div>
</template>

<script>
import intro from "@/components/landpage/intro.vue";
import secondSection from "@/components/landpage/second-section.vue";
import thirdSection from "@/components/landpage/third-section.vue";
import fourthSection from "@/components/landpage/fourth-section.vue";
import fifthSection from "@/components/landpage/fifth-section.vue";

export default {
  name: "Jobs",
  components: {
    intro,
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
