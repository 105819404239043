<template>
  <section class="py-5">
    <div class="bgIntro"></div>
    <div class="bg-ball"></div>
    <div class="container p-relative">
      <img src="@/assets/plants.svg" alt="" id="plants" />
      <img src="@/assets/b-md.svg" alt="" id="bSm" />
      <img src="@/assets/b-xl.svg" alt="" id="bXl" />
      <img src="@/assets/b-xl.svg" alt="" id="b" />
      <div class="row justify-content-center">
        <div class="col-md-8 p-relative d-flex justify-content-center">
          <iframe
            v-if="$store.getters.lang === 'en'"
            src="https://www.youtube-nocookie.com/embed/CugUS9sn9mE?controls=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="$store.getters.lang === 'pt'"
            src="https://www.youtube-nocookie.com/embed/DvsMr5e8p78?controls=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="$store.getters.lang === 'es'"
            src="https://www.youtube-nocookie.com/embed/-8ndzm1x6zE?controls=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div class="col-md-8">
          <p class="mt-5 text-video py-3">
            {{
              $store.getters.trans
                ? $store.getters.trans.culture_page.offerwise_is_a_global
                : "Offerwise is a global company specialized in solutions for consumer insights with over 16 years of experience in online market research. Our panel has more than 10 million panelists across 20 countries. "
            }}.
          </p>
        </div>
        <div class="items py-3 d-flex flex-wrap">
          <div class="col-xl-6 d-flex flex-wrap">
            <div class="col-md-6 experience flex-wrap">
              <div class="stars">
                <img src="@/assets/icon-stars.svg" alt="stars" />
              </div>
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.experience
                    : "Experience"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.we_have_conducted_market
                    : "We have conducted market research for several local and foreign companies for over 16 years. We have a vast knowledge of numerous online methodologies and experience providing solutions for all kinds of necessities."
                }}.
              </p>
            </div>
            <div class="col-md-6 Quickness">
              <div class="hourglass">
                <img src="@/assets/icon-hourglass.svg" alt="hourglass" />
              </div>
              <h4 v-if="$store.getters.lang === 'en'">Agility</h4>
              <h4 v-if="$store.getters.lang === 'pt'">Rapidez</h4>
              <h4 v-if="$store.getters.lang === 'es'">Rapidez</h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page
                        .with_our_speedy_work_structure
                    : "With our speedy work structure, we can offer quality solutions for every client necessity at a competitive price. "
                }}.
              </p>
            </div>
          </div>
          <div class="col-xl-6 d-flex flex-wrap">
            <div class="col-md-6 qualified">
              <div class="qualifiedTeam">
                <img
                  src="@/assets/icon-qualified-team.svg"
                  alt="qualified team"
                />
              </div>
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.qualified_team
                    : "Qualified Team"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.offerwise_relies
                    : "Offerwise relies on a team with extensive knowledge in online market research. They are ready to advise our clients in all steps of the  projects. "
                }}.
              </p>
            </div>
            <div class="col-md-6 technology">
              <div class="technologyInnovation">
                <img
                  src="@/assets/icon-technology-innovation.svg"
                  alt="technology innovation"
                />
              </div>
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page
                        .technology_and_innovation
                    : "Technology and Innovation"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.culture_page.we_use_innovative
                    : "We use innovative data collection tools to approach the same issues from  different perspectives and explore all solutions to choose the proper manner to conduct a study."
                }}.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 line"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
section {
  position: relative;
}
.bgIntro {
  width: 100%;
  height: 450px;
  background: linear-gradient(
    273.32deg,
    #7d9bad 0.82%,
    #ccdde5 44.3%,
    #ffffff 88.78%
  );
  margin-top: -50px;
}
.bg-ball {
  width: 620px;
  height: 620px;
  border-radius: 50%;
  position: absolute;
  top: -304px;
  left: -119px;
  background: rgba(204, 221, 229, 0.08);
}
#plants {
  position: absolute;
  width: 277px;
  top: -444px;
  right: 175px;
}
#bSm {
  position: absolute;
  top: -90px;
  right: 10px;
  width: 122px;
  height: 122px;
}
#bXl {
  position: absolute;
  top: -60px;
  right: 60px;
  width: 310px;
  height: 310px;
}
#b {
  position: absolute;
  top: 30px;
  left: 110px;
  width: 182px;
  height: 182px;
}
iframe {
  margin: auto;
  margin-top: -340px;
  width: 730px;
  height: 410px;
  background: #eef2f5;
  border-radius: 26px;
  box-shadow: 10px 10px 20px rgba(45, 97, 135, 0.4);
}

.text-video {
  font-size: 0.8125rem;
  color: #707070;
  text-align: justify;
  text-align: center;
}

.stars {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin: 20px auto;
  background: linear-gradient(180deg, #4f87da 0%, #3a70c0 100%);
  box-shadow: 4px 3px 18px -3px rgba(33, 43, 51, 0.53);
}

.btn-send {
  margin-top: 20px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  position: relative;
  right: -90px;
  top: -4px;
  color: #ffffff;
  font-size: 0.8rem;
  background: #0686e0;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  padding: 3px 50px 3px 50px;
  transition: all 0.3s ease;
}

.btn-send:hover {
  text-decoration: none;
  color: #ffffff;
  background: #0875ce;
}

.hourglass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin: 20px auto;
  background: linear-gradient(180deg, #e87f88 0%, #df6771 100%);
  box-shadow: 4px 3px 18px -3px rgba(33, 43, 51, 0.53);
}

.qualifiedTeam {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin: 20px auto;
  background: linear-gradient(180deg, #285b84 0%, #215076 100%);
  box-shadow: 4px 3px 18px -3px rgba(33, 43, 51, 0.53);
}

.technologyInnovation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 25px;
  margin: 20px auto;
  background: linear-gradient(180deg, #fe9e61 0%, #e78c53 100%);
  box-shadow: 4px 3px 18px -3px rgba(33, 43, 51, 0.53);
}

.items {
  p {
    font-size: 0.75rem;
    color: #707070;
    text-align: center;
  }
  h4 {
    font-weight: 700;
    font-size: 1.125rem;
    text-align: center;
    margin-bottom: 10px;
  }
  .experience {
    h4 {
      color: #3a70c0;
    }
  }
  .Quickness {
    h4 {
      color: #df6771;
    }
  }
  .qualified {
    h4 {
      color: #215076;
    }
  }
  .technology {
    h4 {
      color: #e78c53;
    }
  }
}
.line {
  border-bottom: 0.5px solid #ccdde5;
}

@media (max-width: 991.98px) {
  #plants {
    position: absolute;
    width: 220px;
    top: -270px;
    right: 175px;
  }
  #bSm {
    display: none;
  }
  #bXl {
    display: none;
  }
  #b {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .text-video {
    text-align: center;
  }
  #plants {
    position: absolute;
    width: 220px;
    top: -340px;
    right: calc(50% - 110px);
  }
}

@media (max-width: 767.98px) {
  .bg-ball {
    display: none;
  }
}

@media (max-width: 575.98px) {
  #plants {
    position: absolute;
    width: 220px;
    top: -280px;
    right: calc(50% - 110px);
  }
  iframe {
    height: 300px;
    margin-top: -230px;
  }
  .items {
    margin-top: 30px;
  }
  .bgIntro {
    height: 350px;
  }
}
</style>
