<template>
  <div
    class="header"
    v-if="$route.name !== 'survey' && $route.name !== ''"
    :class="{ change_color: scrollPosition > 50 }"
  >
    <header class="container d-none d-lg-block">
      <div class="row align-items-center pt-2 pb-2">
        <div
          @click="option = 'home'"
          :class="{ active: option === 'home' }"
          class="col-lg-3 logo"
        >
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/logo_final.svg" alt="Logo" />
          </router-link>
        </div>

        <nav v-if="$route.name !== 'salesterms'" class="col-lg-8 menu">
          <div class="dropdown-research" id="dropResearch">
            <div>
              <router-link
                :to="{ name: 'research', query: { option: 'quantitatives' } }"
                v-if="$route.name !== 'research'"
                class="pb-5"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.research_solutions
                    : "Research Solutions"
                }}
                <img
                  src="@/assets/arrow-down-header.svg"
                  width="13px"
                  class="arrow-down-header"
                />
              </router-link>
              <div class="active-items" v-if="$route.name === 'research'">
                <router-link
                  :to="{
                    name: 'research',
                    query: { option: 'quantitatives' }
                  }"
                  class="pb-5"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.research_solutions
                      : "Research Solutions"
                  }}
                  <img
                    src="@/assets/arrow-down-header.svg"
                    width="13px"
                    class="arrow-down-header"
                  />
                </router-link>
                <div
                  class="ball-research"
                  :class="{ active: option === 'research' }"
                ></div>
              </div>
            </div>
            <div class="drop-research-item">
              <div class="triangle">
                <div class="triangleUp"></div>
              </div>
              <h2>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.solution
                    : " Solutions"
                }}
              </h2>
              <div class="links">
                <div
                  @mouseover="
                    (quantitatives = true),
                      (qualitatives = false),
                      (statistical = false),
                      (passiveData = false),
                      (surveys = false)
                  "
                  :class="{ active: quantitatives }"
                  class="mt-2 research-links-md"
                >
                  <router-link
                    :to="{
                      name: 'research',
                      query: { option: 'quantitatives', scroll: true }
                    }"
                    class="item"
                    :class="{ active: quantitatives }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions.quantitative
                            .quantitative
                        : "Quantitative"
                    }}
                  </router-link>
                </div>
                <div
                  :class="{ active: quantitatives }"
                  class="item-hover-quant"
                >
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests
                            .communication_and_branding_studies
                        : "Communication and Branding Studies "
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.brand_health
                        : "Brand Health"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.pre_and_post
                        : "Pre and Post Communication Tests "
                    }}
                  </p>
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.indicators
                        : "Indicators "
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.omnibus
                        : "Omnibus"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.public_affairs
                        : "public affairs"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ad_hoc_tracker
                        : "Ad hoc/Tracker"
                    }}
                  </p>
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions.quantitative
                            .test
                        : "Tests"
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ihut
                        : "IHUT"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions.quantitative
                            .concept_test
                        : "Concept Test"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.task_panel
                        : "Task Panel"
                    }}
                  </p>
                </div>
                <div
                  @mouseover="
                    (qualitatives = true),
                      (quantitatives = false),
                      (statistical = false),
                      (passiveData = false),
                      (surveys = false)
                  "
                  :class="{ active: qualitatives }"
                  class="mt-2 research-links-md"
                >
                  <router-link
                    :to="{
                      name: 'research',
                      query: { option: 'qualitatives', scroll: true }
                    }"
                    class="item"
                    :class="{ active: qualitatives }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.qualitative
                        : "Qualitative"
                    }}
                  </router-link>
                </div>
                <div :class="{ active: qualitatives }" class="item-hover">
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.qualitative
                        : "Qualitative"
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.communities
                        : "communities"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.focus_group
                        : "Focus group"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.consumer_diaries
                        : "consumer diaries"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.in_depth_interview
                        : "In-depth Interview "
                    }}
                  </p>
                </div>
                <div
                  @mouseover="
                    (statistical = true),
                      (quantitatives = false),
                      (qualitatives = false),
                      (passiveData = false),
                      (surveys = false)
                  "
                  :class="{ active: statistical }"
                  class="mt-2 research-links-xl"
                >
                  <router-link
                    :to="{
                      name: 'research',
                      query: { option: 'statistical', scroll: true }
                    }"
                    class="item"
                    :class="{ active: statistical }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions
                            .statistical_methods
                        : "Statistical Methods"
                    }}
                  </router-link>
                </div>
                <div :class="{ active: statistical }" class="item-hover">
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions
                            .statistical_methods
                        : "Statistical Methods"
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.price_elasticity
                        : "Price Elasticity"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.conjoint
                        : "conjoint"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.maxdiff
                        : "maxdiff"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.nps
                        : "NPS"
                    }}
                  </p>
                </div>
                <div
                  @mouseover="
                    (passiveData = true),
                      (quantitatives = false),
                      (qualitatives = false),
                      (statistical = false),
                      (surveys = false)
                  "
                  :class="{ active: passiveData }"
                  class="mt-2 research-links-md"
                >
                  <router-link
                    :to="{
                      name: 'research',
                      query: { option: 'passive-data', scroll: true }
                    }"
                    class="item"
                    :class="{ active: passiveData }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.passive_data
                        : "Passive Data"
                    }}
                  </router-link>
                </div>
                <div :class="{ active: passiveData }" class="item-hover">
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.passive_data
                        : "Passive Data"
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions
                            .social_listening
                        : "Social Listening"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.geofencing
                        : "geofencing"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.eye_tracking
                        : "eye tracking"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.face_recognition
                        : "face recognition"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.cookies_and_tags
                        : "Cookies & Tags"
                    }}
                  </p>
                </div>
                <div
                  @mouseover="
                    (surveys = true),
                      (quantitatives = false),
                      (qualitatives = false),
                      (statistical = false),
                      (passiveData = false)
                  "
                  :class="{ active: surveys }"
                  class="mt-2 research-links-xl"
                >
                  <router-link
                    :to="{
                      name: 'research',
                      query: { option: 'quick-surveys', scroll: true }
                    }"
                    class="item"
                    :class="{ active: surveys }"
                  >
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ready_to_go_surveys
                        : "Ready to go Surveys"
                    }}
                  </router-link>
                </div>
                <div :class="{ active: surveys }" class="item-hover">
                  <h5>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ready_to_go_surveys
                        : "Ready to go Surveys"
                    }}
                  </h5>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.a_b_tests
                        : "A/B Tests"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.brand_health
                        : "Brand Health"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions.quantitative
                            .pre_test
                        : "Pre Test"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.research_solutions.quantitative
                            .post_test
                        : "Post Test"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="size-hover">
            <router-link
              v-if="$route.name !== 'product'"
              :to="{ name: 'product' }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.products
                  : "Products"
              }}
            </router-link>
            <div class="active-items" v-if="$route.name === 'product'">
              <router-link
                :to="{ name: 'product' }"
                :class="{ active: option === 'product' }"
                class="link-hover"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.products
                    : "Products"
                }}
              </router-link>
              <div
                class="ball-active"
                :class="{ active: option === 'product' }"
              ></div>
            </div>
          </div>

          <div>
            <router-link v-if="$route.name !== 'about'" :to="{ name: 'about' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.about_us
                  : "About"
              }}
            </router-link>
            <div class="active-items" v-if="$route.name === 'about'">
              <router-link
                :to="{ name: 'about' }"
                :class="{ active: option === 'about' }"
                class="link-hover"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.about_us
                    : "About"
                }}
              </router-link>
              <div
                class="ball-active"
                :class="{ active: option === 'about' }"
              ></div>
            </div>
          </div>

          <div>
            <a target="_blank" :href="newsUrl">
              {{
                language === "en"
                  ? "News"
                  : language === "pt"
                  ? "Notícias"
                  : "Noticias"
              }}
            </a>
          </div>

          <!--<div>
            <router-link
              v-if="$route.name !== 'resources'"
              :to="{ name: 'resources', query: { option: 'inside-ow' } }"
              >
               {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.resources
                  : "Resources"
              }}
              </router-link
            >
            <div class="active-items" v-if="$route.name === 'resources'">
              <router-link
                :to="{ name: 'resources', query: { option: 'inside-ow' } }"
                :class="{ active: option === 'resources' }"
                class="link-hover"
                >
                {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.resources
                  : "Resources"
              }}
                </router-link
              >
              <div
                class="ball-active"
                :class="{ active: option === 'resources' }"
              ></div>
            </div>
          </div>-->
          <div>
            <router-link
              v-if="$route.name !== 'careers'"
              :to="{ name: 'careers' }"
            >
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.careers
                  : "Careers"
              }}
            </router-link>
            <div class="active-items" v-if="$route.name === 'careers'">
              <router-link
                :to="{ name: 'careers' }"
                :class="{ active: option === 'careers' }"
                class="link-hover"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.careers
                    : "Careers"
                }}
              </router-link>
              <div
                class="ball-active"
                :class="{ active: option === 'careers' }"
              ></div>
            </div>
          </div>
          <div v-if="$store.getters.lang === 'en'">
            <router-link
              :to="{ name: 'contact' }"
              v-if="$route.name !== 'contact'"
            >
              Contact Us
            </router-link>
            <div class="active-items" v-if="$route.name === 'contact'">
              <router-link
                :to="{ name: 'contact' }"
                :class="{ active: option === 'contact' }"
                class="link-hover"
              >
                Contact Us
              </router-link>
              <div
                class="ball-active"
                :class="{ active: option === 'contact' }"
              ></div>
            </div>
          </div>
          <div v-if="$store.getters.lang === 'pt'">
            <router-link
              :to="{ name: 'contact' }"
              v-if="$route.name !== 'contact'"
            >
              Entre em Contato
            </router-link>
            <div class="active-items" v-if="$route.name === 'contact'">
              <router-link
                :to="{ name: 'contact' }"
                :class="{ active: option === 'contact' }"
                class="link-hover"
              >
                Entre em Contato
              </router-link>
              <div
                class="ball-active"
                :class="{ active: option === 'contact' }"
              ></div>
            </div>
          </div>
          <div v-if="$store.getters.lang === 'es'">
            <router-link
              :to="{ name: 'contact' }"
              v-if="$route.name !== 'contact'"
            >
              Contáctenos
            </router-link>
            <div class="active-items" v-if="$route.name === 'contact'">
              <router-link
                :to="{ name: 'contact' }"
                :class="{ active: option === 'contact' }"
                class="link-hover"
              >
                Contáctenos
              </router-link>
              <div
                class="ball-active"
                :class="{ active: option === 'contact' }"
              ></div>
            </div>
          </div>
        </nav>

        <div
          :class="$route.name === 'salesterms' ? 'col-lg-9' : 'col-lg-1'"
          class="d-flex justify-content-end align-items-center pt-4 pt-lg-0"
        >
          <div class="dropdown-language" id="dropLanguage">
            <a @click="handleDrop('dropLanguage')">
              <img src="@/assets/arrow-down-header.svg" class="arrow-down" />
              <img
                src="@/assets/icon-language.svg"
                class="icon-language"
                :alt="language"
              />{{ language }}</a
            >
            <div class="drop-language-item">
              <div class="triangle">
                <div class="triangleUp-mobile"></div>
              </div>
              <div class="language-button">
                <button
                  v-if="$route.name !== 'salesterms'"
                  @click="language = 'pt'"
                  :class="{ active: language === 'pt' }"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.lang_menu.portuguese
                      : "Portuguese"
                  }}
                </button>
                <button
                  @click="language = 'es'"
                  :class="{ active: language === 'es' }"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.lang_menu.spanish
                      : "Espanhol"
                  }}
                </button>
                <button
                  @click="language = 'en'"
                  :class="{ active: language === 'en' }"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.lang_menu.english
                      : "English"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <header class="d-lg-none d-block menu-mobile d-flex justify-content-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'home' }">
          <img
            src="@/assets/logo-mobile.svg"
            alt="Logo"
            class="pl-3 logo-mobile"
          />
        </router-link>
        <div class="line-header"></div>
        <div class="page-name">
          <div v-if="$route.name === 'home'">
            <p>Offerwise</p>
          </div>
          <div v-if="$route.name === 'dpl'">
            <p>LGPD</p>
          </div>
          <div v-if="$route.name === 'research'">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.research_solutions
                  : "Research Solutions"
              }}
            </p>
          </div>
          <div v-if="$route.name === 'product'">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.products
                  : "Products"
              }}
            </p>
          </div>
          <div v-if="$route.name === 'about'">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.about_us
                  : "About"
              }}
            </p>
          </div>

          <div v-if="$route.name === 'resources'">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.resources
                  : "Resources"
              }}
            </p>
          </div>
          <div v-if="$route.name === 'careers'">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.careers
                  : "Careers"
              }}
            </p>
          </div>
          <div v-if="$route.name === 'panelbook'">
            <p>Panel Book</p>
          </div>
          <div v-if="$route.name === 'contact'">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.contact
                  : "Contact"
              }}
            </p>
          </div>
          <div v-if="$route.name === 'openmatter'">
            <p>Blog</p>
          </div>
          <div v-if="$route.name === 'quick-servey-details'">
            <p>Quick Servey Details</p>
          </div>
        </div>
      </div>
      <div class="drop-search" v-if="$route.name === 'resources'">
        <div class="dropdown-search" id="dropSearch">
          <a @click="handleDrop('dropSearch')">
            <img src="@/assets/magnifyingglass.svg" />
          </a>
          <div class="drop-search-item">
            <form>
              <div class="bg-ligh p-2">
                <div class="input-group d-flex align-items-center">
                  <div class="magnifyingglass">
                    <img src="@/assets/magnifyingglass.svg" alt="arrow" />
                  </div>
                  <input
                    type="search"
                    placeholder="Search"
                    aria-describedby="button-addon2"
                    class="form-control border-0 bg-ligh"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Slide :closeOnNavigation="true" width="282" right>
        <router-link
          :to="{ name: 'research', query: { option: 'quick-surveys' } }"
          v-if="$route.name !== 'research' && $route.name !== 'salesterms'"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.menu_contact.research_solutions
              : "Research Solutions"
          }}
        </router-link>

        <div
          class="active-items-mobile"
          v-if="$route.name === 'research' && $route.name !== 'salesterms'"
        >
          <router-link
            :to="{ name: 'research', query: { option: 'quick-surveys' } }"
          >
            {{
              $store.getters.trans
                ? $store.getters.trans.menu_contact.research_solutions
                : "Research Solutions"
            }}
          </router-link>
        </div>

        <router-link
          :to="{ name: 'product' }"
          v-if="$route.name !== 'product' && $route.name !== 'salesterms'"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.menu_contact.products
              : "Products"
          }}
        </router-link>

        <div
          class="active-items-mobile"
          v-if="$route.name === 'product' && $route.name !== 'salesterms'"
        >
          <router-link :to="{ name: 'product' }">
            {{
              $store.getters.trans
                ? $store.getters.trans.menu_contact.products
                : "Products"
            }}
          </router-link>
        </div>

        <router-link
          :to="{ name: 'about' }"
          v-if="$route.name !== 'about' && $route.name !== 'salesterms'"
          >{{
            $store.getters.trans
              ? $store.getters.trans.menu_contact.about_us
              : "About"
          }}</router-link
        >

        <div
          class="active-items-mobile"
          v-if="$route.name === 'about' && $route.name !== 'salesterms'"
        >
          <router-link :to="{ name: 'about' }">
            {{
              $store.getters.trans
                ? $store.getters.trans.menu_contact.about_us
                : "About"
            }}
          </router-link>
        </div>

        <div>
          <a target="_blank" :href="newsUrl">
            {{
              language === "en"
                ? "News"
                : language === "pt"
                ? "Notícias"
                : "Noticias"
            }}
          </a>
        </div>

        <!--<router-link
          :to="{ name: 'resources', query: { option: 'inside-ow' } }"
          v-if="$route.name !== 'resources'"
          >
          {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.resources
                  : "Resources"
              }}
          </router-link
        >
        <div class="active-items-mobile" v-if="$route.name === 'resources'">
          <router-link
            :to="{ name: 'resources', query: { option: 'inside-ow' } }"
            >
            {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.resources
                  : "Resources"
              }}
            </router-link
          >
        </div>-->
        <router-link
          :to="{ name: 'careers' }"
          v-if="$route.name !== 'careers' && $route.name !== 'salesterms'"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.menu_contact.careers
              : "Careers"
          }}
        </router-link>
        <div
          class="active-items-mobile"
          v-if="$route.name === 'careers' && $route.name !== 'salesterms'"
        >
          <router-link :to="{ name: 'careers' }">
            {{
              $store.getters.trans
                ? $store.getters.trans.menu_contact.careers
                : "Careers"
            }}
          </router-link>
        </div>

        <router-link
          :to="{ name: 'contact' }"
          v-if="
            $route.name !== 'contact' &&
              $store.getters.lang === 'en' &&
              $route.name !== 'salesterms'
          "
        >
          Contact Us
        </router-link>
        <div
          class="active-items-mobile"
          v-if="
            $route.name === 'contact' &&
              $store.getters.lang === 'en' &&
              $route.name !== 'salesterms'
          "
        >
          <router-link :to="{ name: 'contact' }"> Contact Us </router-link>
        </div>

        <router-link
          :to="{ name: 'contact' }"
          v-if="
            $route.name !== 'contact' &&
              $store.getters.lang === 'pt' &&
              $route.name !== 'salesterms'
          "
        >
          Entre em Contato
        </router-link>
        <div
          class="active-items-mobile"
          v-if="
            $route.name === 'contact' &&
              $store.getters.lang === 'pt' &&
              $route.name !== 'salesterms'
          "
        >
          <router-link :to="{ name: 'contact' }">
            Entre em Contato
          </router-link>
        </div>

        <router-link
          :to="{ name: 'contact' }"
          v-if="
            $route.name !== 'contact' &&
              $store.getters.lang === 'es' &&
              $route.name !== 'salesterms'
          "
        >
          Contáctenos
        </router-link>
        <div
          class="active-items-mobile"
          v-if="$route.name === 'contact' && $store.getters.lang === 'es'"
        >
          <router-link :to="{ name: 'contact' }"> Contáctenos </router-link>
        </div>

        <div v-if="$route.name !== 'salesterms'" class="line"></div>
        <div class="language">
          <div class="language-preferences d-flex align-items-center">
            <img src="@/assets/icon-language.svg" />
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.menu_contact.language_preferences
                  : "Language Preferences"
              }}
            </p>
          </div>
          <div class="choose-language">
            <div class="d-flex align-items-center mb-2">
              <p
                v-if="$route.name !== 'salesterms'"
                @click="language = 'pt'"
                :class="{ active: language === 'pt' }"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lang_menu.portuguese
                    : "Portuguese"
                }}
              </p>
              <div
                class="ball-language"
                :class="{ active: language === 'pt' }"
              ></div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <p
                @click="language = 'es'"
                :class="{ active: language === 'es' }"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lang_menu.spanish
                    : "Espanhol"
                }}
              </p>
              <div
                class="ball-language"
                :class="{ active: language === 'es' }"
              ></div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <p
                @click="language = 'en'"
                :class="{ active: language === 'en' }"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.lang_menu.english
                    : "English"
                }}
              </p>
              <div
                class="ball-language"
                :class="{ active: language === 'en' }"
              ></div>
            </div>
          </div>
        </div>
      </Slide>
    </header>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

const NEWS_URL_PT = "https://medium.com/@offerwise";
const NEWS_URL_ES_AND_EN = "https://medium.com/@offerwiselatam";

export default {
  components: {
    Slide
  },
  data() {
    return {
      linkResearch: "1",
      language: this.$store.getters.lang,
      surveys: false,
      quantitatives: true,
      qualitatives: false,
      statistical: false,
      passiveData: false,
      option: "",
      activeSearch: "",
      scrollPosition: null,
      newsUrl: NEWS_URL_ES_AND_EN
    };
  },
  watch: {
    language() {
      this.$store.commit("lang", this.language);
      this.$store.commit("trans", this.language);
      const query = { ...this.$route.query, lang: this.language };
      this.$router.replace({ query });

      this.newsUrl = this.language === "pt" ? NEWS_URL_PT : NEWS_URL_ES_AND_EN;
    }
  },
  methods: {
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    },
    outsideClick(element, events, callback) {
      const html = document.documentElement;
      const outside = "data-outside";
      if (!element.hasAttribute(outside)) {
        events.forEach(userEvent => {
          setTimeout(() => {
            html.addEventListener(userEvent, () => {
              if (!element.contains(event.target)) {
                element.removeAttribute(outside);
                events.forEach(userEvent => {
                  html.removeEventListener(userEvent, this.handleOutsideClick);
                });
                callback();
              }
            });
          });
        });
        element.setAttribute(outside, "");
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    }
  },
  mounted() {
    /*var section = this.$router.currentRoute.hash.replace("#", "");
    if (section)
      this.$nextTick(() =>
        window.document.getElementById(section).scrollIntoView()
      );*/
    window.addEventListener("scroll", this.updateScroll);

    if (this.$route.name === "salesterms" && this.language !== "en") {
      this.language = "es";
    }

    this.newsUrl = this.language === "pt" ? NEWS_URL_PT : NEWS_URL_ES_AND_EN;
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  header {
    &:nth-of-type(2) {
      position: fixed;
      display: flex;
      justify-content: space-between;
      background: #fff;
      display: flex;
      flex-direction: column;
      height: 60px;
      width: 100%;
    }
  }
}
.change_color {
  background: #fff;
  border-bottom: 1px solid rgb(211, 211, 211);
}
.dropdown-language {
  position: relative;
  > a {
    text-transform: uppercase;
    color: #707070;
    font-size: 0.75rem;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    img {
      margin-right: 10px;
    }
    .arrow-down {
      width: 10px;
      height: 7px;
    }
    .icon-language {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
  &:hover {
    color: #8e8e8e;
    .drop-language-item {
      display: block;
    }
    .arrow-down {
      transform: rotate(180deg) !important;
      transition: all 0.5s ease;
    }
  }
  &:focus {
    .arrow-down {
      transform: rotate(180deg) !important;
      transition: all 0.5s ease;
    }
  }
  .drop-language-item {
    width: 140px;
    border-radius: 5px;
    left: -12px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    transform-origin: top center;
    background: #e9e9e9;
    position: absolute;
    z-index: 5;
    top: 55px;
    padding: 10px;
    text-align: center;
    box-shadow: 5px 5px 5px rgba(42, 109, 172, 0.2);
    a {
      display: block;
      color: #495057;
      padding: 2px 10px;
      &:hover,
      &.active {
        color: #666;
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &.active {
      display: block;
    }
  }
  .arrow-down {
    position: relative;
    z-index: 10;
    right: -6px;
    width: 12px;
    height: 12px;
  }
}

.logo {
  img {
    width: 100%;
  }
}

.menu {
  display: flex;
  align-items: center;

  padding-left: 35px;

  div {
    > a {
      text-transform: uppercase;
      color: #707070;
      font-size: 0.75rem;
      font-weight: 600;
      text-decoration: none;
      transition: all 0.3s ease;
      //padding: 5px 10px; quando adicionar o resources no menu habilitar esse padding
      padding: 5px 15px;
      &:hover {
        color: #4f87da;
      }
    }
  }
}

.dropdown-research {
  > a {
    color: #707070;
    cursor: pointer;
  }

  &:hover {
    color: #8e8e8e;
    .drop-research-item {
      display: block;
    }
  }
  .arrow-down-header {
    margin: 0 0 3px 2px;
  }
  &:hover {
    .arrow-down-header {
      transform: rotate(180deg) !important;
      transition: all 0.5s ease;
    }
  }
  .drop-research-item {
    width: 427px;
    height: 246px;
    padding: 40px 20px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    transform-origin: top center;
    background: #fff;
    position: absolute;
    z-index: 5;
    top: 40px;
    left: -70px;
    background: url(../../assets/bg-dropdown-header.svg) no-repeat left #fff;
    box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
    border-radius: 5px;
    h2 {
      font-weight: 600;
      font-size: 0.9375rem;
      padding-left: 10px;
      color: #6c6c6c;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    .links {
      display: flex;
      flex-direction: column;
      .research-links-md {
        width: 135px;
      }
      .research-links-xl {
        width: 173px;
      }
      .item {
        color: #000;
        font-weight: 700;
        padding-right: 0;
        font-size: 0.875rem;
        text-transform: initial;
        &:hover,
        &.active {
          color: #666;
        }
      }
      .item-hover-quant {
        width: 150px;
        position: absolute;
        right: 30px;
        top: 5px;
        display: none;
        &.active {
          display: block;
        }
        h5 {
          font-size: 0.75rem;
          font-weight: 700;
          color: #707070;
          margin-top: 5px;
        }
        p {
          font-weight: 400;
          font-size: 0.625rem;
          color: #707070;
          margin: 0;
        }
      }
      .item-hover {
        width: 150px;
        position: absolute;
        right: 30px;
        top: 30px;
        display: none;
        &.active {
          display: block;
        }
        h5 {
          font-size: 0.75rem;
          font-weight: 700;
          color: #707070;
          margin-top: 10px;
        }
        p {
          font-weight: 400;
          font-size: 0.625rem;
          color: #707070;
          margin-bottom: 5px;
        }
      }
    }
    &.active {
      display: block;
    }
  }
}

.active-items {
  position: relative;
  a {
    text-transform: uppercase;
    color: #4f87da !important;
    font-size: 0.75rem;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    padding: 5px 10px;
  }
  .ball-research {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #4f87da;
    position: absolute;
    left: calc(50% - 10px);
    bottom: -8px;
  }
  .ball-active {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #4f87da;
    position: absolute;
    left: calc(50% - 2px);
    bottom: -8px;
  }
}

.ball-diy {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #4f87da;
  position: absolute;
  //left: 254.5px;
  left: 294.5px;
  bottom: -8px;
}
.ball-about {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #4f87da;
  position: absolute;
  //right: 238px;
  right: 188px;
  bottom: -8px;
}
.ball-blog {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #4f87da;
  position: absolute;
  right: 162px;
  bottom: -8px;
}

.ball-jobs {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #4f87da;
  position: absolute;
  //right: 80.5px;
  right: 112px;
  bottom: -8px;
}

.menu-mobile {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  a {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    color: #707070;
    text-transform: uppercase;
  }
}

.logo-mobile {
  width: 60px;
  height: 60px;
}

.line-header {
  border-left: 1.5px solid rgba(0, 0, 0, 0.1);
  height: 40px;
  margin-left: 10px;
}

.page-name {
  p {
    margin: 0 10px;
    color: #373d49;
    font-weight: 600;
    font-size: 1rem;
  }
}

.active-items-mobile {
  a {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    color: #4f87da;
    text-transform: uppercase;
  }
}

.bm-item-list > * {
  padding: 0.6em;
}

.line {
  border-bottom: 0.5px solid #d1d3d4;
  position: absolute;
  width: 282px;
  left: 0;
}

.drop-search {
  position: absolute;
  right: 70px;
  .dropdown-search {
    > a {
      transition: all 0.3s ease;
      padding: 20px 10px 50px 10px;
      cursor: pointer;
    }
    &:hover,
    &:focus,
    &.active {
      .drop-search-item {
        display: block;
      }
    }
  }
  .drop-search-item {
    width: 300px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    transform-origin: top center;
    background: #fff;
    position: absolute;
    border-radius: 7px;
    z-index: 5;
    top: 50px;
    left: -480%;
    padding: 5px;
    text-align: center;
    box-shadow: 5px 5px 5px rgba(42, 109, 172, 0.2);
    &.active {
      display: block;
    }
  }
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.language {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .language-preferences {
    img {
      width: 18px;
      height: 18px;
    }
    p {
      margin: 0 0 0 10px;
      font-weight: 400;
      font-size: 0.9rem;
      color: #9c9c9c;
    }
  }
  .choose-language {
    margin-top: 20px;
    p {
      margin: 0;
      font-weight: 400;
      font-size: 1.125rem;
      color: #9c9c9c;
      transition: all 0.5s ease;
      cursor: pointer;
      &.active {
        color: #3a70c0;
      }
    }
    .ball-language {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #3a70c0;
      margin-left: 10px;
      display: none;
      animation: opacity 0.5s ease-in-out;
      &.active {
        display: block;
      }
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.8;
  }
}

.align-btn {
  padding: 0;
  margin-top: 20px;
}

.triangleUp {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  position: absolute;
  top: -13px;
  left: calc(50% - 15px);
}

.triangleUp-mobile {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #e9e9e9;
  position: absolute;
  top: -13px;
  left: calc(50% - 15px);
}

@media (max-width: 1199.98px) {
  .dropdown-research {
    .drop-research-item {
      left: -118px;
    }
  }
  .menu {
    div {
      > a {
        //padding: 5px 7px;
        padding: 5px 15px;
        font-size: 0.71rem !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.language-button {
  button {
    border: none;
    color: #707070;
    background: transparent;
    font-size: 0.9rem !important;
    outline: 0;
    cursor: pointer;
    &.active {
      color: #3a70c0;
    }
  }
}
</style>
