<template>
  <section id="intro">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 pt-5">
          <div class="vertical-items pb-5 pt-5">
            <div class="">
              <p class="text-italic pb-5">
                Muitas pessoas que têm interesse ou trabalham com pesquisa de
                mercado, entendem os passos cruciais sobre as atividades deste
                segmento. A dúvida provém da funcionalidade de cada mecanismo,
                ou seja, suas diferenças e especificidades. A partir de hoje,
                iremos mostrar como a Offerwise trabalha com cada processo,
                quais os sistemas e serviços utilizados, nossa forma de
                atendimento aos clientes e claro, a trajetória de cada
                funcionário.
              </p>
              <div class="title pb-3">
                <p>Processamento de dados</p>
              </div>
              <p class="text-pm pb-4">
                Hoje vamos falar sobre Processamento de Dados, que se resume, de
                maneira simples em, transformar as respostas dadas pelos
                painelistas em informações assertivas de fácil entendimento para
                os nossos clientes. Por mais que não seja o processo inicial
                para compreender sobre pesquisa de mercado, Yolanda Maia, que
                trabalha há 10 meses na Offerwise, informa que entender melhor o
                banco de dados utilizado, proporciona segurança para a
                implementação de políticas de mercado.
              </p>
              <p class="text-pm pb-4">
                Processamento de dados Os resultados são frutos do sistema Lime
                Survey que, inicialmente, apresenta códigos brutos. Yolanda
                consegue selecionar e baixar estes dados através de segmentos
                variáveis, com uma sequência de identificação de painelistas e
                suas respostas. Estes processos se moldam a partir da demanda do
                cliente. Normalmente, questões que requerem respostas mais
                completas exigem um tempo maior para que todas as informações
                sejam tabuladas de forma efetiva.
              </p>
              <p class="text-pm pb-4">
                Yolanda afirma que estamos sempre atentos à necessidade do
                cliente e, por isso, enviamos os cruzamentos iniciais de dados
                para que estes tenham uma ideia de como o estudo está
                caminhando. Além disso, oferecemos sistemas de alto padrão que
                procuram soluções de ponta a ponta, reforçando a participação e
                engajamento das pessoas recrutadas durante todo o processo.
                Nosso sistema é acoplado ao Fraud AI que utiliza inteligência
                artificial para assegurar a qualidade e integridade dos dados
                coletados, prevendo a ocorrência de fraudes, através de
                algoritmos avançados que estudam o comportamento dos
                painelistas. Esse processo resulta em uma melhor qualidade dos
                dados coletados.
              </p>
              <p class="text-pm pb-4">
                Para entender melhor, Yolanda explica que seu trabalho é
                acompanhar todo o processamento de estudos mais elaborados,
                acompanhamento do cliente frente suas necessidades/dúvidas e
                elaboração de tabelas. A partir disso, ela pode enxergar, de
                forma mais clara, a aplicabilidade de produtos e respectivas
                marcas diante das opiniões do público. “Trabalhar com a linha de
                processamento, me fez não somente entender como uma empresa pode
                trabalhar para conquistar seu público alvo, mas, também,
                alcançar um maior nicho de pessoas”, acrescenta. Para Yolanda, o
                processamento, além de todo estudo de campo que a Offerwise
                desempenha, traz à empresa a compreensão das necessidades não
                somente vinculadas àqueles que já conhecem a marca, mas para
                aqueles que podem adquirir confiança ao perceber o diferencial.
              </p>
              <p class="text-pm pb-4">
                Yolanda enfatiza que a comunicação e a autonomia oferecida pela
                empresa facilita seu crescimento profissional a partir de
                experiências diárias com colegas. Ela afirma que este fator é
                importante para alcançar resultados favoráveis. Por ser um setor
                recente, ela acredita que tem muito a crescer a partida das
                metodologias implementadas para atender as novas demandas e
                condutas do mercado. A Offerwise consegue, através de sistemas
                próprios e inúmeros serviços de qualidade, obter a excelência
                face de suas futuras pesquisas de mercado.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 pt-5">
          <div class="mobile">
            <div class="align-text">
              <div class="vertical-items flex-wrap pb-4 pt-5">
                <form action="" class="pb-5">
                  <div class="p-1 bg-light rounded rounded-pill shadow-sm mb-4">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <button
                          id="button-addon2"
                          type="submit"
                          class="btn btn-link text-warning"
                        >
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                      <a class="magnifyingglass" href="">
                        <img src="@/assets/magnifyingglass.svg" alt="arrow"
                      /></a>
                      <input
                        type="search"
                        placeholder="Search"
                        aria-describedby="button-addon2"
                        class="form-control border-0 bg-light"
                      />
                    </div>
                  </div>
                </form>
                <div class="title-categories">
                  <p>Categories</p>
                </div>
              </div>
            </div>
            <div class="vertical-items-2 flex-wrap">
              <div class="categorias-text">
                <a href="#"><p class="text-pm">Inside OW</p></a>
                <a href="#"><p class="text-pm">News</p></a>
                <a href="#"><p class="text-pm">Innovation</p></a>
                <a href="#"><p class="text-pm">Reports</p></a>
              </div>
            </div>
            <div class="vertical-items flex-wrap pb-4 pt-5">
              <div class="flex-wrap">
                <div class="title-categories">
                  <p>Recents Posts</p>
                </div>
              </div>
            </div>
            <div class="vertical-items-2 flex-wrap">
              <div class="categorias-text">
                <a href="#"> <p class="text-pm">Data Processing</p></a>
                <a href="#"> <p class="text-pm">Data Processing</p></a>
                <a href="#"> <p class="text-pm">Data Processing</p></a>
                <a href="#"> <p class="text-pm">Data Processing</p></a>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap pb-5 col-xl-9 selectOffices">
          <div class="lineOffices"></div>
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-xl-2">
          <div class="align-center">
            <div class="circle">
              <img src="@/assets/yolanda-blog.png" />
            </div>
          </div>
        </div>
        <div class="col-xl-3 pb-5">
          <div class="align-center">
            <div class="author-blog">
              <div class="icon-retangle">
                <p class="p-icon-retangle">Autora:</p>
              </div>
              <p class="title-autor">Yolanda Maia</p>
              <p class="function-autor">Data Processing Analyst na OW</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.input-group.md-form.form-sm.form-2 input {
  border: 1px solid #bdbdbd;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.align-text {
  text-align: left;
}

.form-control {
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
}

.text-ow {
  margin-left: 10px;
}

.item {
  display: flex;
}

.title {
  p {
    margin-bottom: 0;
    letter-spacing: 0.12em;
    color: #202020;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.title-categories {
  p {
    margin-bottom: 0;
    letter-spacing: 0.12em;
    color: #202020;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid #eef2f5;
    line-height: 2em;
  }
}

.vertical-items {
  > div {
    margin-bottom: 20px;
    margin-right: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 25px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #666666;
      margin-bottom: 0;
    }
  }
}

.categorias-text {
  p {
    font-size: 14px;
    color: #707070;
    margin-bottom: 0;
    border-bottom: 1px solid #eef2f5;
    line-height: 2em;
    margin-bottom: 15px;
  }
}

.img-banner {
  position: relative;
  z-index: 1;
  width: 100%;
}

.text-italic {
  font-style: italic;
}

.lineOffices {
  border-bottom: 1px solid #d1d3d4;
  position: relative;
  width: 100%;
  top: -18px;
}

.circle {
  background-color: #aaa;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.circle img {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.author-blog {
  width: 260px;
  margin-left: 20px;
  margin-top: 20px;
}

.icon-retangle {
  width: fit-content;
  background-color: #ff9300;
  border-radius: 3px;

  .p-icon-retangle {
    color: #ffffff;
    font-size: 0.8em;
    margin: 3px;
  }
}

.title-autor {
  color: #279ed8;
  font-size: 21px;
  font-weight: bold;
  margin: 0;
}

.function-autor {
  color: #707070;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.form-control::placeholder {
  font-size: 1rem;
  color: #aaa;
  left: 0;
  position: relative;
}

.magnifyingglass {
  position: relative;
  left: -15px;
  top: 5px;
}

@media (max-width: 1199.98px) {
  .mobile {
    display: none;
  }
  .text {
    justify-content: center;
    text-align: center;
  }
  .row {
    justify-content: center;
    text-align: center;
  }
  .circle img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .text-yolanda {
    position: relative;
    width: 260px;
    top: 25px;
    left: 0px;
  }

  .icon-retangle {
    width: fit-content;
    background-color: #ff9300;
    border-radius: 3px;
    margin: 0 auto;

    .p-icon-retangle {
      color: #ffffff;
      font-size: 0.8em;
      margin: 3px;
    }
  }
  .title-autor {
    position: relative;
    color: #279ed8;
    font-size: 21px;
    font-weight: bold;
    margin: 0;
  }
}

.align-center {
  justify-content: center;
  display: flex;
}

.teste1 {
  background-color: #ff9300;
}
</style>
