var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-wrap"},[_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.computer_and_mobile : "Computer and Mobile")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.social_listening : "Social Listening")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.monitor_consumers : "Monitor what consumers are saying about your brand and identify positive or negative comments.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Passive Data - Social Listening' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.mobile : "Mobile")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.geofencing : "Geofencing")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.know_audience_places : "Know the places your audience frequents and create a precise segmentation based on the locations.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Passive Data - Geofencing' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(2),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.computer_and_mobile : "Computer and Mobile")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.eye_tracking : "Eye Tracking")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.verify_local_point : "Verify which is the focal point of an advertisement, package, or image. Understand what draw your consumer's attention.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Passive Data - Eye Tracking' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(3),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.computer : "Computer")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.face_recognition : "Face Recognition")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions .detect_facial_emotions : "Detect the facial emotions an advertisement or package arises on your consumers.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Passive Data - Face Recognition' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(4),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.computer_and_mobile : "Computer and Mobile")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.cookies_and_tags : "Cookies & Tags")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.identify_individuals : "Identify the individuals exposed to an advertisement and send them a survey. Evaluate the impact of the message.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Passive Data - Cookies and Tags' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/shopping-cart.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/location-data.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/eye.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/target.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/cookies.svg"),"alt":""}})])
}]

export { render, staticRenderFns }