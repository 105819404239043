<template>
  <section class="py-5">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/health.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .communication_and_branding
                  : "Communication and Branding"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.brand_health
                  : "Brand Health"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .identify_positionings
                  : "Identify brand positionings that should have been enforced or modified. Optimize your communication efforts."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Brand Health' }
                }"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/rate.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .communication_and_branding
                  : "Communication and Branding"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .pre_test
                  : "Pre/post Communication Test"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .test_efficiency
                  : "Test the efficiency of an advertising campaign before launching it, and analyze the performance after airing it."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Pre Test' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/bus.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.indicators
                  : "Indicators"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .omnibus_survey
                  : "Omnibus Survey"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .obtain_information_in_one_market_research
                  : "Obtain information about different subjects in one market research. With low-cost budgets, it is possible to conduct omnibuses monthly."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Omnibus Survey' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/megaphone.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.indicators
                  : "Indicators"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.public_affairs
                  : "Public Affairs"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .comprehend_relationship
                  : "Comprehend the relationship your brand has with the target audience. Plan your efforts to build a closer brand-consumer relationship."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Public Affairs' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/graphic.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.indicators
                  : "Indicators"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ad_hoc_tracker
                  : "Ad hoc/Tracker"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .create_personalized_research
                  : "Create personalized research to meet the necessities of your business. Repeat it over time and monitor the results."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Ad hoc Tracker' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/ihut.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative.test
                  : "Tests"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.ihut
                  : "IHUT"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .send_physical_products
                  : "Send physical products to the home of a group of consumers and receive feedbacks about design and usability."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - IHUT' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/search.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative.test
                  : "Tests"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .concept_test
                  : "Concept Test"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .identify_necessities
                  : "Identify the necessities, wishes, and preferences of your consumers regarding new products."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Concept Test' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/bottle.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative.test
                  : "Tests"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.task_panel
                  : "Task Panel"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .create_tasks
                  : "Create tasks that consumers will accomplish by consumers and  comprehend their habits."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Task Performance' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/computer-quantative.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative.test
                  : "Tests"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .packing_and_product
                  : "Packaging and Product Tests"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.quantitative
                      .consult_consumer
                  : " Consult your consumer's opinions about the new packaging of a product and design changes. Meet their expectations e become a standout in the market."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Quantitative - Packaging and Product Tests' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  width: 288px;
  min-height: 390px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.1);
  padding: 40px 20px 25px 20px;
  margin-bottom: 40px;
  margin-left: calc(50% - 144px);
  .img-box {
    min-height: 84px;
  }
  .type {
    font-weight: 500;
    font-size: 0.625rem;
    color: #4f87da;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  h3 {
    font-weight: 700;
    font-size: 1.187rem;
    color: #3f4451;
    min-height: 44px;
  }
  .description {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #3f4451;
    min-height: 114px;
  }
  .getQuote a {
    font-weight: 600;
    font-size: 0.8rem;
    color: #4f87da;
  }
  .getQuote a:hover {
    text-decoration: none;
    color: #3e72c0;
  }
}
</style>
