<template>
  <section class="py-5 p-relative bg" id="features">
    <img src="@/assets/bg-landpage.svg" class="bg-lp" />
    <div class="container">
      <div class="row mt-2">
        <div class="col perks">
          <h3>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.buttons_and_inputs.benefits
                : "Benefits"
            }}
          </h3>
          <h4>
            {{
              $store.getters.trans
                ? $store.getters.trans.landpage.benefits_differentials
                : "Benefits, differentials, and advantages"
            }}
          </h4>
          <div class="text">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.gain_more_autonomy
                  : "Gain more autonomy to manage your projects"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="row second-row justify-content-end">
        <div class="col-xl-6 d-flex flex-wrap items">
          <div class="col-lg-5 col-xl-6 item">
            <img src="@/assets/quickness.png" alt="" />
            <h5>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.quickness
                  : "Quickness"
              }}
            </h5>
            <div class="text">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.using_survey_tools
                    : "Using OW Survey tools, the survey scripting will be fast and easy. By doing so, you can conclude our market research projects in a short time"
                }}
              </p>
            </div>
          </div>
          <div class="col-lg-5 col-xl-6 item mt-scripting">
            <img src="@/assets/convenience.png" alt="" />
            <h5>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.convenience
                  : "Convenience"
              }}
            </h5>
            <div class="text">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.you_can_easily
                    : "You can easily alter the flow or the logic after programming the questionnaire with AutoSurvey"
                }}
              </p>
            </div>
          </div>
          <div class="col-lg-5 col-xl-6 item mt">
            <img src="@/assets/scripting.svg" alt="" />
            <h5>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.autonomy
                  : "Autonomy"
              }}
            </h5>
            <div class="text">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.our_respondent_panel
                    : "Our respondent panel is profiled. Therefore it's possible to create samples using more than 200 data points, including health aspects, income, and consumer habits"
                }}
              </p>
            </div>
          </div>
          <div class="col-lg-5 col-xl-6 item mt">
            <img src="@/assets/cost.svg" alt="" />
            <h5>
              {{
                $store.getters.trans
                  ? $store.getters.trans.landpage.flexible_billing
                  : "Flexible billing"
              }}
            </h5>
            <div class="text">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.landpage.pay_only_for_what_used
                    : "Pay only for what you used. We don't charge license fees to use the platform, and you can pay in your local currency. Check our tailored bundled services"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col line"></div>
    </div>
    <div class="col-xl-6 pl-0 img-computer">
      <img
        src="@/assets/computer-landpage.svg"
        alt="computer"
        class="img-fluid"
      />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bg {
  background: #f7fafb;
  overflow: hidden;
}
.bg-lp {
  position: absolute;
  top: -130px;
  width: 100%;
}
.img-computer {
  position: absolute;
  bottom: 210px;
}
.perks {
  h3 {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    color: #666666;
  }
  h4 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    font-weight: 400;
    font-size: 0.875rem;
    color: #707070;
    text-align: center;
    width: 581px;
    span {
      font-weight: bold;
    }
  }
}
.second-row {
  margin-top: 90px;
}
.item {
  img {
    width: 109px;
    margin-left: -10px;
  }
  h5 {
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    margin: 15px 0 25px 0;
  }
  p {
    font-weight: 400;
    font-size: 0.875rem;
    color: #707070;
    margin-bottom: 0;
  }
}
.text {
  display: flex;
  justify-content: center;
}
.mt {
  margin-top: 40px;
}
.line {
  margin-top: 51px;
  border-bottom: 1px solid #ccdde5;
}
@media (max-width: 1199.98px) {
  .img-computer {
    display: none;
  }
  .items {
    justify-content: space-between;
  }
  .item {
    img {
      margin-left: calc(50% - 54.5px);
    }
    h5 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    li {
      text-align: center;
      &::marker {
        color: transparent;
      }
    }
  }
  .bg-lp {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .mt-scripting {
    margin-top: 40px;
  }
}

@media (max-width: 771.98px) {
  .item {
    p {
      width: 400px;
    }
  }
}
@media (max-width: 575.98px) {
  .second-row {
    margin-top: 30px;
  }
}
</style>
