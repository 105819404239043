<template>
  <section id="second" class="margin-section">
    <div class="container">
      <div class="row justify-content-center section4 pt-5 pb-5">
        <div class="col-md-6 col-lg-3">
          <div class="item">
            <div class="ball">
              <img src="@/assets/Frame-4.png" class="ball-1" alt="" />
            </div>
            <div class="text-ow">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products.ow_survey
                    : "OW Survey"
                }}
              </h4>
              <p v-if="$store.getters.lang === 'en'">
                An AI-enabled DIY platform filled with features to include
                automatic programming, rapid fielding, and advanced reporting.
              </p>
              <p v-if="$store.getters.lang === 'pt'">
                Plataforma DIY com inteligência artificial e funções que incluem
                programação automática, rápido envio ao campo e relatórios
                avançados.
              </p>
              <p v-if="$store.getters.lang === 'es'">
                Una plataforma DIY habilitada para IA con funciones que incluyen
                programación automática, campo rápido y reportes avanzados.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="item">
            <img src="@/assets/owfocus-ball.png" class="ball-2" alt="" />
            <div class="text-ow">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products.ow_focus
                    : "OW Focus"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .conduct_your_focus_groups
                    : "Conduct your focus groups on an appropriate online platform"
                }}.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="item">
            <img src="@/assets/owvoices-ball.png" class="ball-3" alt="" />

            <div class="text-ow">
              <h4>OW VOICES</h4>
              <div class="test">
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .create_personalized_communities
                      : "Create personalized communities and interact with them online"
                  }}.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="item">
            <img src="@/assets/ow-vision-ball.png" class="ball-4" alt="" />

            <div class="text-ow">
              <h4>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products.ow_vision
                    : "OW Vision"
                }}
              </h4>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .eye_tracking_and_facial_coding
                    : "Eye Tracking and Facial Coding for testing packaging design, shelf positioning, marketing campaigns, website UI and more."
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-between pt-5 pb-5">
        <div class="col-lg-5 survey pt-5">
          <div class="title pb-4">
            <img src="@/assets/survey.svg" class="img-survey" alt="" />
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products
                      .easy_to_use_diy_2
                  : "An easy-to-use DIY survey platform, with all resources to conduct your own market research"
              }}
            </p>
          </div>
          <div class="pb-4 pt-3">
            <div class="retangle">
              <div class="ball-0">
                <img
                  src="@/assets/inteligence.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .create_your_questionnaires
                    : "Create your questionnaires and program them using artificial intelligence"
                }}.
              </p>
            </div>
          </div>
          <div class="pb-4">
            <div class="retangle">
              <div class="ball-0">
                <img
                  src="@/assets/rapidresult.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle-midle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .access_the_respondent
                    : "Access the respondent panel and send your research to the field"
                }}.
              </p>
            </div>
          </div>
          <div class="pb-5">
            <div class="retangle">
              <div class="ball-0">
                <img src="@/assets/monitor.svg" class="img-magnifying" alt="" />
              </div>
              <p class="text-retangle-last">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .monitor_the_results
                    : "Monitor the results in real-time"
                }}.
              </p>
            </div>
          </div>
          <router-link :to="{ name: 'owsurvey' }" class="btn btn-survey">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.request_a_demo
                : "Request a Demo"
            }}
          </router-link>
          <a
            href="https://www.youtube.com/watch?v=MRgt5je7VPY"
            class="btn btn-survey"
            target="_blank"
            v-if="$store.getters.lang === 'en'"
          >
            SEE HOW IT WORKS
          </a>
          <a
            href="https://www.youtube.com/watch?v=h6dXR62xDfo"
            class="btn btn-survey"
            target="_blank"
            v-if="$store.getters.lang === 'es'"
          >
            VE CÓMO FUNCIONA
          </a>
          <a
            href="https://www.youtube.com/watch?v=KJdm_9FqLQE"
            class="btn btn-survey"
            target="_blank"
            v-if="$store.getters.lang === 'pt'"
          >
            VEJA COMO FUNCIONA
          </a>
        </div>
        <div class="col-lg-6 pt-5 pb-4">
          <img class="dots-1" src="@/assets/dots.svg" alt="Imagem" />
          <img
            class="img-fluid img-animacao"
            src="@/assets/gif-survey.gif"
            alt="Imagem"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.retangle {
  background: #ffffff;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  border-radius: 5080px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.text-retangle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.text-retangle-midle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.text-retangle-last {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
  padding: 10px;
}

.ball-0 {
  width: 60px;
  height: 60px;
  background: #4b83d5;
  border-radius: 50%;
  position: absolute;
}

.img-magnifying {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  transform: translate(-50%, -50%);
}

.text-ow {
  padding-top: 20px;
}

.margin-section {
  margin-top: 5px;
}

.img-survey-animated {
  position: relative;
}

.dots-1 {
  right: -15%;
  position: absolute;
  margin-top: -5%;
}

.img-ball {
  right: 10%;
  position: relative;
  margin-top: 19%;
}

.img-focus {
  right: 20%;
  top: 15%;
}

.img-animacao {
  margin-top: 50px;
  position: relative;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 10px;
}

@media (max-width: 991.98px) {
  .img-animacao {
    display: none;
  }
  .survey {
    text-align: center;
    margin-top: -100px;
  }
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
  .dots-1 {
    display: none;
  }
  .retangle {
    width: 70%;
    margin-left: calc(50% - 235.5px);
  }
  .text-ow {
    margin-bottom: 50px;
  }
}

.img-survey {
  padding-bottom: 5%;
}

.section4 .text h3 {
  font-weight: 700;
  font-size: 2rem;
  color: black;
}

.survey-p {
  font-size: 20px;
}

.item {
  display: flex;
}

.ball-1 {
  width: 120px;
  height: 120px;
  position: relative;
  top: -10px;
  border-radius: 100%;
}

.ball-2 {
  width: 120px;
  height: 120px;
  position: relative;
  top: -10px;
  border-radius: 100%;
}

.ball-3 {
  width: 120px;
  height: 120px;
  position: relative;
  top: -10px;
  border-radius: 100%;
}

.ball-4 {
  width: 120px;
  height: 120px;
  position: relative;
  top: -10px;
  border-radius: 100%;
}

.section4 .item p {
  color: #707070;
  font-size: 0.8rem;
}

.title {
  p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.title-focus {
  p {
    margin-bottom: 0;
    letter-spacing: 0.12em;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 14px;
  color: #3f4451;
  font-weight: bold;
}

.vertical-items {
  > div {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 25px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

.btn-survey {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4b83d5;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 22px 3px 22px;
  transition: all 0.3s ease;
  margin: 5px;
}

.btn-focus {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #4b83d5;
  font-size: 0.8rem;
  background: #ffffff;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  border: 1px solid #3a70c0;
  margin-left: 25px;
  transition: all 0.3s ease;
}

.btn-focus:hover {
  text-decoration: none;
  color: #ffffff;
  background: #3a70c0;
}

.btn-survey:hover {
  text-decoration: none;
  background: #246edd;
}

@media (max-width: 767.98px) {
  .retangle {
    width: 90%;
    margin-left: calc(50% - 205.5px);
  }
  .item {
    display: block;
    img {
      margin-left: calc(50% - 60px);
    }
  }

  .text-ow {
    margin-left: 0;
    text-align: center;
  }
}

@media (max-width: 575.98px) {
  .btn-focus {
    margin-left: 10px;
  }
  .retangle {
    width: 100%;
    margin-left: 0px;
  }
}
</style>
