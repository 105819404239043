<template>
  <section class="py-5 p-relative">
    <div class="container">
      <div class="row">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="col-lg-5 firstCol">
            <div class="abTest mt-5">
              <h1>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.post_test
                    : "Post-Test"
                }}
              </h1>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.details_post_test
                        .monitor_relevance_of_your_advertisement
                    : "Monitor the relevance of your advertisement campaign to your brand memorability growth. With the Post-Test method, you will understand all the KPIs of your campaign and have the best cost-benefit ratio on the market."
                }}.
              </p>
              <div class="d-flex btnSurveys">
                <router-link :to="{ name: 'contact' }" class="btnGet">
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.research_solutions.get_started
                      : "Get Started"
                  }}
                </router-link>
              </div>
            </div>
            <div class="benefits">
              <h2>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.details_A_B_tests.benefits
                    : "benefits"
                }}
              </h2>
              <ul>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_brand_health.monitor_health
                      : "Monitor the health and equity of your brand."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_A_B_tests
                          .improve_engagement
                      : "Improve engagement."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_A_B_tests.reduce_the_risks
                      : "Reduce the risks of an investment."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_A_B_tests
                          .obtain_results_faster
                      : "Obtain the results faster."
                  }}
                </li>
              </ul>
              <h2 class="points">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.details_A_B_tests.key_performance
                    : " Key Performance Indicator"
                }}
              </h2>
              <ul>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_pre_test
                          .pre_test_key_metrics
                      : "Heat Maps, likes, dislikes, brand memorability, KPI relevance, image attributes, buyers intention and usage intention."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_A_B_tests.study_topics
                      : "Study topics."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_A_B_tests.target_audience
                      : "Target audience profile."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_A_B_tests.media_touchpoint
                      : "Media touchpoint."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_post_test
                          .spontaneous_memory
                      : "Spontaneous memory of a brand advertisement."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_post_test
                          .spontaneous_memory_main_message
                      : " Spontaneous memory of the main message of a brand."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_post_test
                          .storyboard_and_video
                      : "Storyboard and video stimulus."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_post_test.stimulated_memory
                      : "Aided awareness"
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_post_test.kpi_relevance
                      : "KPIs relevance."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_pre_test
                          .communication_kpis_relevance
                      : "Communication KPIs relevance."
                  }}
                </li>
                <li>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.details_pre_test
                          .communication_attributes
                      : "communication_attributes"
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-5 secondCol">
            <img src="@/assets/details-pos-test.png" alt="" class="img-fluid" />
            <h2 class="outputs">
              {{
                $store.getters.trans
                  ? $store.getters.trans.details_A_B_tests.outputs
                  : "Outputs"
              }}
            </h2>
            <p class="mt-3 text-outputs">
              {{
                $store.getters.trans
                  ? $store.getters.trans.details_A_B_tests.our_reporting_layouts
                  : "Our reporting layouts have a modern design making them easy to understand"
              }}
            </p>
            <img
              src="@/assets/img-outputs.png"
              alt=""
              class="outputsImg p-relative"
            />
          </div>
        </div>
      </div>
    </div>
    <img class="bg" src="@/assets/bgFourth.svg" />
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  margin-top: 150px;
}
.firstCol {
  margin-left: 48px;
}
.abTest {
  h1 {
    font-weight: 700;
    font-size: 2.1875rem;
    color: #000;
    margin-bottom: 40px;
  }
  p {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #666666;
  }
}
.btnSurveys {
  .btnGet {
    text-decoration: none;
    border-color: #eb858c;
    background: #eb858c;
    color: #fff;
    height: 30px;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.75rem;
    border-radius: 3px;
    margin-top: 20px;
    box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  }
  .btnGet:hover {
    border-color: #d8636b;
    background: #d8636b;
    color: #fff;
  }
}
.benefits {
  margin-top: 70px;
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    color: #000;
    margin-bottom: 20px;
  }
  ul {
    padding-left: 25px;
  }
  li {
    font-weight: 400;
    font-size: 0.8rem;
    color: #666666;
    margin-bottom: 5px;
  }
  li::marker {
    color: #4c84d7;
  }
}
.points {
  margin-top: 48px;
}
.secondCol {
  margin-top: 10px;
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    color: #000;
  }
  .metrics {
    margin: 86px 0 20px 0;
  }
  p {
    font-weight: 400;
    font-size: 0.8rem;
    color: #666666;
  }
  .text-outputs {
    font-size: 0.8rem;
  }
}
.bg {
  position: absolute;
  right: -55px;
  bottom: 0px;
}

.availability {
  margin: 48px 0 25px 0;
}

select {
  cursor: pointer;
  font-weight: 300;
  font-size: 0.9rem;
  border-radius: 3px;
  width: 256px;
  height: 36px;
  background-color: #eef2f5 !important;
  border: none;
  padding: 5px 5px 5px 15px;
  color: #9c9c9c;
  appearance: none;
  background: url(../../assets/arrow-down.svg) no-repeat right #666;
  background-repeat: no-repeat;
  background-size: 17px 10px;
  background-position: right 10px top 14px;
}

select:focus {
  outline: none;
}

#otherCountries {
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 15px;
}
.outputs {
  margin-top: 48px;
}
.outputsImg {
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 1199.98px) {
  .outputsImg {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  section {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .firstCol {
    margin: 40px 0 0 0px;
  }
  .img-fluid {
    display: none;
  }
  .abTest {
    text-align: center;
  }
  .btnSurveys {
    justify-content: center;
  }
  .benefits {
    margin-top: 70px;
    h2 {
      text-align: center;
    }
    ul {
      padding-left: 0;
      li {
        text-align: center;
        &::marker {
          color: transparent;
        }
      }
    }
  }
  .points {
    margin-top: 59px;
  }
  .metrics {
    margin-top: 59px !important;
  }
  .secondCol {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    .chooseCountry {
      display: flex;
      justify-content: center;
    }
    .text-outputs {
      font-size: 0.9rem;
    }
  }
  #otherCountries {
    width: 100%;
    text-align: center;
  }
  .bg {
    display: none;
  }
  .availability {
    margin-top: 59px;
  }
  select {
    width: 80%;
  }
  .outputs {
    margin-top: 59px;
  }
  .outputsImg {
    margin-top: 20px;
    margin-left: calc(50% - 25.5px);
  }
}
@media (max-width: 575.98px) {
  .abTest {
    margin-top: -20px !important;
  }
  #otherCountries {
    width: 255px;
    text-align: center;
    margin-left: calc(50% - 127.5px);
  }
}
</style>
