<template>
  <section class="py-3 second-section" id="second">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-lg-3 item">
          <div class="border-img">
            <div :class="{ active: panelists }" class="panelists">
              <img
                :class="{ active: !panelists }"
                src="@/assets/panelist-blue.png"
                alt="Panelists"
              />
            </div>
          </div>
          <p :class="{ active: panelists }" class="pt-4 panelists-text">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.the_largest_and_most
                : "The respondent panel is the largest and most representative of Latin-American and US Hispanic populations"
            }}.
          </p>
        </div>
        <div class="col-md-6 col-lg-3 item">
          <div class="border-img">
            <div :class="{ active: diy }" class="diy">
              <img
                :class="{ active: !diy }"
                src="@/assets/diy-pink.png"
                alt="DIY"
              />
            </div>
          </div>
          <p :class="{ active: diy }" class="pt-4 diy-text">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.diy_platform
                : "Easy-to-use DIY survey platform"
            }}.
          </p>
        </div>
        <div class="col-md-6 col-lg-3 item">
          <div class="border-img">
            <div :class="{ active: endToEnd }" class="end-to-end">
              <img
                :class="{ active: !endToEnd }"
                src="@/assets/end-to-end-blue.png"
                alt="End to end"
              />
            </div>
          </div>
          <p :class="{ active: endToEnd }" class="pt-4 end-to-end-text">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.end_to_end
                : "End-to-end solutions From questionnaire design to the analysis of the results"
            }}.
          </p>
        </div>
        <div class="col-md-6 col-lg-3 item">
          <div class="border-img">
            <div :class="{ active: solutions }" class="solutions">
              <img
                :class="{ active: !solutions }"
                src="@/assets/solutions-orange.png"
                alt="Solutions"
              />
            </div>
          </div>
          <p :class="{ active: solutions }" class="pt-4 solutions-text">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.more_than_50
                : "More than 50 solutions for online market research"
            }}.
          </p>
        </div>
      </div>
      <div class="row align-items-center pt-5 mt">
        <div class="col-lg-7">
          <img
            class="img-fluid ss-home-img"
            src="@/assets/ss-home-img.png"
            alt="Imagem"
          />
        </div>
        <div class="col-lg-5">
          <div class="title pb-4">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.hear
                  : "Hear who matters the most"
              }}
            </p>
            <h2>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.panel_with_respondents
                  : "Panel with 10 million respondents in 20 countries"
              }}
            </h2>
          </div>
          <div class="vertical-items pb-5">
            <div>
              <img src="@/assets/sample-only.svg" alt="" />
              <h3>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.sample_solutions
                    : "Sample Solutions"
                }}
              </h3>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.create_research
                    : "Create your research, choose a sample, and obtain the answers in the panel"
                }}.
              </p>
            </div>
            <div>
              <img src="@/assets/advanced-panelist.svg" alt="" />
              <h3>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.advanced_respondent
                    : "Advanced respondent profiling"
                }}
              </h3>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.more_than_200
                    : "More than 200 questions are used to profile each respondent and to create accurate samples for each segment"
                }}.
              </p>
            </div>
            <div>
              <img src="@/assets/unique-recruitment.svg" alt="" />
              <h3>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.unique_recruitment
                    : "Unique recruitment model"
                }}
              </h3>
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.exclusive_recruitment
                    : "The exclusive recruitment model allows the panel to grow and provides more respondents to conduct market researches"
                }}.
              </p>
            </div>
          </div>
          <div class="panelbook d-flex justify-content-center">
            <router-link :to="{ name: 'panelbook' }" class="btn btn-panelbook">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.request_panelbook
                  : "Request panel book"
              }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      panelists: false,
      diy: false,
      endToEnd: false,
      solutions: false
    };
  }
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
  }
  p {
    color: #707070;
    text-align: center;
    font-size: 0.81rem;
    // transition: all 0.3s ease;
    // &.panelists-text.active {
    //   color: #3a70c0;
    //   text-shadow: 0px 4px 4px rgba(76, 132, 215, 0.15);
    // }
    // &.diy-text.active {
    //   color: #e77c85;
    //   text-shadow: 0px 4px 4px rgba(235, 133, 140, 0.15);
    // }
    // &.end-to-end-text.active {
    //   color: #2d6187;
    //   text-shadow: 0px 4px 4px rgba(45, 97, 135, 0.15);
    // }
    // &.solutions-text.active {
    //   color: #e07024;
    //   text-shadow: 0px 4px 4px rgba(255, 178, 125, 0.15);
    // }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.8;
  }
}

.title {
  p {
    margin-bottom: 0;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: #666666;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.vertical-items {
  > div {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 25px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin: 0.625rem 0;
    }
    p {
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

.btn-panelbook {
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #285b84;
  box-shadow: 3px 3px 8px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
}

.btn-panelbook:hover {
  background: #19466b;
}

@media (max-width: 991px) {
  .mt {
    margin-top: 40px;
  }
  .ss-home-img {
    display: none;
  }
  .panelbook {
    display: flex;
    justify-content: center;
  }

  .title {
    margin: 70px 0 30px 0;
    p {
      text-align: center;
      margin-bottom: 5px;
    }
    h2 {
      color: #000000;
      font-size: 1.3rem;
      text-align: center;
    }
  }

  .item {
    margin-top: 15px;
  }

  .vertical-items {
    margin-top: 30px;
    div {
      img {
        height: 25px;
        margin-bottom: 20px;
        margin-left: calc(50% - 12.5px);
      }
      h3 {
        text-align: center;
        margin-bottom: 5px;
      }
      p {
        text-align: center;
        width: 500px;
        margin-left: calc(50% - 250px);
      }
    }
  }
}

@media (max-width: 766px) {
  .mt {
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .vertical-items {
    p {
      width: 300px !important;
      margin-left: calc(50% - 150px) !important;
    }
  }
}

.second-section {
  margin-top: 20px;
}

@media (min-width: 1400px) {
  .second-section {
    margin-top: 40px;
  }
}
</style>
