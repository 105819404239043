<template>
  <section>
    <div class="container pb-3">
      <vue-programmatic-invisible-google-recaptcha
        ref="invisibleRecaptcha1"
        :sitekey="'6Le2gb0aAAAAAC-lBxH52eqNr1duvsYd2_ymIGip'"
        :elementId="'invisibleRecaptcha1'"
        :badgePosition="'left'"
        :showBadgeMobile="false"
        :showBadgeDesktop="true"
        @recaptcha-callback="recaptchaCallback"
      ></vue-programmatic-invisible-google-recaptcha>
      <div class="row justify-content-between" v-if="careers.length">
        <div class="row justify-content-center">
          <div class="pt-5 pb-3 col-md-10 title-jobs">
            <h2>
              {{
                $store.getters.trans
                  ? $store.getters.trans.careers_page.join_our_team
                  : "Join Our Team!"
              }}
            </h2>
            <p class="paragraph-title-jobs">
              {{
                $store.getters.trans
                  ? $store.getters.trans.careers_page.at_offerwise
                  : "At Offerwise, we are always looking for new talents to join our team. If you want to apply for one of our vacancies, click on the links below"
              }}.
            </p>
          </div>
        </div>
        <div class="col-12" v-for="(carreer, index) in careers" :key="index">
          <div v-if="$store.getters.lang === 'en'">
            <div class="title pb-3">
              <p>{{ carreer.title_en }}</p>
            </div>
            <div class="vertical-items jobs-p pb-5">
              <div class="retangle-location d-flex">
                <div
                  v-if="
                    carreer.location.city_en !== '' &&
                      carreer.location.country_en !== 'none'
                  "
                  class="icon-retangle d-flex"
                >
                  <img src="@/assets/location.svg" class="location" alt="" />
                  <p class="p-icon-retangle text-nowrap">
                    {{ carreer.location.city_en }},
                    {{ carreer.location.country_en }}
                  </p>
                </div>
                <div
                  v-if="carreer.location.city_en === ''"
                  class="icon-retangle d-flex"
                >
                  <img src="@/assets/location.svg" class="location" alt="" />
                  <p class="p-icon-retangle text-nowrap">
                    {{ carreer.location.country_en }}
                  </p>
                </div>
              </div>
              <div class="description-careers">
                <p class="pre-formatted" v-html="carreer.description_en"></p>
              </div>
            </div>
            <div
              class="form"
              @click="openForm(`form${index}`)"
              :id="`form${index}`"
            >
              <button class="btn btn-careers-second">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.apply
                    : "Apply"
                }}
              </button>
              <div class="retangle" v-on:submit.prevent="submitForm">
                <input
                  type="text"
                  class="form-control"
                  :id="`name${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.name
                      : 'Name'
                  "
                />
                <input
                  type="email"
                  class="form-control"
                  :id="`email${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.email
                      : 'E-mail'
                  "
                />
                <input
                  type="text"
                  class="form-control"
                  :id="`linkedin${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.linkedin
                      : 'LinkedIn link'
                  "
                />
                <div class="uploadFile">
                  <label :id="`labelFile-${index}`" :for="`file${index}`">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.choose_file
                        : "Choose file"
                    }}</label
                  >
                  <p class="textFile">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.cv_file
                        : "Upload Resume"
                    }}
                  </p>
                </div>
                <input
                  @change="uploadCV(`labelFile-${index}`, `file${index}`)"
                  style="display: none"
                  type="file"
                  :id="`file${index}`"
                />
                <button
                  v-if="!loader"
                  class="btn btn-careers"
                  @click="applyJob(index, carreer.id)"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.apply
                      : "Apply"
                  }}
                </button>
              </div>
            </div>

            <div class="justify-content-center">
              <img
                src="@/assets/line-communities.svg"
                class="line-jobs"
                alt=""
              />
            </div>
          </div>

          <div v-if="$store.getters.lang === 'es'">
            <div class="title pb-3">
              <p>{{ carreer.title_es }}</p>
            </div>
            <div class="vertical-items jobs-p pb-5">
              <div class="retangle-location d-flex">
                <div
                  v-if="
                    carreer.location.city_es !== '' &&
                      carreer.location.country_es !== 'none'
                  "
                  class="icon-retangle d-flex"
                >
                  <img src="@/assets/location.svg" class="location" alt="" />
                  <p class="p-icon-retangle text-nowrap">
                    {{ carreer.location.city_es }},
                    {{ carreer.location.country_es }}
                  </p>
                </div>
                <div
                  v-if="carreer.location.city_es === ''"
                  class="icon-retangle d-flex"
                >
                  <img src="@/assets/location.svg" class="location" alt="" />
                  <p class="p-icon-retangle text-nowrap">
                    {{ carreer.location.country_es }}
                  </p>
                </div>
              </div>
              <div class="description-careers">
                <p class="pre-formatted" v-html="carreer.description_es"></p>
              </div>
            </div>
            <div
              class="form"
              @click="openForm(`form${index}`)"
              :id="`form${index}`"
            >
              <button class="btn btn-careers-second">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.apply
                    : "Apply"
                }}
              </button>
              <div class="retangle" v-on:submit.prevent="submitForm">
                <input
                  type="text"
                  class="form-control"
                  :id="`name${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.name
                      : 'Name'
                  "
                />
                <input
                  type="email"
                  class="form-control"
                  :id="`email${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.email
                      : 'E-mail'
                  "
                />
                <input
                  type="text"
                  class="form-control"
                  :id="`linkedin${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.linkedin
                      : 'LinkedIn link'
                  "
                />
                <div class="uploadFile">
                  <label :id="`labelFile-${index}`" :for="`file${index}`">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.choose_file
                        : "Choose file"
                    }}</label
                  >
                  <p class="textFile">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.cv_file
                        : "Upload Resume"
                    }}
                  </p>
                </div>
                <input
                  @change="uploadCV(`labelFile-${index}`, `file${index}`)"
                  style="display: none"
                  type="file"
                  :id="`file${index}`"
                />
                <button
                  v-if="!loader"
                  class="btn btn-careers"
                  @click="applyJob(index, carreer.id)"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.apply
                      : "Apply"
                  }}
                </button>
              </div>
            </div>

            <div class="justify-content-center">
              <img
                src="@/assets/line-communities.svg"
                class="line-jobs"
                alt=""
              />
            </div>
          </div>

          <div v-if="$store.getters.lang === 'pt'">
            <div class="title pb-3">
              <p>{{ carreer.title_pt }}</p>
            </div>
            <div class="vertical-items jobs-p pb-5">
              <div class="retangle-location d-flex">
                <div
                  v-if="
                    carreer.location.city_pt !== '' &&
                      carreer.location.country_pt !== 'none'
                  "
                  class="icon-retangle d-flex"
                >
                  <img src="@/assets/location.svg" class="location" alt="" />
                  <p class="p-icon-retangle text-nowrap">
                    {{ carreer.location.city_pt }},
                    {{ carreer.location.country_pt }}
                  </p>
                </div>
                <div
                  v-if="carreer.location.city_pt === ''"
                  class="icon-retangle d-flex"
                >
                  <img src="@/assets/location.svg" class="location" alt="" />
                  <p class="p-icon-retangle text-nowrap">
                    {{ carreer.location.country_pt }}
                  </p>
                </div>
              </div>
              <div class="description-careers">
                <p class="pre-formatted" v-html="carreer.description_pt"></p>
              </div>
            </div>
            <div
              class="form"
              @click="openForm(`form${index}`)"
              :id="`form${index}`"
            >
              <button class="btn btn-careers-second">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.menu_contact.apply
                    : "Apply"
                }}
              </button>
              <div class="retangle" v-on:submit.prevent="submitForm">
                <input
                  type="text"
                  class="form-control"
                  :id="`name${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.name
                      : 'Name'
                  "
                />
                <input
                  type="email"
                  class="form-control"
                  :id="`email${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.email
                      : 'E-mail'
                  "
                />
                <input
                  type="text"
                  class="form-control"
                  :id="`linkedin${index}`"
                  :placeholder="
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.linkedin
                      : 'LinkedIn link'
                  "
                />
                <div class="uploadFile">
                  <label :id="`labelFile-${index}`" :for="`file${index}`">{{
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.choose_file
                      : "Choose file"
                  }}</label>
                  <p class="textFile">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.menu_contact.cv_file
                        : "Upload Resume"
                    }}
                  </p>
                </div>
                <input
                  @change="uploadCV(`labelFile-${index}`, `file${index}`)"
                  style="display: none"
                  type="file"
                  :id="`file${index}`"
                />
                <button
                  v-if="!loader"
                  class="btn btn-careers"
                  @click="submitButtonClicked(index, carreer.id)"
                >
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.menu_contact.apply
                      : "Apply"
                  }}
                </button>
              </div>
            </div>

            <div class="justify-content-center">
              <img
                src="@/assets/line-communities.svg"
                class="line-jobs"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="donut">
          <img src="@/assets/donut-careers.svg" class="img-donut" alt="" />
        </div>
        <div class="donut-2" style="repeat-y: height: 45px;">
          <div class="ball"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { api } from "@/services.js";
export default {
  data() {
    return {
      careers: [],
      loader: false,
      careerIndex: 0,
      careerId: ""
    };
  },
  methods: {
    getCareers() {
      api.get("jobs").then(r => {
        this.careers = r.data;
      });
    },

    applyJob(index, id) {
      const name = document.querySelector(`#name${index}`).value;
      const email = document.querySelector(`#email${index}`).value;
      const linkedin = document.querySelector(`#linkedin${index}`).value;
      const file = document.querySelector(`#file${index}`).files;

      if (!this.loader && name !== "" && email !== "") {
        this.loader = true;

        this.$gtag.event("conversion", {
          send_to: "AW-1072735680/U3xwCJi7is0BEMDLwv8D"
        });

        this.$gtag.event("conversion", {
          send_to: "AW-404271196/mL-hCKvXvskZENzg4sAB"
        });

        const data = new FormData();
        data.append("type", "jobs");
        data.append("name", name);
        data.append("email", email);
        data.append("linkedin", linkedin);
        data.append("jobId", id);
        if (file.length) {
          data.append("cv", file[0]);
        }
        api
          .post("mail/jobs", data, {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(r => {
            if (r.status === 200) {
              document.querySelector(`#name${index}`).value = "";
              document.querySelector(`#email${index}`).value = "";
              document.querySelector(`#linkedin${index}`).value = "";
              document.querySelector(`#file${index}`).value = "";
              const itemLabel = document.querySelector(`#labelFile-${index}`);
              itemLabel.innerText = "Choose File";
              document
                .querySelector(`#form${index}`)
                .classList.remove("active");
              this.$toast.open("Successfully sent!");
            }
            this.loader = false;
          })
          .catch(() => {
            this.$toast.open({
              message: "Something went wrong!",
              type: "error"
            });
            this.loader = false;
          });
      }
    },
    uploadCV(label, input) {
      const itemLabel = document.querySelector(`#${label}`);
      const itemInput = document.querySelector(`#${input}`);
      const name = itemInput.files[0].name;
      if (name.length > 8)
        itemLabel.innerText =
          name
            .split("")
            .splice(0, 8)
            .join("") + "...";
      else itemLabel.innerText = name;
    },
    openForm(element) {
      const item = document.querySelector(`#${element}`);
      item.classList.add("active");
      this.outsideClick(item, ["click"], () => {
        item.classList.remove("active");
      });
    },
    outsideClick(element, events, callback) {
      const html = document.documentElement;
      const outside = "data-outside";
      if (!element.hasAttribute(outside)) {
        events.forEach(userEvent => {
          setTimeout(() => {
            html.addEventListener(userEvent, () => {
              if (!element.contains(event.target)) {
                element.removeAttribute(outside);
                events.forEach(userEvent => {
                  html.removeEventListener(userEvent, this.handleOutsideClick);
                });
                callback();
              }
            });
          });
        });
        element.setAttribute(outside, "");
      }
    },
    submitButtonClicked(index, id) {
      this.careerIndex = index;
      this.careerId = id;
      this.$refs.invisibleRecaptcha1.execute();
    },

    recaptchaCallback() {
      this.applyJob(this.careerIndex, this.careerId);
    },
    init() {
      this.getCareers();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.title-jobs {
  margin-bottom: 50px;
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    color: #000;
    text-align: center;
  }
  p {
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-top: 15px;
  }
}

.pmp {
  position: relative;
}

.form {
  display: flex;
  width: 200px;
  .retangle {
    display: none;
    overflow: hidden;
    flex-wrap: nowrap !important;
  }
  &.active {
    width: 100%;
    animation: expand 0.5s;
    > button {
      display: none;
    }
    .retangle {
      display: flex;
    }
  }
}

@keyframes expand {
  0% {
    opacity: 0;
    width: 200px;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

.retangle {
  border-radius: 33px;
  display: flex;
  padding: 1px;
  border: 1px solid #ccdde5;
  width: 100%;
}

.retangle-location {
  border-radius: 33px;
  position: relative;
  display: flex;
  padding: 1px;
}

input,
.uploadFile {
  display: flex;
  overflow: hidden;
  width: 340px;
  height: 35px;
  border: none;
  border-radius: 33px;
  margin: 7px;
  max-width: 225px;
  color: #9c9c9c;
  background-color: #eef2f5;

  ::placeholder {
    color: #2d6187;
    font-size: 0em;
  }
}

.uploadFile {
  label {
    background: #ffffff;
    border: 1px solid #7d9bad;
    font-size: 0.75rem;
    padding: 3px 5px 5px 5px;
    margin: 5px 0 5px 12px;
    cursor: pointer;
  }
}

.form-control {
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
}

.ellipse-2 {
  position: absolute;
  bottom: -480px;
  width: 10px;
}

.ellipse-jobs {
  position: absolute;
  bottom: -480px;
  width: 10px;
}

.ellipse-jobs-2 {
  position: absolute;
  bottom: -54px;
  right: 0;
}

.line-jobs {
  align-items: center;
  padding-bottom: 5%;
  width: 100%;
  margin-top: 56px;
}

.text-ow {
  margin-left: 10px;
}

.jobs-p {
  font-size: 20px;
}

.item {
  display: flex;
}

.title {
  p {
    margin-bottom: 0;
    letter-spacing: 1px;
    color: #202020;
    font-weight: bold;
    font-size: 24px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.pre-formatted {
  white-space: pre;
  white-space: pre-line;
}

.vertical-items {
  > div {
    margin-bottom: 20px;
    margin-right: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 22px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

.btn-careers {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.9rem;
  background: #3a70c0;
  box-shadow: 2px 2px 9px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  padding: 1px 35px 1px 35px;
  margin: 10px;
  transition: all 0.3s ease;
}

.btn-btn-careers:hover {
  text-decoration: none;
  background: #067ed4;
}

.btn-careers-second {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.9rem;
  background: #3a70c0;
  box-shadow: 2px 2px 9px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  padding: 1px 35px 1px 35px;

  transition: all 0.3s ease;
}

.btn-btn-careers-second:hover {
  text-decoration: none;
  background: #067ed4;
}

@media (max-width: 1199.98px) {
  .row {
    text-align: center;
  }
  .test {
    text-align: center;
  }
  .icon-retangle {
    align-items: center;
  }
  .retangle {
    justify-content: center;
  }
  .retangle-location {
    justify-content: center;
  }
  .job-description-container {
    list-style: none;
  }
  .icon-retangle .p-icon-retangle[data-v-1d474236] {
    color: #ffffff;
    font-size: 0.7em;
    margin-top: 0px;
    margin-right: 6px;
  }
  .ellipse-jobs {
    display: none;
  }
  .ellipse-jobs-2 {
    display: none;
  }
  @keyframes expand {
    0% {
      opacity: 0;
      height: 20px;
    }
    100% {
      opacity: 1;
      height: 33%;
    }
  }
  .form {
    display: flex;
    width: 200px;
    justify-content: center;
    margin: 0 auto;
    .retangle {
      display: none;
      max-width: 41%;
      overflow: hidden;
      flex-wrap: wrap !important;
      border: none;
    }
    &.active {
      width: 100%;
      animation: expand 0.5s;
      > button {
        display: none;
      }
      .retangle {
        display: flex;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .donut {
    display: none;
  }

  .title-jobs {
    margin-bottom: 50px;
  }

  .donut-2 {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .form {
    display: flex;
    width: 200px;
    justify-content: center;
    margin: 0 auto;
    .retangle {
      display: none;
      max-width: 70%;
      overflow: hidden;
      flex-wrap: wrap !important;
      border: none;
    }
  }
}

.icon-retangle {
  background-color: #ff9300;
  border-radius: 3px;

  .p-icon-retangle {
    color: #ffffff;
    font-size: 0.7em;
    margin-top: 3px;
    margin-right: 6px;
  }
}

.location {
  margin: 3px;
}

.textFile {
  margin: 8px;
  font-size: 0.8em;
  color: #727272;
}

.donut {
  position: absolute;
  left: 0px;
}

.donut-2 {
  position: absolute;
  margin-top: 98px;
  right: 0px;
}

.ball {
  width: 65px;
  height: 65px;
  color: red;
  background: #f7f8fb;
  border-radius: 50%;
}

.img-donut {
  width: 70%;
}
</style>
