var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pt-3 pb-2"},[_c('div',{staticClass:"container",attrs:{"if":"partners.length"}},[_c('div',{staticClass:"item mb-5"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.partner_associations : "Partner associations")+" ")]),_c('carousel',{attrs:{"perPage":1,"perPageCustom":[
          [576, 2],
          [767, 3],
          [992, 4],
          [1024, 4],
          [1200, 5]
        ],"navigationEnabled":true,"paginationEnabled":false,"navigation-next-label":"","navigation-prev-label":""}},[_vm._l((_vm.partners),function(partners,index){return _c('slide',{key:index},[_c('img',{attrs:{"src":partners.logo,"alt":""}})])}),_c('slide',[_c('img',{staticClass:"apeim",attrs:{"src":require("@/assets/logo-AIM.svg"),"alt":""}})])],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }