<template>
  <section class="bg p-relative">
    <div class="img-bg"></div>
    <div class="container">
      <div class="row justify-content-center" v-if="testimonials.length">
        <div class="col-10">
          <b-carousel id="carousel-fade" :interval="10000" fade indicators>
            <b-carousel-slide
              caption=""
              class="carousel"
              v-for="(testimonials, index) in testimonials"
              :key="index"
            >
              <div class="col">
                <img
                  :src="testimonials.logo_image"
                  alt="Logo Mondaléz"
                  class="a"
                />
              </div>
              <img
                src="@/assets/quotation-marks.svg"
                class="img-fluid quotationMarks"
              />
              <div class="d-flex justify-content-center clientsSay">
                <h3>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .what_clients_have_to_say
                      : "What the clients have to say about Offerwise"
                  }}
                </h3>
              </div>
              <h4>
                {{ testimonials.client_name }}
              </h4>
              <h5>{{ testimonials.occupation }}</h5>
              <p v-if="$store.getters.lang === 'en'">
                {{ testimonials.testimonial_en }}
              </p>
              <p v-if="$store.getters.lang === 'es'">
                {{ testimonials.testimonial_es }}
              </p>
              <p v-if="$store.getters.lang === 'pt'">
                {{ testimonials.testimonial_pt }}
              </p>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  data() {
    return {
      testimonials: []
    };
  },
  methods: {
    getTestimonials() {
      api.get("testimonial").then(r => {
        this.testimonials = r.data;
      });
    },
    init() {
      this.getTestimonials();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
section {
  height: 547px;
}
.bg {
  background: linear-gradient(182.72deg, #f4f8fc 26.73%, #51869e 125.09%);
  overflow: hidden;
}

.img-bg {
  width: 5400px;
  height: 5400px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -5300px;
  left: calc(50% - 2700px);
  z-index: 0;
}

img {
  position: absolute;
  top: -230px;
  left: calc(50% - 93px);
  width: 200px;
  height: 200px;
}

.clientsSay {
  h3 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.12em;
    color: #666666;
    margin-bottom: 50px;
    text-align: center;
  }
}
.carousel-item,
.carousel-inner {
  height: 487px;
}

.carousel {
  margin-bottom: 50px;
  h4 {
    font-weight: 700;
    font-size: 1rem;
    color: #2d6187;
    text-align: center;
  }
  h5 {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #666666;
    text-align: center;
    margin-bottom: 35px;
  }
  p {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #2d6187;
    text-align: center;
    margin-bottom: 10px;
  }
}
.quotationMarks {
  display: none;
  /*position: absolute;
  z-index: -1;
  left: -28px;
  top: 90px;*/
}

@media (max-width: 1199.98px) {
  section {
    height: 560px;
  }

  .quotationMarks {
    display: none;
    /*left: -10px;
    top: 103px;
    width: 95px;*/
  }

  .logo {
    img {
      top: -200px;
    }
  }

  .carousel-item,
  .carousel-inner {
    height: 500px;
  }
}

@media (max-width: 991.98px) {
  section {
    height: 600px;
  }

  .carousel-item,
  .carousel-inner {
    height: 540px;
  }

  .clientsSay {
    h3 {
      margin-bottom: 50px;
    }
  }

  .quotationMarks {
    display: none;
    /*left: -25px;
    top: 105px;
    width: 95px;*/
  }
}

@media (max-width: 767.98px) {
  section {
    height: 660px;
  }
  .carousel-item,
  .carousel-inner {
    height: 600px;
  }

  .quotationMarks {
    display: none;
    /*left: -21px;
    top: 105px;
    width: 95px;*/
  }
}

@media (max-width: 575.98px) {
  section {
    height: 760px;
  }

  .carousel-item,
  .carousel-inner {
    height: 630px;
  }

  .logo {
    img {
      top: -205px;
    }
  }

  .quotationMarks {
    display: none;
  }
  .img-bg {
    width: 900px;
    height: 900px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: -800px;
    left: calc(50% - 450px);
    z-index: 0;
  }
}
@media (min-width: 1400px) {
  .img-bg {
    width: 9000px;
    height: 9000px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: -8900px;
    left: calc(50% - 4500px);
    z-index: 0;
  }
}
@media (min-width: 1400px) {
  .img-bg {
    width: 11572px;
    height: 11572px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: -11472px;
    left: calc(50% - 5786px);
    z-index: 0;
  }
}
</style>
