var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"itens-blog"},[_c('div',{staticClass:"d-flex flex-wrap pb-5"},[_c('div',{staticClass:"dropdown-blog mt-4 p-relative",attrs:{"id":"dropBlog"}},[_c('a',{on:{"click":function($event){return _vm.handleDrop('dropBlog')}}},[_c('strong',[_vm._v("Order by: ")]),_vm._v(" Most Recent")]),_vm._m(0)])]),_c('div',{staticClass:"d-flex flex-wrap box-blog"},[_c('div',{staticClass:"col-md-4 mb-4"},[_c('a',{staticClass:"readmore",on:{"click":function($event){return _vm.$router.push('/openmatter')}}},[_vm._m(1)])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drop-blog-item"},[_c('p',[_vm._v("Most Recent")]),_c('div',{staticClass:"triangle"},[_c('div',{staticClass:"triangleUp"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('img',{staticClass:"img-blog flex-wrap",attrs:{"src":require("@/assets/blog-1.png"),"alt":""}}),_c('p',{staticClass:"title-blog"},[_vm._v("Processamento de dados")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"img-blog flex-wrap",attrs:{"src":require("@/assets/blog-2.png"),"alt":""}}),_c('p',{staticClass:"title-blog"},[_vm._v("Processamento de dados")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"img-blog flex-wrap",attrs:{"src":require("@/assets/blog-3.png"),"alt":""}}),_c('p',{staticClass:"title-blog"},[_vm._v("Processamento de dados")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4 mt-2"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"img-blog flex-wrap",attrs:{"src":require("@/assets/blog-4.png"),"alt":""}}),_c('p',{staticClass:"title-blog"},[_vm._v("Processamento de dados")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4 mt-2"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"img-blog flex-wrap",attrs:{"src":require("@/assets/blog-5.png"),"alt":""}}),_c('p',{staticClass:"title-blog"},[_vm._v("Processamento de dados")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 mb-4 mt-2"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"img-blog flex-wrap",attrs:{"src":require("@/assets/blog-6.png"),"alt":""}}),_c('p',{staticClass:"title-blog"},[_vm._v("Processamento de dados")])])])
}]

export { render, staticRenderFns }