var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-wrap"},[_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.statistical_methods : "Statistical Methods")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.price_elasticity : "Price Elasticity")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions .know_which_are_the_most_elastic_products : "Know which are the most elastic products of your market segment. Predict the consequences of alterations on the prices of your products and services.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Statistical Methods - Price Elasticity' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.statistical_methods : "Statistical Methods")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.conjoint : "Conjoint")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.emulate_market : "Emulate market share scenarios to predict the next movements of the market. The methodology also identifies consumers' preferences.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Statistical Methods - Conjoint' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(2),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.statistical_methods : "Statistical Methods")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.maxdiff : "MaxDiff")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.compare_more_two : "Compare more two attributes and verify which of them is more important to your target audience.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Statistical Methods - MaxDiff' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(3),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.statistical_methods : "Statistical Methods")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.nps : "NPS")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions .measure_level_of_satisfaction : "Measure the level of satisfaction of your consumers. Identify which consumers are loyal, neutral, or detractors.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Statistical Methods - NPS' }
              }}},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/dolar.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/rate.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/bus.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/megaphone.svg"),"alt":""}})])
}]

export { render, staticRenderFns }