<template>
  <div class="container">
    <div class="itens-blog pt-5">
      <div class="d-flex flex-wrap box-blog pt-4" v-if="resources.length">
        <!--<pre>{{ resources }}</pre>-->
        <div
          class="col-md-4 mb-4"
          v-for="(resources, index) in resources"
          :key="index"
        >
          <a class="readmore" @click="$router.push('/openmatter')">
            <div class="box">
              <img class="img-blog flex-wrap" :src="resources.image_file" />
              <p class="title-blog">{{ resources.title_en }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
export default {
  components: {},
  data() {
    return { resources: [] };
  },
  methods: {
    getNews() {
      api.get("resources").then(r => {
        this.resources = r.data;
      });
    },
    init() {
      this.getNews();
    },
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    },
    outsideClick(element, events, callback) {
      const html = document.documentElement;
      const outside = "data-outside";
      if (!element.hasAttribute(outside)) {
        events.forEach(userEvent => {
          setTimeout(() => {
            html.addEventListener(userEvent, () => {
              if (!element.contains(event.target)) {
                element.removeAttribute(outside);
                events.forEach(userEvent => {
                  html.removeEventListener(userEvent, this.handleOutsideClick);
                });
                callback();
              }
            });
          });
        });
        element.setAttribute(outside, "");
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.box {
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  margin: 0 auto;
  cursor: pointer;
}

.img-blog {
  width: 100%;
}

.title-blog {
  padding: 30px;
  font-size: 1em;
  font-weight: bolder;
  text-align: center;
}

.paragraph-blog {
  padding-left: 20px;
  padding-bottom: 20px;
  color: #707070;
}

.dropdown-blog {
  width: 160px;
  > a {
    color: #000000;
    cursor: pointer;
    font-size: 0.8em;
    padding: 0 5% 20px 5%;
  }
  p {
    margin-bottom: 0;
  }
  &:hover {
    color: #8e8e8e;
    .drop-blog-item {
      display: block;
    }
  }
}

.triangleUp {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgb(233, 233, 233);
  position: absolute;
  top: -13px;
  left: calc(50% - 15px);
}

.drop-blog-item {
  width: 100%;
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  background: rgb(233, 233, 233);
  font-size: 0.8em;
  position: absolute;
  color: black;
  z-index: 5;
  top: 35px;
  text-align: center;
  padding: 5px;
  a {
    display: block;
    color: #495057;
    padding: 2px 10px;
    &:hover,
    &.active {
      color: #666;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  &.active {
    display: block;
  }
}

.readmore {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
</style>
