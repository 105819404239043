<template>
  <div class="owvision">
    <intro />
  </div>
</template>

<script>
import intro from "@/components/owvision/intro.vue";

export default {
  name: "owVision",
  components: {
    intro
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
