<template>
  <section class="clients_section">
    <div class="container" v-if="trustedBy.length">
      <div class="row justify-content-center align-items-center">
        <div class="item col-lg-10">
          <h3>
            {{
              $store.getters.trans
                ? $store.getters.trans.home.clients_trust
                : "Clients that trust Offerwise"
            }}
          </h3>
          <div class="d-flex justify-content-center flex-wrap">
            <div
              class="logos d-flex justify-content-center align-items-center"
              v-for="(trustedBy, index) in trustedBy"
              :key="index"
            >
              <img class="trustedImg" :src="trustedBy.logo_image" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="align-button" v-if="$store.getters.lang === 'en'">
        <router-link :to="{ name: 'contact' }" class="btn btn-survey">
          Contact Us
        </router-link>
      </div>
      <div class="align-button" v-if="$store.getters.lang === 'pt'">
        <router-link :to="{ name: 'contact' }" class="btn btn-survey">
          Entre em Contato
        </router-link>
      </div>
      <div class="align-button" v-if="$store.getters.lang === 'es'">
        <router-link :to="{ name: 'contact' }" class="btn btn-survey">
          Contáctenos
        </router-link>
      </div>
      <div class="border"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    trustedBy: {}
  }
};
</script>

<style lang="scss" scoped>
.VueCarousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.trustedImg {
  //width: 150px;
  height: 35px;
  //margin-right: 90px;
  // margin-bottom: 40px;
}
h3 {
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  color: #666666;
  margin-bottom: 60px;
}

.logos {
  width: 150px;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  section {
    margin: 20px 0;
  }
}

.align-button {
  align-content: center;
  text-align: center;
  margin: 45px 0 40px 0;
}

.btn-survey {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4b83d5;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  transition: all 0.3s ease;
}

.btn-survey:hover {
  text-decoration: none;
  background: #246edd;
}

.clients_section {
  margin-top: 20px;
}

@media (min-width: 1400px) {
  .clients_section {
    margin-top: 48px;
  }
}
</style>
