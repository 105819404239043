<template>
  <div class="home">
    <intro />
    <div v-if="!loader">
      <firstCarouselSection :trustedBy="trustedBy" />
      <secondSection />
      <thirdSection />
      <fourthSection />
      <secondCarouselSection />
      <fifthSection />
    </div>
  </div>
</template>

<script>
import intro from "@/components/home/intro.vue";
import firstCarouselSection from "@/components/home/first-carousel-section.vue";
import secondSection from "@/components/home/second-section.vue";
import thirdSection from "@/components/home/third-section.vue";
import fourthSection from "@/components/home/fourth-section.vue";
import fifthSection from "@/components/home/fifth-section.vue";
import secondCarouselSection from "@/components/home/second-carousel-section.vue";

import { api } from "@/services.js";
export default {
  name: "Home",
  components: {
    intro,
    firstCarouselSection,
    secondSection,
    thirdSection,
    fourthSection,
    fifthSection,
    secondCarouselSection
  },
  data() {
    return {
      trustedBy: [],
      loader: false
    };
  },
  methods: {
    getTrustedBy() {
      this.loader = true;
      api
        .get("trustedby")
        .then(r => {
          this.trustedBy = r.data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    init() {
      this.getTrustedBy();
    }
  },
  created() {
    window.scrollTo({ top: 0 });
    this.init();
  }
};
</script>

<style lang="scss" scoped></style>
