<template>
  <div class="lgpd">
    <intro />
    <secondSection />
    <thirdSection />
    <fourthSection />
  </div>
</template>

<script>
import intro from "@/components/dpl/intro.vue";
import secondSection from "@/components/dpl/second-section.vue";
import thirdSection from "@/components/dpl/third-section.vue";
import fourthSection from "@/components/dpl/fourth-section.vue";

export default {
  name: "dpl",
  components: {
    intro,
    secondSection,
    thirdSection,
    fourthSection
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
