var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row flex-wrap"},[_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.qualitative : "Qualitative")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.communities : "Communities")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions .interact_with_your_targets : "Interact with your targets in an online environment. Create tasks with researches, direct answers, and other resources to obtain valuable insights for your business. ")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Qualitative - Communities' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.qualitative : "Qualitative")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.focus_group : "Focus Group")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions .talk_to_your_consumers : " Talk to your consumers and perceive how they express their opinions. Identify what makes them change their minds to create more effective business strategies. ")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Qualitative - Focus Group' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(2),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.qualitative : "Qualitative")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.consumer_diaries : "Consumer diaries")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.comprehend_details : "Comprehend the details about your client's consumption habits. Know what they buy, on which frequency, and how your product is a part of these behaviors.")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Qualitative - Consumer diaries' }
              }}},[_vm._v(_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])]),_c('div',{staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"box"},[_vm._m(3),_c('p',{staticClass:"type mb-4"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.qualitative : "Qualitative")+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.home.tests.in_depth_interview : "In-depth Interview")+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions .conduct_in_depth_interviews : "Conduct in-depth interviews with consumers and know their opinions regarding your brand. ")+". ")]),_c('div',{staticClass:"getQuote"},[_c('router-link',{attrs:{"to":{
                name: 'requestabid',
                query: { path: 'Qualitative - In-depth Interview' }
              }}},[_vm._v(" "+_vm._s(_vm.$store.getters.trans ? _vm.$store.getters.trans.research_solutions.quantitative .request_bid : "Request a bid")+" >")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/computer-qualitative.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/focus-qualitative.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/calendar-qualitative.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/depth.svg"),"alt":""}})])
}]

export { render, staticRenderFns }