<template>
  <section class="py-5 p-relative bg-third-section">
    <img src="@/assets/bg-image-communities.png" class="bg-1" />
    <img src="@/assets/bg-image-communities.png" class="bg-2" />
    <div class="bg-white"></div>
    <div class="container p-relative">
      <img src="@/assets/dots.svg" class="img-dots" />
      <div class="row align-items-center justify-content-between">
        <div class="col-lg-5 item">
          <div id="text">
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.more_than_solutions.more_than
                  : "More than"
              }}
              <span class="color">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.more_than_solutions.solutions
                    : "50 solutions"
                }}</span
              >
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.more_than_solutions.for_your
                  : "for your business's"
              }}
              <span class="color">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.more_than_solutions.every_need
                    : "every need"
                }}</span
              >
            </p>
          </div>
        </div>
        <div class="col-lg-7 d-flex flex-wrap">
          <div class="col-md-6 mb-5">
            <router-link
              :to="{
                name: 'research',
                query: { option: 'quick-surveys', scroll: true }
              }"
            >
              <div
                class="frame"
                @mouseover="quick = true"
                @mouseleave="quick = false"
                :class="{ active: quick }"
              >
                <img
                  src="@/assets/clock.svg"
                  :class="{ active: quick }"
                  alt="clock"
                />
                <img
                  src="@/assets/clock-white.svg"
                  :class="{ active: !quick }"
                  alt="clock"
                />
                <div :class="{ active: quick }" class="blue">
                  <h2>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ready_to_go_surveys
                        : "Ready-to-go Surveys"
                    }}
                  </h2>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.questionnaire_models
                        : "Questionnaire models ready to go to the field"
                    }}.
                  </p>
                </div>
                <div :class="{ active: !quick }" class="white">
                  <p class="pb-2 pt-3">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.a_b_tests
                        : "A/B Tests"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.brand_health
                        : "Brand Health"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.pre_test
                        : "Pre Test"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.post_test
                        : "Post Test"
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 mb-5">
            <router-link
              :to="{
                name: 'research',
                query: { option: 'quantitatives', scroll: true }
              }"
            >
              <div
                class="frame"
                @mouseover="communication = true"
                @mouseleave="communication = false"
                :class="{ active: communication }"
              >
                <img
                  :class="{ active: communication }"
                  src="@/assets/player.svg"
                  alt="player"
                />
                <img
                  :class="{ active: !communication }"
                  src="@/assets/player-white.svg"
                  alt="player"
                />
                <div :class="{ active: communication }" class="blue">
                  <h2>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests
                            .communication_and_branding_studies
                        : "Communication and Branding Studies"
                    }}
                  </h2>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.monitor_your_brand
                        : "Monitor your brand equity and memorability through our methodologies"
                    }}.
                  </p>
                </div>
                <div :class="{ active: !communication }" class="white">
                  <p class="pb-3 pt-3">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.brand_health
                        : "Brand Health"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.pre_and_post
                        : "Pre and Post Communication Tests"
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 mb-5">
            <router-link
              :to="{
                name: 'research',
                query: { option: 'statistical', scroll: true }
              }"
            >
              <div
                class="frame"
                @mouseover="statistics = true"
                @mouseleave="statistics = false"
                :class="{ active: statistics }"
              >
                <img
                  :class="{ active: statistics }"
                  src="@/assets/statistics.svg"
                  alt="statistics"
                />
                <img
                  :class="{ active: !statistics }"
                  src="@/assets/statistics-white.svg"
                  alt="statistics"
                />
                <div :class="{ active: statistics }" class="blue">
                  <h2>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.statistical_test
                        : "Statistical Tests"
                    }}
                  </h2>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.obtain_well_grounded
                        : "Obtain well-grounded data and conduct an advanced analysis. Evaluate your target's acceptance of a new product, packaging, or communication efforts"
                    }}.
                  </p>
                </div>
                <div
                  :class="{ active: !statistics }"
                  class="white w-statistics"
                >
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.price_elasticity
                        : "Price Elasticity"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ihut
                        : "IHUT"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.conjoint
                        : "Conjoint"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests
                            .concept_package_and_product_test
                        : "Concept, Package and Product Test"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.maxdiff
                        : "MaxDiff"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.nps
                        : "NPS"
                    }}
                  </p>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.task_panel
                        : "Task Panel"
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 mb-5">
            <router-link
              :to="{
                name: 'research',
                query: { option: 'qualitatives', scroll: true }
              }"
            >
              <div
                class="frame"
                @mouseover="qualitative = true"
                @mouseleave="qualitative = false"
                :class="{ active: qualitative }"
              >
                <img
                  :class="{ active: qualitative }"
                  src="@/assets/message.svg"
                  alt="message"
                />
                <img
                  :class="{ active: !qualitative }"
                  src="@/assets/message-white.svg"
                  alt="message"
                />
                <div :class="{ active: qualitative }" class="blue">
                  <h2>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.qualitative
                        : "Qualitative"
                    }}
                  </h2>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.know_our_methodologies
                        : "Know our methodologies and exclusive platforms - OW Focus and OW Voices"
                    }}.
                  </p>
                </div>
                <div :class="{ active: !qualitative }" class="white">
                  <p class="pb-2 pt-3">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.communities
                        : "Communities"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.focus_group
                        : "Focus Group"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.consumer_diaries
                        : "Consumer Diaries"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.in_depth_interview
                        : "In-depth Interview"
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 mb-5">
            <router-link
              :to="{
                name: 'research',
                query: { option: 'quantitatives', scroll: true }
              }"
            >
              <div
                class="frame"
                @mouseover="indicators = true"
                @mouseleave="indicators = false"
                :class="{ active: indicators }"
              >
                <img
                  :class="{ active: indicators }"
                  src="@/assets/indicators.svg"
                  alt="indicators"
                />
                <img
                  :class="{ active: !indicators }"
                  src="@/assets/indicators-white.svg"
                  alt="indicators"
                />
                <div :class="{ active: indicators }" class="blue">
                  <h2>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.indicators
                        : "Indicators"
                    }}
                  </h2>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.observe_all_kpis
                        : "Observe all KPIs from your study in real-time"
                    }}.
                  </p>
                </div>
                <div :class="{ active: !indicators }" class="white">
                  <p class="pb-2 pt-3">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.omnibus
                        : "Omnibus"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.public_affairs
                        : "Public Affairs"
                    }}
                  </p>
                  <p class="pb-2">
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.ad_hoc_tracker
                        : "Ad hoc/Tracker"
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-md-6 mb-5">
            <router-link
              :to="{
                name: 'research',
                query: { option: 'passive-data', scroll: true }
              }"
            >
              <div
                class="frame"
                @mouseover="passive = true"
                @mouseleave="passive = false"
                :class="{ active: passive }"
              >
                <img
                  :class="{ active: passive }"
                  src="@/assets/cellphone.svg"
                  alt="cellphone"
                />
                <img
                  :class="{ active: !passive }"
                  src="@/assets/cellphone-white.svg"
                  alt="cellphone"
                />
                <div :class="{ active: passive }" class="blue">
                  <h2>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests.passive_data
                        : "Passive Data"
                    }}
                  </h2>
                  <p>
                    {{
                      $store.getters.trans
                        ? $store.getters.trans.home.tests
                            .collect_all_passive_data
                        : "Collect all sorts of passive data Use our high-tech methods"
                    }}.
                  </p>
                </div>
                <div :class="{ active: !passive }" class="white">
                  <div>
                    <p class="pt-3">
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.home.tests.buyers_journey
                          : "Buyer's Journey"
                      }}
                    </p>
                    <p>
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.home.tests.smartphone_usage
                          : "Smartphone Usage"
                      }}
                    </p>
                    <p>
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.home.tests.geofencing
                          : "Geofencing"
                      }}
                    </p>
                    <p>
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.home.tests.eye_tracking
                          : "Eye Tracking"
                      }}
                    </p>
                    <p>
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.home.tests.face_recognition
                          : "Face Recognition"
                      }}
                    </p>
                    <p>
                      {{
                        $store.getters.trans
                          ? $store.getters.trans.home.tests.cookies_and_tags
                          : "Cookies & Tags"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row justify-content-center section4 py-5">
        <div class="col-md-12 text-center mb-5">
          <div class="text">
            <h2>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.diy_surveys
                  : "DIY SURVEYS"
              }}
            </h2>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products
                      .simple_automated_survey
                  : "Simple automated surveys"
              }}
            </h3>
          </div>
        </div>
        <div class="col-md-10 d-flex flex-wrap">
          <div class="col-md-3 grid1">
            <div class="item">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .upload_text_file
                    : "Upload a text file to the platform and program your survey automatically"
                }}.
              </p>
              <div class="ball">1</div>
            </div>
          </div>
          <div class="col-md-3 grid2">
            <div class="item">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .build_advanced_programming_logic
                    : "Build advanced programming logic"
                }}.
              </p>
              <div class="ball">2</div>
            </div>
          </div>
          <div class="col-md-3 grid3">
            <div class="item">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products.send_survey
                    : "Send your survey to 10 million respondents in 20 countries"
                }}.
              </p>
              <div class="ball">3</div>
            </div>
          </div>
          <div class="col-md-3 grid4">
            <div class="item">
              <p>
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .monitor_field
                    : "Monitor the field progress and observe the results in real-time"
                }}.
              </p>
              <div class="ball">4</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row section5 py-5 align-item-center p-relative mt-5">
        <div class="col-lg-4 mb-4 first-col d-flex align-items-center">
          <div>
            <div class="item d-flex align-items-center">
              <img src="@/assets/check.svg" alt="check" />
              <div class="ml-5">
                <h4>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .easy_to_use
                      : "Easy-to-use"
                  }}
                </h4>
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .easy_to_use_diy
                      : "An easy-to-use DIY survey platform"
                  }}.
                </p>
              </div>
            </div>
            <div class="item d-flex align-items-center">
              <img src="@/assets/complete.svg" alt="complete" />
              <div class="ml-5">
                <h4>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products.complete
                      : "Complete"
                  }}
                </h4>
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .mange_all_parts
                      : "Manage all parts of your project on the same platform - programming, questionnaire design, data collection, and result analysis"
                  }}.
                </p>
              </div>
            </div>
            <div class="item mb-3 d-flex align-items-center">
              <img src="@/assets/trophy.svg" alt="trophy" />
              <div class="ml-5">
                <h4>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .high_quality
                      : "High-Quality"
                  }}
                </h4>
                <p>
                  {{
                    $store.getters.trans
                      ? $store.getters.trans.home.tests.page_products
                          .for_over_15_years
                      : "For over 15 years, Offerwise provides high-quality solutions for its clients and the best respondents for their questionnaires"
                  }}.
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center py-4">
              <router-link :to="{ name: 'product' }" class="knowMore">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products.know_more
                    : "Know more"
                }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="second-col">
          <div class="logos d-flex">
            <img src="@/assets/survey.svg" alt="logo ow survey" class="mr-4" />
            <img src="@/assets/sample-DIY.svg" alt="logo ow sample" />
          </div>
          <div class="videoCompuiter d-flex justify-content-center">
            <img src="@/assets/tablet.gif" class="gif-tablet" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      quick: false,
      communication: false,
      statistics: false,
      qualitative: false,
      indicators: false,
      passive: false
    };
  }
};
</script>

<style lang="scss" scoped>
.bg-third-section {
  background: #f7fafb;
  .bg-1 {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .bg-2 {
    position: absolute;
    bottom: 140px;
    width: 100%;
    transform: rotate(180deg);
    transform: rotateX(180deg);
  }
  .bg-white {
    background: #fff;
    width: 100%;
    height: 140px;
    position: absolute;
    bottom: 0;
    z-index: 0;
  }
}
.img-dots {
  position: absolute;
  top: 270px;
  left: -65px;
}
#text p {
  font-weight: 600;
  font-size: 2.5rem;
  width: 420px;
  line-height: 55px;
  margin: 0;
}

.color {
  color: #7ba4bf;
}

a {
  text-decoration: none;
  color: black;
  .frame {
    padding: 25px 25px 0px 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.2);
    min-height: 230px;
    transition: all 0.3s ease;
    cursor: pointer;
    &.active {
      background: #7d9bad;
    }
    img {
      margin-bottom: 10px;
      width: 36px;
      height: 36px;
      transition: all 0.3s ease;
      &.active {
        display: none;
      }
    }
    > div {
      transition: all 0.3s ease;
      &.active {
        display: none;
      }
    }
    .white {
      p {
        text-transform: uppercase;
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0;
      }
    }
    .w-statistics {
      p {
        margin-bottom: -3px;
      }
    }
    .blue {
      h2 {
        font-weight: 700;
        font-size: 16px;
      }
      p {
        font-weight: 400;
        font-size: 0.75rem;
        color: #707070;
      }
    }
  }
}

.section4 {
  .text {
    h2 {
      font-weight: normal;
      font-size: 1rem;
      color: #707070;
      letter-spacing: 0.12em;
    }
    h3 {
      font-weight: 700;
      font-size: 1.5rem;
      color: black;
    }
  }
  .item {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px 10px;
    background: #ffffff;
    width: 175px;
    min-height: 184px;
    p {
      color: #707070;
      width: 140px;
      margin-bottom: 0;
    }
  }
  .ball {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #33a1f9;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.3rem;
    right: -25px;
    top: calc(50% - 25px);
  }
}

.section5 {
  height: 500px;
  .col1,
  .col2 {
    height: 410px;
  }

  .first-col {
    height: 455px;
  }

  .second-col {
    .logos {
      position: relative;
      left: 120px;
      top: -30px;
      img {
        width: 126px;
      }
    }
    .videoCompuiter {
      position: absolute;
      top: calc(50% - 187.5px);
      .gif-tablet {
        width: 70%;
        border-radius: 30px;
        box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
      }
    }
  }

  .item {
    margin-bottom: 55px;
    img {
      position: absolute;
      width: 35px;
      height: 35px;
    }
    h4 {
      font-weight: 700;
      font-size: 1rem;
    }
    p {
      color: #707070;
      font-size: 0.8125rem;
      margin-bottom: 0;
    }
  }
  .knowMore {
    text-decoration: none;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 5px 35px;
    background: #33a1f9;
    border-radius: 25px;
    box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
    margin-bottom: 30px;
    position: relative;
    z-index: 10;
  }
  a:hover {
    text-decoration: none;
    color: #ffffff;
    background: #3497e7;
  }
}

.style-blue {
  position: absolute;
  height: 100%;
  top: 0;
}

@media (max-width: 1199.98px) {
  .bg-2 {
    bottom: 250px !important;
  }
  .bg-white {
    height: 250px !important;
  }
  .img-dots {
    position: absolute;
    top: 310px;
    left: -65px;
  }
  .frame {
    min-height: 250px;
  }
  .section4 {
    margin-bottom: 450px;
    .item {
      width: 190px;
      min-height: 184px;
    }
    .ball {
      right: -25px;
      top: calc(45% - 25px);
    }
    .grid1 {
      position: absolute;
      left: 55px;
    }
    .grid2 {
      position: absolute;
      right: 100px;
    }
    .grid3 {
      position: absolute;
      top: 220px;
      left: 55px;
    }
    .grid4 {
      position: absolute;
      top: 220px;
      right: 100px;
    }
  }
  .section5 {
    margin-top: 400px !important;
    .second-col {
      .logos {
        left: 80px;
        top: -20px;
      }
      .videoCompuiter {
        position: absolute;
        top: calc(50% - 180px);
        .gif-tablet {
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .bg-third-section {
    .bg-1 {
      display: none;
    }
    .bg-2 {
      display: none;
    }
    .bg-white {
      display: none;
    }
  }
  .frame {
    min-height: 219px !important;
    .w-statistics {
      p {
        margin-bottom: 0px;
      }
    }
  }
  .img-dots {
    display: none;
  }
  #text p {
    font-weight: 600;
    font-size: 2.5rem;
    width: 100%;
    margin-bottom: 70px;
    text-align: center;
  }

  .section4 {
    margin-bottom: 413px;
    .grid1 {
      position: absolute;
      left: 5px;
    }
    .grid3 {
      position: absolute;
      top: 220px;
      left: 5px;
    }
  }
  .section5 {
    margin-top: 0px !important;
    margin-bottom: 99px;
    .first-col {
      display: unset !important;
    }
    .item {
      display: block !important;
      margin-bottom: 40px;
      img {
        position: relative;
        left: calc(50% - 17px);
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
      }
      h4 {
        font-weight: 700;
        font-size: 1.2rem;
        margin-left: -48px;
        text-align: center;
      }
      p {
        color: #707070;
        font-size: 0.85rem;
        margin-left: -48px;
        width: 280px;
        text-align: center;
        margin-left: calc(50% - 164px);
      }
    }
    a {
      font-size: 1.25rem;
      padding: 5px 40px;
      order: 2;
    }
  }
  .second-col {
    display: none;
    .videoCompuiter {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  .frame {
    min-height: 222px !important;
  }
  .section4 {
    margin-bottom: -51px;
    .text {
      h2 {
        font-weight: normal;
        font-size: 1.3rem;
        color: #707070;
        letter-spacing: 7px;
      }
      h3 {
        font-weight: 700;
        font-size: 1.8rem;
        color: black;
      }
    }
    .item {
      position: relative;
      display: flex;
      margin: 0 auto;
      padding: 20px;
      background: rgba(255, 255, 255, 0.5);
      min-height: 186px;
    }
    .grid1,
    .grid2,
    .grid3,
    .grid4 {
      position: static;
      margin-bottom: 35px;
    }
    p {
      width: 200px;
    }
    .ball {
      position: absolute;
      top: calc(50% - 50px);
    }
  }
  .section5 {
    margin-top: 22px !important;
  }
}

@media (max-width: 575.98px) {
  section {
    padding-bottom: 800px;
  }
  #text {
    margin-left: 0;
    p {
      font-weight: 600;
      font-size: 2rem;
      width: 100%;
      margin: 0;
      margin-bottom: 50px;
      text-align: center;
    }
  }
  .frame {
    min-height: 215px !important;
  }
  .section4 {
    margin-top: 35px;
    .text {
      h2 {
        font-weight: normal;
        font-size: 1.3rem;
        color: #707070;
        letter-spacing: 7px;
      }
      h3 {
        font-weight: 700;
        font-size: 1.8rem;
        color: black;
      }
    }
    .grid1,
    .grid2,
    .grid3,
    .grid4 {
      position: static;
      margin-bottom: 25px;
    }
    .item {
      position: relative;
      display: flex;
      margin: 0 auto;
      margin-bottom: 20px;
      padding: 20px;
      background: rgba(255, 255, 255, 0.5);
      min-height: 186px;
    }
    p {
      width: 200px;
    }
    .ball {
      top: calc(50% - 35px);
    }
  }
  .section5 {
    margin: -25px 0 65px 0;
    margin-top: 0px !important;
    margin-bottom: 120px;

    .item {
      img {
        position: relative;
        left: calc(50% - 17px);
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
      }
      h4 {
        font-weight: 700;
        font-size: 1.2rem;
        margin-left: -48px;
        text-align: center;
      }
      p {
        color: #707070;
        font-size: 0.85rem;
        margin-left: -48px;
        text-align: center;
        margin-left: calc(50% - 164px);
      }
    }
    a {
      font-size: 1.25rem;
      padding: 5px 40px;
    }
  }
  .videoCompuiter {
    display: none;
  }
}
</style>
