<template>
  <section class="p-relative" id="intro">
    <div class="container">
      <!--<div class="text-center introCareers">
        <h3>Offerwise Careers</h3>
        <p>
          At Offerwise, we're always looking for new talents to integrate our
          team. If you want to apply for one of our vacant positions, click on
          the links below.
        </p>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6 imgVacant d-flex justify-content-center">
          <img src="@/assets/vacant.svg" alt="" class="img-fluid" />
        </div>
        <div class="col-lg-6 mb-5 item">
          <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls>
            <b-carousel-slide img-blank>
              <h2>Project Management</h2>
              <div class="type mt-3">
                <p>Internship</p>
              </div>
              <h4>Perfil que buscamos:</h4>
              <p>Boa comunicação;</p>
              <p>- Facilidade com línguas estrangeiras (inglês e espanhol);</p>
              <p>- Saiba trabalhar em equipe;</p>
              <p>- Muita vontade de aprender sobre o mercado;</p>
              <p>- Boa organização e senso de responsabilidade;</p>
              <p>- Buscar melhorar sempre.</p>
              <h4>Pré requisitos:</h4>
              <p>- Inglés avançado</p>
              <p>- Espanhol avançado</p>
              <div class="aplly mt-5">
                <router-link :to="{ name: 'jobs' }" class="btn-aplly"
                  >Aplly</router-link
                >
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-blank>
              <h2>Project Management 2</h2>
              <div class="type mt-3">
                <p>Internship</p>
              </div>
              <h4>Perfil que buscamos:</h4>
              <p>Boa comunicação;</p>
              <p>- Facilidade com línguas estrangeiras (inglês e espanhol);</p>
              <p>- Saiba trabalhar em equipe;</p>
              <p>- Muita vontade de aprender sobre o mercado;</p>
              <p>- Boa organização e senso de responsabilidade;</p>
              <p>- Buscar melhorar sempre.</p>
              <h4>Pré requisitos:</h4>
              <p>- Inglés avançado</p>
              <p>- Espanhol avançado</p>
              <div class="aplly mt-5">
                <router-link :to="{ name: 'careers' }" class="btn-aplly"
                  >Aplly</router-link
                >
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>-->
      <div class="row justify-content-center">
        <div
          class="col-lg-10 d-flex justify-content-center align-items-center height-col"
        >
          <div class="img-follow">
            <img src="@/assets/cellphone-follow.png" alt="" />
          </div>
          <div class="col-md-5 d-flex justify-content-center">
            <div class="followUs">
              <p class="title-follow">Follow us</p>
              <div class="d-flex">
                <a href="https://br.linkedin.com/company/offerwise"
                  ><img src="@/assets/icon-linkedin.svg" class="mr-3"
                /></a>
                <a href="https://www.instagram.com/offerwiseresearch"
                  ><img src="@/assets/icon-instagram.svg" class="mr-3"
                /></a>
                <a
                  href="https://www.youtube.com/channel/UCvLRefUzMyTai4sAXPu0gKw"
                  ><img src="@/assets/icon-youtube.svg"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
section {
  overflow: hidden;
}

.height-col {
  height: 330px;
}

.title-follow {
  font-weight: 700;
  font-size: 1.25rem;
  color: #000;
}

.img-follow {
  position: relative;
  top: 95px;
}

a img {
  width: 35px;
  height: 35px;
}

@media (max-width: 768.98px) {
  section {
    margin-top: 150px;
  }
}

@media (max-width: 576.98px) {
  section {
    margin-top: 100px;
  }

  .title-follow {
    text-align: center;
  }

  .height-col {
    align-items: flex-start !important;
    height: 150px;
  }

  .img-follow {
    display: none;
  }
}

/*section {
  background: #f4f8fc;
  z-index: 0;
  margin-top: 40px;
}
.bg-about {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
}
.introCareers {
  text-align: center;

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    color: #000000;
  }
  p {
    display: block;
    font-weight: 400;
    font-size: 0.8125rem;
    color: #666666;
    max-width: 620px;
    margin: 0 auto;
  }
}

.imgVacant {
  padding: 0px 15px 0px 15px;
}
.item {
  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    color: #000000;
  }
  h4 {
    font-weight: 700;
    font-size: 0.8125rem;
    margin: 30px 0;
  }
  p {
    font-weight: 400;
    font-size: 0.8125rem;
    margin: 0;
  }
}
.type p {
  font-weight: 400;
  font-size: 0.8rem;
  color: #ffffff;
  border-radius: 2px;
  background: #ff9300;
  width: 100px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-item,
.carousel-inner {
  height: 600px;
}

.aplly {
  .btn-aplly {
    font-weight: 500;
    font-size: 0.8rem;
    padding: 5px 35px;
    border-radius: 20px;
    color: #fff;
    text-transform: uppercase;
    background: #3a70c0;
    box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
    cursor: pointer;
    &:hover {
      text-decoration: none;
      background: #3262aa;
    }
  }
}

@media (max-width: 1199.98px) {
  section {
    margin-top: 0px;
  }
}

@media (max-width: 991.98px) {
  section {
    padding-bottom: 0 !important;
  }
  .img-fluid {
    display: none;
  }
  .bg-about {
    display: none;
  }
  .introCareers {
    padding-top: 20px;
  }
  .item {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
    h4 {
      text-align: center;
    }
    .type {
      margin-left: calc(50% - 50px);
    }
    .aplly {
      .btn-aplly {
        margin-left: calc(50% - 52.82px);
      }
    }
  }
}
@media (max-width: 575.98px) {
  .item {
    margin-top: 30px;
  }
}*/
</style>
