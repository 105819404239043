import { render, staticRenderFns } from "./third-section.vue?vue&type=template&id=09ef9288&scoped=true"
import script from "./third-section.vue?vue&type=script&lang=js"
export * from "./third-section.vue?vue&type=script&lang=js"
import style0 from "./third-section.vue?vue&type=style&index=0&id=09ef9288&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09ef9288",
  null
  
)

export default component.exports