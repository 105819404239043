<template>
  <section class="pb-5">
    <div class="col-md-12">
      <div class="d-flex justify-content-center selectOffices mt-4">
        <p
          class="one"
          @click="changeTab('inside-ow')"
          :class="{ active: option === 'inside-ow' }"
          id="inside-ow"
        >
          Inside OW
        </p>
        <p
          class="two"
          @click="changeTab('news')"
          :class="{ active: option === 'news' }"
          id="news"
        >
          News
        </p>
        <p
          class="three"
          @click="changeTab('innovations')"
          :class="{ active: option === 'innovations' }"
          id="innovations"
        >
          Innovations
        </p>
        <p
          class="four"
          @click="changeTab('reports')"
          :class="{ active: option === 'reports' }"
          id="reports"
        >
          Reports
        </p>
      </div>

      <span class="target"></span>
      <div class="container h100">
        <div class="d-flex flex-wrap justify-content-center selectOffices">
          <div class="lineOffices"></div>
        </div>
      </div>
    </div>
    <transition mode="out-in">
      <insideow v-if="option === 'inside-ow'" />
      <news v-if="option === 'news'" />
      <innovations v-if="option === 'innovations'" />
      <reports v-if="option === 'reports'" />
    </transition>
  </section>
</template>

<script>
import insideow from "@/components/resources/insideow.vue";
import news from "@/components/resources/news.vue";
import innovations from "@/components/resources/innovations.vue";
import reports from "@/components/resources/reports.vue";

export default {
  components: {
    insideow,
    news,
    innovations,
    reports
  },
  data() {
    return {};
  },
  computed: {
    option() {
      return this.$route.query.option;
    }
  },
  methods: {
    changeTab(option) {
      this.$router.push({ query: { option: option } });
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
}

.orderby {
  font-size: 0.8em;
  margin-top: 20px;
}

.selectOffices p {
  text-align: center;
  width: 120px;
  font-weight: 700;
  font-size: 0.8em;
  cursor: pointer;
  color: #707070;
  line-height: 4em;

  &.active {
    color: #4f87da;
    border-bottom: 3px solid #4b83d5;
    line-height: 4em;
    z-index: 1;
  }
}

.selectOffices a {
  font-weight: 700;
  font-size: 0.8em;
  cursor: pointer;
  color: #707070;
  line-height: 4em;

  &.active {
    color: #4f87da;
    border-bottom: 3px solid #4b83d5;
    line-height: 4em;
    z-index: 1;
  }
}

.lineOffices {
  border-bottom: 1px solid #d1d3d4;
  position: relative;
  width: 75%;
  top: -18px;
}

ul li {
  display: inline;
  text-align: center;
}

a {
  display: inline-block;
  width: 25%;
  padding: 0.75rem 0;
  margin: 0;
  text-decoration: none;
  color: #333;
}

.two:hover ~ hr {
  margin-left: 25%;
}

.three:hover ~ hr {
  margin-left: 50%;
}

.four:hover ~ hr {
  margin-left: 75%;
}

hr {
  height: 0.25rem;
  width: 15%;
  margin: 0;
  background: #4b83d5;
  border: none;
  transition: 0.3s ease-in-out;
}
</style>
