import { render, staticRenderFns } from "./insideow.vue?vue&type=template&id=28f7e736&scoped=true"
import script from "./insideow.vue?vue&type=script&lang=js"
export * from "./insideow.vue?vue&type=script&lang=js"
import style0 from "./insideow.vue?vue&type=style&index=0&id=28f7e736&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f7e736",
  null
  
)

export default component.exports