<template>
  <section class="intro">
    <div class="container h100">
      <div class="ball-1"></div>
      <div class="row pt-5 align-items-center h100">
        <div class="col-xl-6">
          <div class="title-align">
            <h1 class="title">
              {{
                $store.getters.trans
                  ? $store.getters.trans.resources_page.resources
                  : "Resources"
              }}
            </h1>
          </div>
        </div>
        <div class="col-xl-6 intro-img">
          <img
            class="img-banner img-fluid"
            src="@/assets/img-blog.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <a
      href="#second"
      class="arrow-btn down"
      v-smooth-scroll="{ duration: 1000 }"
    >
      <img src="@/assets/arrow.svg" alt="arrow" />
    </a>
    <div class="intro-style">
      <img src="@/assets/style2.png" alt="style" />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.intro {
  height: calc(550px - 10px);
  background: linear-gradient(
    133.2deg,
    rgba(236, 240, 248, 0.43) 38.39%,
    #ccdde5 93.86%
  );
  padding-top: 125px;
  position: relative;
  .container {
    position: relative;
  }
}

.intro-style {
  position: absolute;
  bottom: -3px;
  width: 100%;
  img {
    width: 100%;
  }
}

.arrow-btn {
  position: absolute;
  bottom: 53px;
  right: calc(50% - 28px);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.title {
  font-weight: 500;
  color: #000000;
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  margin-top: -140px;
}

.img-banner {
  bottom: 126px;
  position: relative;
  right: -97px;
  width: 90%;
}

@media (max-width: 1199.98px) {
  .intro-img {
    display: none;
  }
  .title-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .intro {
    height: calc(80vh - 10px);
    max-height: 700px;
  }
  .arrow-btn {
    bottom: 42px;
  }
}

.ball-1 {
  width: 620px;
  height: 620px;
  border-radius: 50%;
  position: absolute;
  top: -380px;
  left: -150px;
  background: rgba(204, 221, 229, 0.08);
}

@media (max-width: 767.98px) {
  .arrow-btn {
    bottom: 26px;
  }
}

@media (max-width: 575.98px) {
  .arrow-btn {
    bottom: 14px;
  }
}

@media (min-width: 1400px) {
  .arrow-btn {
    bottom: 52px;
  }
}
@media (min-width: 1800px) {
  .arrow-btn {
    bottom: 75px;
  }
}
</style>
