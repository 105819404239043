<template>
  <section>
    <div class="container focus-pt">
      <div class="row justify-content-between pt-5 pb-3">
        <div class="col-lg-5 pt-5 section-focus" id="focus">
          <img
            v-if="$store.getters.lang === 'pt'"
            class="img-fluid img-focus"
            src="@/assets/gif-focus-pt.gif"
            alt="Imagem"
          />
          <img
            v-if="$store.getters.lang === 'en'"
            class="img-fluid img-focus"
            src="@/assets/gif-focus-en.gif"
            alt="Imagem"
          />
          <img
            v-if="$store.getters.lang === 'es'"
            class="img-fluid img-focus"
            src="@/assets/gif-focus-es.gif"
            alt="Imagem"
          />
        </div>

        <div class="col-lg-6 focus pt-5 pb-3">
          <div class="title-focus pb-4">
            <img
              src="@/assets/focusnewlogo.svg"
              class="img-focus-1 focus"
              alt=""
            />
            <p>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.page_products
                      .online_platforms.ow_focus_explanation
                  : "OW Focus is an online platform developed to conduct focus groups and in-depth interviews"
              }}
            </p>
          </div>
          <div class="pb-4 pt-3">
            <div class="retangle">
              <div class="ball-0">
                <img src="@/assets/earth.svg" class="img-magnifying" alt="" />
              </div>
              <p class="text-retangle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .you_can_easily_create
                    : "You can easily create groups with respondents from different places"
                }}.
              </p>
            </div>
          </div>
          <div class="pb-4">
            <div class="retangle">
              <div class="ball-0">
                <img
                  src="@/assets/message-focus.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle-midle">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .the_platform_provides_all
                    : "The platform provides all moderation tools necessary"
                }}.
              </p>
            </div>
          </div>
          <div class="pb-5">
            <div class="retangle">
              <div class="ball-0">
                <img
                  src="@/assets/webbrowser.svg"
                  class="img-magnifying"
                  alt=""
                />
              </div>
              <p class="text-retangle-last">
                {{
                  $store.getters.trans
                    ? $store.getters.trans.home.tests.page_products
                        .access_ow_focus_through_web_link
                    : "Access OW Focus easily through a web link. There is no need to download a software or app"
                }}.
              </p>
            </div>
          </div>
          <router-link :to="{ name: 'owfocus' }" class="btn btn-getstarted">
            {{
              $store.getters.trans
                ? $store.getters.trans.home.tests.page_products.request_a_demo
                : "Request a Demo"
            }}
          </router-link>
          <a
            href="https://www.youtube.com/watch?v=5EZqTJTUl8g"
            class="btn btn-getstarted"
            target="_blank"
            v-if="$store.getters.lang === 'en'"
          >
            SEE HOW IT WORKS
          </a>
          <a
            href="https://www.youtube.com/watch?v=rPBnE12UQUc"
            class="btn btn-getstarted"
            target="_blank"
            v-if="$store.getters.lang === 'es'"
          >
            VE CÓMO FUNCIONA
          </a>
          <a
            href="https://www.youtube.com/watch?v=X0pNS6omM_E"
            class="btn btn-getstarted"
            target="_blank"
            v-if="$store.getters.lang === 'pt'"
          >
            VEJA COMO FUNCIONA
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    option() {
      return this.$route.query.option;
    }
  },
  mounted() {
    if (this.$route.query.scroll) {
      const element = document.getElementById("");
      element.scrollIntoView();
      window.scrollTo({ top: 2550 });
      // this.$router.push({ name: "product", query: { option: this.option } });
    }
  },
  updated() {
    if (this.$route.query.scroll) {
      const element = document.getElementById("");
      element.scrollIntoView();
      window.scrollTo({ top: 1400 });
      // this.$router.push({ name: "product", query: { option: this.option } });
    }
  }
};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.background {
  background-color: #f7fafb;
  position: relative;
}

.background-white {
  position: absolute;
  top: 0;
  width: 100%;
}

.retangle {
  background: #ffffff;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  border-radius: 5080px;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 90%;
}

.text-retangle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.text-retangle-midle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.text-retangle-last {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.ball-0 {
  width: 60px;
  height: 60px;
  background: #4f9fb7;
  border-radius: 50%;
  position: absolute;
}

.retangle {
  background: #ffffff;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  border-radius: 5080px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.text-retangle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.text-retangle-midle {
  font-size: 13px;
  position: relative;
  max-width: 340px;
  margin: 10px 0 10px 80px;
}

.ball {
  width: 60px;
  height: 60px;
  background: #4f9fb7;
  border-radius: 50%;
  position: absolute;
}

.img-magnifying {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  transform: translate(-50%, -50%);
}

.img-communities {
  margin-left: 48px;
  top: 112px;
  width: 98%;
  border-radius: 10px;
}

.img-animacao {
  position: relative;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
}

.img-survey {
  padding-bottom: 5%;
}

.img-cell {
  position: absolute;
  right: 20%;
  top: 20%;
  z-index: 1;
}

.img-focus {
  right: 20%;
  top: 15%;
}

.img-focus-1 {
  right: 20%;
  top: 15%;
  width: 120px;
  margin-bottom: 20px;
}

.img-focus {
  padding-bottom: 5%;
}

.focus-p {
  font-size: 20px;
}

.section4 .item p {
  color: #707070;
  font-size: 12px;
}

.title {
  p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.title-focus {
  p {
    margin-bottom: 0;
    color: black;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 14px;
  color: #3f4451;
  font-weight: bold;
}

.vertical-items {
  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 25px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

.btn-getstarted {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4f9fb7;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 22px 3px 22px;
  margin: 5px;
}

.btn-getstarted:hover {
  text-decoration: none;
  background: #38c1eb;
  transition: all 0.3s ease;
}

.btn-getstarted-communities {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.8rem;
  background: #4f9fb7;
  box-shadow: 5px 5px 16px rgba(45, 97, 135, 0.3);
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  transition: all 0.3s ease;
}

.btn-getstarted-communities:hover {
  text-decoration: none;
  color: #ffffff;
  background: #418296;
}

.btn-focus {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #0695fd;
  font-size: 0.8rem;
  background: #ffffff;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 15px;
  padding: 3px 28px 3px 28px;
  border: 1px solid #3a70c0;
  margin-left: 20px;
  transition: all 0.3s ease;
}

.btn-focus:hover {
  text-decoration: none;
  color: #ffffff;
  background: #3a70c0;
}

@media (max-width: 991.98px) {
  .img-focus-cell {
    display: none;
  }

  .img-focus-1 {
    right: 20%;
    top: 15%;
    width: 120px;
    margin-bottom: 10px;
    padding-top: 60px;
  }

  .section-focus {
    display: none;
  }

  .focus {
    text-align: center;
    margin-top: -93px;
  }
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
  .img-communities {
    display: none;
  }
  .communities {
    text-align: center;
    margin-top: -5px;
    margin-bottom: -80px;
  }
  .retangle {
    width: 50%;
    margin-left: calc(50% - 235.5px);
  }
}

@media (max-width: 991.98px) {
  .retangle {
    width: 70%;
    margin-left: calc(50% - 235.5px);
  }
}

@media (max-width: 767.98px) {
  .retangle {
    width: 90%;
    margin-left: calc(50% - 235.5px);
  }
}

@media (max-width: 575.98px) {
  .retangle {
    width: 100%;
    margin-left: 0px;
  }
  .text-retangle-last {
    font-size: 13px;
    position: relative;
    max-width: 340px;
    margin: 5px 0 5px 80px;
  }
}

@media (max-width: 414px) {
  .btn-focus {
    margin-left: 0;
  }
  .retangle {
    width: 100%;
    margin-left: 0px;
  }
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 359px) {
  .survey-p {
    text-align: center;
    margin-top: 20px;
  }
}

.img-owvoices {
  width: 132px;
  margin-bottom: 27px;
}
</style>
