<template>
  <section id="second">
    <div class="container">
      <div class="row justify-content-between" v-if="resources.length">
        <div class="col-lg-5 pt-5">
          <div class="align-text">
            <div class="title pb-2">
              <p>{{ resources[0].title_en }}</p>
            </div>
            <div class="vertical-items flex-wrap pb-5 pt-5">
              <div class="retangle flex-wrap">
                <div class="icon-retangle">
                  <p class="p-icon-retangle">
                    {{ resources[0].category.name }}
                  </p>
                </div>
              </div>
              <div>
                <p class="text-pm pb-3">
                  {{ resources[0].short_en }}
                </p>
                <a class="readmore" @click="$router.push('/openmatter')"
                  >Read More ></a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 pt-5">
          <div class="img-pesquisa">
            <div class="ball-1"></div>
            <div class="ball-2"></div>
            <img class="img-banner flex-wrap" :src="resources[0].image_file" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "@/services.js";
export default {
  data() {
    return {
      resources: []
    };
  },
  methods: {
    getNews() {
      api.get("resources").then(r => {
        this.resources = r.data;
      });
    },
    init() {
      this.getNews();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.section4 .text h2 {
  font-weight: normal;
  font-size: 1.5rem;
  color: #707070;
  letter-spacing: 7px;
}

.align-text {
  text-align: right;
}

.readmore {
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: bold;
  color: #3a6b8f;
  cursor: pointer;
}

.retangle {
  position: relative;
  display: flex;
  margin: 5px;
  right: 0;
  justify-content: flex-end;

  .data {
    position: relative;
    margin-top: 3px;
    font-style: italic;
    color: #777777;
  }
}

.form-control {
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
}

.text-ow {
  margin-left: 10px;
}

.icon-retangle {
  background-color: #ff9300;
  border-radius: 3px;
  margin-left: 10px;

  .p-icon-retangle {
    color: #ffffff;
    font-size: 0.8em;
    margin: 3px;
  }
}

.item {
  display: flex;
}

.title {
  p {
    margin-bottom: 0;
    letter-spacing: 0.12em;
    color: #202020;
    font-weight: bold;
    font-size: 20px;
  }
  h2 {
    color: 000000;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.vertical-items {
  > div {
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      height: 25px;
      margin-bottom: 2px;
    }
    h3 {
      font-weight: bold;
      font-size: 0.81rem;
      color: #707070;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      color: #707070;
      margin-bottom: 0;
    }
  }
}

.img-pesquisa {
  position: relative;
}

.img-banner {
  position: relative;
  z-index: 1;
  width: 100%;
}

@media (max-width: 991.98px) {
  .img-banner {
    display: none;
  }
  .align-text {
    text-align: center;
  }
  .retangle {
    justify-content: center;
  }
}

.ball-1 {
  width: 223px;
  height: 223px;
  border-radius: 50%;
  position: absolute;
  right: -47px;
  top: -19px;
  background: radial-gradient(100% 100% at 50.19% 0%, #ecf0f8 0%, #fcfcfc 100%);
  transform: rotate(35.17deg);
}

@media (max-width: 1200px) {
  .ball-1 {
    display: none;
  }
}

.ball-2 {
  width: 136px;
  height: 136px;
  border-radius: 50%;
  position: absolute;
  left: -74px;
  bottom: 49px;
  background: radial-gradient(100% 100% at 50.19% 0%, #ecf0f8 0%, #fcfcfc 100%);
  transform: rotate(-54.02deg);
}

@media (max-width: 1200px) {
  .ball-2 {
    display: none;
  }
}

#intro {
  position: relative;
  z-index: 1;
}
</style>
