import { render, staticRenderFns } from "./DetailsPostTest.vue?vue&type=template&id=3b08b68a&scoped=true"
import script from "./DetailsPostTest.vue?vue&type=script&lang=js"
export * from "./DetailsPostTest.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b08b68a",
  null
  
)

export default component.exports