import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueSmoothScroll from "vue2-smooth-scroll";
import { BFormSelect } from "bootstrap-vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Toaster from "v-toaster";
import "v-toaster/dist/v-toaster.css";
import VueScrollTo from "vue-scrollto";
import VMask from "v-mask";
import { VueMaskDirective } from "v-mask";
import VueProgrammaticInvisibleGoogleRecaptcha from "vue-programmatic-invisible-google-recaptcha";
import VueGtag from "vue-gtag";

Vue.component(
  "vue-programmatic-invisible-google-recaptcha",
  VueProgrammaticInvisibleGoogleRecaptcha
);

Vue.directive("mask", VueMaskDirective);

Vue.use(
  VueGtag,
  {
    config: { id: "UA-1646604-16" },
    includes: [
      {
        id: "G-YLBLQMMCXZ"
      }
    ]
  },
  router
);

Vue.use(VMask);

Vue.use(VueScrollTo);

Vue.component("b-form-select", BFormSelect);

Vue.use(vueSmoothScroll);

Vue.config.productionTip = false;

Vue.use(IconsPlugin);

Vue.use(BootstrapVue);

Vue.use(VueToast);
Vue.use(Toaster, { timeout: 5000 });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
