<template>
  <section class="py-5">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/shopping-cart.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.computer_and_mobile
                  : "Computer and Mobile"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.social_listening
                  : "Social Listening"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.monitor_consumers
                  : "Monitor what consumers are saying about your brand and identify positive or negative comments."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Passive Data - Social Listening' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/location-data.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.mobile
                  : "Mobile"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.geofencing
                  : "Geofencing"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.know_audience_places
                  : "Know the places your audience frequents and create a precise segmentation based on the locations."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Passive Data - Geofencing' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/eye.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.computer_and_mobile
                  : "Computer and Mobile"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.eye_tracking
                  : "Eye Tracking"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.verify_local_point
                  : "Verify which is the focal point of an advertisement, package, or image. Understand what draw your consumer's attention."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Passive Data - Eye Tracking' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/target.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.computer
                  : "Computer"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.face_recognition
                  : "Face Recognition"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .detect_facial_emotions
                  : "Detect the facial emotions an advertisement or package arises on your consumers."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Passive Data - Face Recognition' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/cookies.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.computer_and_mobile
                  : "Computer and Mobile"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.cookies_and_tags
                  : "Cookies & Tags"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.identify_individuals
                  : "Identify the individuals exposed to an advertisement and send them a survey. Evaluate the impact of the message."
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Passive Data - Cookies and Tags' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  width: 288px;
  min-height: 355px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.1);
  padding: 40px 20px 25px 20px;
  margin-bottom: 40px;
  margin-left: calc(50% - 144px);
  .img-box {
    min-height: 84px;
  }
  .type {
    font-weight: 500;
    font-size: 0.625rem;
    color: #ff9300;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  h3 {
    font-weight: 700;
    font-size: 1.187rem;
    color: #3f4451;
  }
  .description {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #3f4451;
    min-height: 114px;
  }
  .getQuote a {
    font-weight: 600;
    font-size: 0.8rem;
    color: #ff9300;
  }
  .getQuote a:hover {
    text-decoration: none;
    color: #ff9300;
  }
}
</style>
