<template>
  <div>
    <div v-if="$store.getters.lang === 'es'">
      <introES />
      <termsES />
    </div>

    <div v-else>
      <introEN />
      <termsEN />
    </div>
  </div>
</template>

<script>
import introES from "@/components/salesterms/introES.vue";
import termsES from "@/components/salesterms/termsES.vue";
import introEN from "@/components/salesterms/introEN.vue";
import termsEN from "@/components/salesterms/termsEN.vue";

export default {
  name: "SalesTerm",
  components: {
    introES,
    termsES,
    introEN,
    termsEN
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
