<template>
  <div class="about">
    <intro />
    <secondSection />
    <thirdSection />
    <fourthSection />
  </div>
</template>

<script>
import intro from "@/components/about/intro.vue";
import secondSection from "@/components/about/second-section.vue";
import thirdSection from "@/components/about/third-section.vue";
import fourthSection from "@/components/about/fourth-section.vue";

export default {
  name: "About",
  components: {
    intro,
    secondSection,
    thirdSection,
    fourthSection
  },
  created() {
    window.scrollTo({ top: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
