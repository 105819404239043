<template>
  <section class="intro">
    <div class="container h100">
      <div class="ball-1"></div>
      <div class="ball-2"></div>
      <img src="@/assets/plants.svg" alt="" id="plants" />
      <div class="row pt-5 align-items-center justify-content-center h100">
        <div class="col-md-8 p-relative d-flex justify-content-center video">
          <iframe
            v-if="$store.getters.lang === 'en'"
            src="https://www.youtube-nocookie.com/embed/-FjGwJXHQMo?controls=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            v-if="$store.getters.lang === 'es'"
            src="https://www.youtube-nocookie.com/embed/97p0le-Y4h8?controls=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>

          <iframe
            v-if="$store.getters.lang === 'pt'"
            src="https://www.youtube-nocookie.com/embed/TKsWqJ26HU4?controls=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.intro {
  width: 100%;
  height: 450px;
  background: linear-gradient(
    273.32deg,
    #7d9bad 0.82%,
    #ccdde5 44.3%,
    #ffffff 88.78%
  );
  padding-top: 125px;
  position: relative;
  .container {
    position: relative;
  }
}

#plants {
  position: absolute;
  width: 277px;
  top: -118px;
  right: 165px;
}

iframe {
  margin: auto;
  margin-top: -65px;
  width: 730px;
  height: 410px;
  background: #eef2f5;
  border-radius: 26px;
  box-shadow: 10px 10px 20px rgba(45, 97, 135, 0.4);
}

.ellipse-intro {
  position: absolute;
  top: 300px;
  right: 100px;
}

.title {
  color: #000000;
  font-size: 2.3em;
  font-weight: bold;
  margin-left: 50px;
  margin-bottom: 100px;
}

.careers-p {
  max-width: 450px;
  font-size: 0.9em;
  color: #51869e;
  margin-left: 50px;
  margin-bottom: 40px;
}

.intro-style {
  position: absolute;
  bottom: -3px;
  width: 100%;
  img {
    width: 100%;
  }
}

.arrow-btn {
  position: absolute;
  bottom: 53px;
  right: calc(50% - 28px);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 50;
  &:focus {
    outline: none;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    opacity: 0.9;
  }
}
.down {
  -webkit-animation: downarrow 0.6s infinite alternate ease-in-out;
}

.img-banner {
  width: 85%;
  position: absolute;
  z-index: 1;
  right: -15px;
  bottom: -223px;
}

@media (max-width: 1199.98px) {
  .img-banner {
    display: none;
  }
  .title {
    text-align: center;
    margin-left: 0;
    margin-top: 60px;
  }
  .careers-p {
    text-align: center;
    margin-top: 20px;
    max-width: 100%;
    margin-left: 0;
  }
}

.form-control {
  font-size: 0.8rem;
}

.ball-1 {
  width: 620px;
  height: 620px;
  border-radius: 50%;
  position: absolute;
  top: -380px;
  left: -150px;
  background: rgba(204, 221, 229, 0.08);
}

.ball-2 {
  width: 127px;
  height: 127px;
  border-radius: 50%;
  position: absolute;
  bottom: -40px;
  right: 110px;
  background: radial-gradient(100% 100% at 50.19% 0%, #ecf0f8 0%, #fcfcfc 100%);
  opacity: 0.3;
  transform: rotate(-54.02deg);
}

@media (max-width: 575.98px) {
  .intro {
    height: 350px;
  }
  iframe {
    height: 300px;
    margin-top: -50px;
  }
  #plants {
    position: absolute;
    width: 220px;
    top: -58px;
    right: calc(50% - 110px);
  }
}

@media (min-width: 1400px) {
  .arrow-btn {
    bottom: 52px;
  }
}
@media (min-width: 1800px) {
  .arrow-btn {
    bottom: 69px;
  }
}
</style>
