<template>
  <section>
    <div class="container">
      <h2>1. Introducción</h2>
      <p>
        1.1. Estos Términos y Condiciones ("Términos") establecen los términos y
        condiciones bajo los cuales <strong>OFFERWISE</strong> proporcionará
        servicios de estudios de mercado a los clientes.
      </p>

      <p>
        1.2. Al contratar nuestros servicios, el Cliente acepta y se compromete
        a cumplir estos Términos en su totalidad.
      </p>

      <h2>2. Servicios de Estudios de Mercado</h2>

      <p>
        2.1. <strong>OFFERWISE</strong> se compromete a proporcionar servicios
        de estudios de mercado según lo acordado en la cotización emitida al
        Cliente.
      </p>

      <p>
        2.2. El presupuesto es válido para los servicios y entregables
        presentados en la propuesta. En caso de que haya cambios en el alcance
        del proyecto, el costo y el plazo de entrega serán revisados.
      </p>

      <h2>3. Confidencialidad</h2>

      <p>
        3.1. Toda la información, datos y resultados obtenidos durante el
        estudio serán tratados de manera estrictamente confidencial.
        <strong>OFFERWISE</strong> no divulgará, compartirá o utilizará la
        información con fines que no estén directamente relacionados con el
        estudio en cuestión, excepto con la autorización expresa del Cliente.
      </p>

      <p>
        3.2. El Cliente deberá solicitar autorización de
        <strong>OFFERWISE</strong> para publicar datos relativos a este estudio
        en los medios.
      </p>

      <h2>4. Protección de datos</h2>

      <p>
        4.1. <strong>OFFERWISE</strong> garantiza al cliente que ha obtenido el
        consentimiento de sus panelistas para participar en estudios de mercado
        y cumple con las leyes de protección de datos vigentes.
      </p>

      <p>
        4.2. <strong>OFFERWISE</strong> no compartirá las informaciones de los
        panelistas con el Cliente, en ninguna circunstancia. Si el Cliente desea
        ofrecer ventajas a los panelistas que sean elegibles para la encuesta
        encargada, con el objetivo de obtener potenciales clientes, esta
        solicitud deberá realizarse de manera expresa, para que el panelista
        pueda brindar su debido consentimiento para tal efecto, teniendo en
        cuenta no ser el Final que se refiere la relación del panelista con
        OFFERWISE.
      </p>

      <p>
        4.3. Los datos de las encuestas serán almacenados en nuestro servidor
        AWS. Los datos recopilados durante el estudio serán retenidos por
        OFFERWISE por un período de 12 meses después de la conclusión del
        estudio. En caso de que sea necesario extender el período de retención,
        se requerirá un acuerdo previo con el cliente, considerando las
        necesidades específicas del proyecto y cualquier requisito legal o
        contractual adicional.
      </p>

      <h2>5. Términos de pago</h2>

      <p>
        5.1. La propuesta tendrá una validez de 30 días a partir de la fecha de
        su presentación al Cliente. Si el cliente desea aceptar la propuesta
        después de la fecha de vencimiento, será necesario ponerse en contacto
        con <strong>OFFERWISE</strong> para verificar la disponibilidad y
        viabilidad de mantener las mismas condiciones propuestas.
      </p>

      <p>
        5.2. Los pagos se realizarán de acuerdo con los plazos y métodos de pago
        especificados en el contrato. <strong>OFFERWISE</strong> emitirá la
        factura finalizada la entrega y brindará un periodo de 30 días para el
        pago de la factura, salvo previo acuerdo de términos de pago con el
        Cliente.
      </p>

      <p>
        5.3. Para nuevos Clientes será cobrado el pago adelantado del 50% del
        valor y lo demás al finalizar la entrega.
      </p>

      <p>
        5.4. Para proyectos de valor superior a US$ 7,500 se requiere el pago
        por adelantado del 25% del valor.
      </p>

      <p>
        5.5. A todos los proyectos se aplica una tarifa mínima de USD 300 o el
        correspondiente en moneda local.
      </p>

      <h2>
        6. Retrasos en el pago
      </h2>

      <p>
        6.1. <strong>OFFERWISE</strong> se reserva de derecho de tomar acciones
        legales contra clientes que no realicen los pagos comprometidos.
      </p>

      <p>
        6.2. En caso de que exista un retraso de pago superior a 100 días desde
        la fecha de vencimiento de la factura, la deuda tendrá un interés
        compuesto de 4% mensual.
      </p>

      <div>
        <p class="main">
          6.3. Finalizado el proyecto, el cliente tendrá hasta 72 horas para
          confirmar o corregir las métricas finales. De lo contrario, se
          facturará con la información obtenida por <strong>OFFERWISE</strong>.
        </p>

        <p>
          6.3.1. En caso de que se solicite modificar una factura porque la
          información de métricas no corresponde y el cliente no realizo la
          confirmación en el plazo estipulado, será imputado un costo adicional
          de 10% del valor a ser asumido por el Cliente.
        </p>
      </div>

      <h2>7. Cancelación y Modificación</h2>

      <p>
        7.1. Cualquier cancelación o modificación de los servicios debe
        acordarse por escrito entre <strong>OFFERWISE</strong> y el Cliente.
      </p>

      <p>
        7.2. En caso de que se produzcan cambios en los servicios contratados
        después de la emisión de la propuesta comercial, ambas partes deben
        acordar por escrito las nuevas condiciones de facturación, con el fin de
        mantener la transparencia y el acuerdo mutuo entre las partes
        involucradas.
      </p>

      <h3>7.3. Desviaciones:</h3>

      <div>
        <p>
          7.3.1. cualquier alteración en las métricas superior a un 20%
          implicará en reprice al momento del soft launch o si necesario en el
          full launch.
        </p>

        <p>
          7.3.2. para filtros aplicados con una duración mayor a 3 minutos de
          encuesta se cobra el 20% del CPI.
        </p>

        <p>
          7.3.3. En dado caso que la tasa de abandono del proyecto supera los
          20% estimados para un campo promedio será aplicado un reprice en el
          costo por entrevista completa.
        </p>

        <p>
          7.3.4. Si el proyecto llega a ser cancelado en cualquier momento
          posterior a la prueba de links se requiere el pago de la tarifa mínima
          de servicio de US$300.
        </p>
      </div>

      <h2>8. Especificación de servicios</h2>

      <h3>8.1. Diseño de cuestionario y Programación:</h3>

      <div>
        <p>
          8.1.1. Una vez aprobado cuestionario y programado, se admiten cambios
          de hasta 10% del documento. De lo contrario se aplica una tarifa de
          ajuste de acuerdo con el valor inicial de diseño y/o programación del
          cuestionario.
        </p>

        <p>
          8.1.2. Cambios mayores a un 20% en la programación de estudios tracker
          deben ser señalados al momento de la cotización o aprobación de nueva
          ola.
        </p>
      </div>

      <h3>
        8.2. Campo
      </h3>

      <div>
        <p>
          8.2.1. Agregar o modificar cuotas podría generar cambios en los plazos
          inicialmente establecidos para los cierres de campo.
        </p>
      </div>

      <h3>8.3. Procesamiento</h3>

      <div>
        <p>
          8.3.1. El Procesamiento estándar se compone por hasta tres banners/
          variables. En el caso de necesitar más que esto se considera
          procesamiento avanzado y se debe hacer el ajuste de costos y tiempos
          conforme la solicitud.
        </p>
      </div>

      <h2>9. Retenciones</h2>

      <p>
        Para los proyectos que deban ser facturados a Estados Unidos y
        comisionados desde los países indicados abajo es necesario considerar el
        incremento en el valor total referente a las tasas de retención o IVA.
      </p>

      <img
        src="@/assets/withholding-tax.png"
        alt="with holding tax table"
        class="img-fluid"
      />

      <p class="sample-quality-text">
        Aceptando esta cotización se acepta la posibilidadque haya un error
        muestra/calidad no superior al 5% de la muestra.
      </p>

      <span>
        Actualización: Noviembre 2023
      </span>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
section {
  padding: 48px 15px;

  @media (max-width: 500px) {
    padding: 0 15px;
  }
}

.container {
  padding: 40px 0;

  div {
    .main {
      margin-bottom: 15px;
      margin-left: 20px;
    }

    p {
      margin-left: 45px;
      margin-bottom: 5px;
    }

    margin-bottom: 30px;
  }
}

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #4d85d8;
  margin: 35px 0 15px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767.98px) {
    font-size: 20px;
  }
}

h3 {
  font-weight: 700;
  font-size: 18px;
  color: #707070;
  margin-bottom: 15px;
  margin-left: 20px;

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 30px;
  margin-left: 20px;

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}

span {
  display: block;
  color: #f99746;
  font-style: italic;
  margin-top: 70px;
  font-size: 18px;
}

.sample-quality-text {
  font-weight: 700;
  font-size: 18px;
  color: #707070;

  font-style: italic;

  margin-top: 15px;

  @media (max-width: 767.98px) {
    font-size: 16px;
  }
}
</style>
