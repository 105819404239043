<template>
  <section class="py-5">
    <div>
      <h2>
        {{
          $store.getters.trans
            ? $store.getters.trans.culture_page.our_offices
            : "Our Offices"
        }}
      </h2>
      <div
        class="d-flex justify-content-center selectOffices mt-5 mb-5 align-items-center"
      >
        <p @click="option = 'bh'" :class="{ active: option === 'bh' }" id="bh">
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.brasil
              : "Brazil"
          }}
        </p>
        <div class="lineOffices"></div>
        <p
          @click="option = 'bogota'"
          :class="{ active: option === 'bogota' }"
          id="bogota"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.colombia
              : "Colombia"
          }}
        </p>
        <div class="lineOffices"></div>
        <p
          @click="option = 'argentina'"
          :class="{ active: option === 'argentina' }"
          id="argentina"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.argentina
              : "Argentina"
          }}
        </p>
        <div class="lineOffices"></div>
        <p
          @click="option = 'chile'"
          :class="{ active: option === 'chile' }"
          id="chile"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.chile
              : "Chile"
          }}
        </p>
        <div class="lineOffices"></div>
        <p
          @click="option = 'eua'"
          :class="{ active: option === 'eua' }"
          id="eua"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.usa
              : "USA"
          }}
        </p>
        <div class="lineOffices"></div>
        <p
          @click="option = 'mexico'"
          :class="{ active: option === 'mexico' }"
          id="mexico"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.mexico
              : "México"
          }}
        </p>
        <div class="lineOffices"></div>
        <p
          @click="option = 'peru'"
          :class="{ active: option === 'peru' }"
          id="peru"
        >
          {{
            $store.getters.trans
              ? $store.getters.trans.culture_page.countries.peru
              : "peru"
          }}
        </p>
      </div>
      <div class="col d-flex justify-content-center d-lg-none">
        <div class="select-office">
          <b-form-select v-model="select" class="mt-4 mb-4">
            <b-form-select-option value="bh">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.brasil
                  : "Brazil"
              }}
            </b-form-select-option>
            <b-form-select-option value="bogota">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.colombia
                  : "Colombia"
              }}
            </b-form-select-option>
            <b-form-select-option value="argentina">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.argentina
                  : "Argentina"
              }}
            </b-form-select-option>
            <b-form-select-option value="chile">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.chile
                  : "Chile"
              }}
            </b-form-select-option>
            <b-form-select-option value="eua">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.usa
                  : "USA"
              }}
            </b-form-select-option>
            <b-form-select-option value="mexico">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.mexico
                  : "México"
              }}
            </b-form-select-option>
            <b-form-select-option value="peru">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.peru
                  : "peru"
              }}
            </b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="">
      <div class="py-3 d-flex align-items-center ml p-relative">
        <div class="offices col-lg-5 p-0">
          <img
            src="@/assets/img-bh.png"
            alt="belo horizonte offerwise office image"
            :class="{ active: option === 'bh' }"
            class="img-effect"
          />
          <img
            src="@/assets/img-bogota.png"
            alt="bogota offerwise office image"
            :class="{ active: option === 'bogota' }"
            class="img-effect"
          />
          <img
            src="@/assets/argentina.png"
            alt="argentina offerwise office image"
            :class="{ active: option === 'argentina' }"
            class="img-effect"
          />
          <img
            src="@/assets/chile.png"
            alt="chile offerwise office image"
            :class="{ active: option === 'chile' }"
            class="img-effect"
          />
          <img
            src="@/assets/eua.png"
            alt="eua offerwise office image"
            :class="{ active: option === 'eua' }"
            class="img-effect"
          />
          <img
            src="@/assets/mexico.png"
            alt="mexico offerwise office image"
            :class="{ active: option === 'mexico' }"
            class="img-effect"
          />
          <img
            src="@/assets/peru.png"
            alt="peru offerwise office image"
            :class="{ active: option === 'peru' }"
            class="img-effect"
          />
          <div class="numberOffice" :class="{ active: option === 'bh' }">
            <p class="city" :class="{ active: option === 'bh' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.brasil
                  : "Brazil"
              }}
            </p>
            <p class="city" :class="{ active: option === 'bogota' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.colombia
                  : "Colombia"
              }}
            </p>
            <p class="city" :class="{ active: option === 'argentina' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.argentina
                  : "Argentina"
              }}
            </p>
            <p class="city" :class="{ active: option === 'chile' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.chile
                  : "Chile"
              }}
            </p>
            <p class="city" :class="{ active: option === 'eua' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.united_states
                  : "United States"
              }}
            </p>
            <p class="city" :class="{ active: option === 'mexico' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.mexico
                  : "México"
              }}
            </p>
            <p class="city" :class="{ active: option === 'peru' }">
              {{
                $store.getters.trans
                  ? $store.getters.trans.culture_page.countries.peru
                  : "peru"
              }}
            </p>
          </div>
        </div>
        <div class="col-lg-7 maps" :class="{ active: option === 'bh' }">
          <img
            src="@/assets/map-brasil.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'bh' }"
          />
          <img
            src="@/assets/map-bogota.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'bogota' }"
          />
          <img
            src="@/assets/map-buenosaires.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'argentina' }"
          />
          <img
            src="@/assets/map-chile.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'chile' }"
          />
          <img
            src="@/assets/map-eua.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'eua' }"
          />
          <img
            src="@/assets/map-mexico.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'mexico' }"
          />
          <img
            src="@/assets/map-peru.jpg"
            alt="map"
            class="imgMap"
            :class="{ active: option === 'peru' }"
          />
        </div>
        <div class="ball"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      option: "bh",
      select: "bh"
    };
  },
  watch: {
    select() {
      this.option = this.select;
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  color: #000000;
}
.selectOffices p {
  margin: 0;
  min-width: 40px;
  padding: 5px;
  font-weight: 700;
  font-size: 0.875;
  cursor: pointer;
  color: #c4c4c4;
  transition: all 1s ease;
  text-transform: uppercase;
  &.active {
    color: #4f87da;
  }
}
.lineOffices {
  border-bottom: 2.5px solid #d1d3d4;
  width: 20px;
  height: 0;
  margin: 0 20px;
}
.select-office {
  width: 80%;
  outline: none;
}

.custom-select {
  padding: 0.375rem 1.75rem 0.375rem 1.1rem;
  font-size: 1rem;
  color: #495057;
}

.custom-select:focus {
  border-color: #ced4da;
  outline: none;
  box-shadow: none;
}

.offices {
  position: relative;
  display: flex;
  justify-content: flex-end;
  box-shadow: 40px 10px 51px -10px rgb(255, 255, 255);
  z-index: 50;
  background: #fff;
  img {
    width: 512px;
    height: 417.39px;
    display: none;
    animation: opacity 1s ease-in-out;
    &.active {
      display: block;
    }
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  /*5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }*/
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

#imgCircle {
  position: relative;
  z-index: 3;
}
section {
  position: relative;
}
.numberOffice {
  position: absolute;
  z-index: 50;
  top: calc(50% - 20px);
  right: -40px;
  &.bh {
    top: calc(50% - 63.5px);
  }
  .city {
    width: 200px;
    font-weight: 700;
    font-size: 3.125rem;
    color: #000000;
    line-height: 45.25px;
    display: none;
    animation: opacity 1s ease-in-out;
    &.active {
      display: block;
    }
  }
}
.ml {
  margin-left: 50px;
}

.maps {
  position: relative;
}
.imgMap {
  max-height: 300px;
  position: absolute;
  bottom: -150px;
  left: -310px;
  display: none;
  opacity: 0.75;
  animation: opacit 1s ease-in-out;
  &.active {
    display: block;
  }
}

@keyframes opacit {
  0% {
    opacity: 0;
  }
  /*5% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0.6;
  }*/
  50% {
    opacity: 0.35;
  }
  100% {
    opacity: 0.75;
  }
}

/*.ball {
  position: absolute;
  z-index: -10;
  width: 126px;
  height: 126px;
  border-radius: 50%;
  background: #4e86d8;
  right: -25px;
  bottom: 30px;
  overflow: hidden;
  animation: opacity 0.5s ease-in-out;
}*/

@media (max-width: 991.98px) {
  .ball {
    display: none;
  }
  .offices {
    margin-top: 30px;
    display: block;
    box-shadow: none;
    img {
      margin-left: calc(50% - 256px);
    }
  }

  .city-none {
    display: none;
  }

  .numberOffice {
    position: relative;
    left: calc(50% - 150px);
    top: 15px;
    max-width: 250px;
    .city {
      font-size: 2.8rem;
      line-height: 45.25px;
      width: 250px;
      text-align: center;
    }
  }
  .maps {
    display: none;
  }
  .selectOffices {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  #lineOffices {
    border-bottom: 2.5px solid #d1d3d4;
    width: 25px;
    height: 0;
  }

  .offices {
    margin-top: 30px;
    display: block;
    img {
      width: 450px;
      margin-left: calc(50% - 225px);
    }
  }

  .imgMap {
    display: none;
  }
}
@media (max-width: 575.98px) {
  section {
    margin-top: 3px;
  }
  .offices {
    margin-top: 30px;
    display: block;
    img {
      width: 300px;
      height: 250px;
      margin-left: calc(50% - 153px);
    }
  }
  .numberOffice {
    position: relative;
    left: calc(50% - 150px);
    top: 15px;
    min-width: 230px;
    .city {
      font-size: 2rem;
      line-height: 45.25px;
      min-width: 230px;
    }
    .number {
      font-size: 1.1rem;
      min-width: 230px;
    }
  }
}
@media (min-width: 1400px) {
  .ball {
    bottom: 60px;
  }
}
@media (min-width: 1650px) {
  .ball {
    bottom: 100px;
  }
}
@media (min-width: 1850px) {
  .ball {
    display: none;
  }
}

@media (min-width: 2000px) {
  .imgMap {
    max-height: 300px;
    position: absolute;
    bottom: -150px;
    left: 0px;
    display: none;
    opacity: 0.75;
    animation: opacit 1s ease-in-out;
    &.active {
      display: block;
    }
  }
}
</style>
