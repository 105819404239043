<template>
  <section class="intro">
    <div class="second-intro">
      <div class="container">
        <div class="itens-blog">
          <div class="pt-5 pb-5">
            <h1 class="title">Inside Market Research</h1>
            <p class="text-intro mt-4">
              Confira nossos artigos sobre o mundo da Pesquisa de Mercado
            </p>
          </div>

          <div class="carouselProduct">
            <carousel
              :perPage="1"
              :perPageCustom="[
                [830, 2],
                [1199, 3]
              ]"
              :navigationEnabled="true"
              :paginationEnabled="true"
              navigation-next-label=""
              navigation-prev-label=""
            >
              <slide>
                <a
                  href=""
                  class="readmore"
                  @click="$router.push('/openmatter')"
                >
                  <div class="box">
                    <img
                      class="img-blog flex-wrap"
                      src="@/assets/blog-1.png"
                      alt=""
                    />
                    <p class="title-blog">Processamento de dados</p>
                    <p class="paragraph-blog">0/0/2020</p>
                  </div>
                </a>
              </slide>
              <slide>
                <div class="box">
                  <img
                    class="img-blog flex-wrap"
                    src="@/assets/blog-2.png"
                    alt=""
                  />
                  <p class="title-blog">Processamento de dados</p>
                  <p class="paragraph-blog">0/0/2020</p>
                </div>
              </slide>
              <slide>
                <div class="box">
                  <img
                    class="img-blog flex-wrap"
                    src="@/assets/blog-3.png"
                    alt=""
                  />
                  <p class="title-blog">Processamento de dados</p>
                  <p class="paragraph-blog">0/0/2020</p>
                </div>
              </slide>
              <slide>
                <div class="box">
                  <img
                    class="img-blog flex-wrap"
                    src="@/assets/blog-3.png"
                    alt=""
                  />
                  <p class="title-blog">Processamento de dados</p>
                  <p class="paragraph-blog">0/0/2020</p>
                </div>
              </slide>
              <slide>
                <div class="box">
                  <img
                    class="img-blog flex-wrap"
                    src="@/assets/blog-3.png"
                    alt=""
                  />
                  <p class="title-blog">Processamento de dados</p>
                  <p class="paragraph-blog">0/0/2020</p>
                </div>
              </slide>
              <slide>
                <div class="box">
                  <img
                    class="img-blog flex-wrap"
                    src="@/assets/blog-3.png"
                    alt=""
                  />
                  <p class="title-blog">Processamento de dados</p>
                  <p class="paragraph-blog">0/0/2020</p>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {};
  },
  methods: {
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    },
    outsideClick(element, events, callback) {
      const html = document.documentElement;
      const outside = "data-outside";
      if (!element.hasAttribute(outside)) {
        events.forEach(userEvent => {
          setTimeout(() => {
            html.addEventListener(userEvent, () => {
              if (!element.contains(event.target)) {
                element.removeAttribute(outside);
                events.forEach(userEvent => {
                  html.removeEventListener(userEvent, this.handleOutsideClick);
                });
                callback();
              }
            });
          });
        });
        element.setAttribute(outside, "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.intro {
  background-color: white;
  height: 700px;
}

.second-intro {
  background-color: #f4f8fc;
  height: 400px;
}

.box {
  width: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.13);
  margin: 0 auto;
}

.img-blog {
  width: 100%;
}

.title-blog {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 1em;
  font-weight: bolder;
  max-width: 200px;
}

.paragraph-blog {
  padding-left: 20px;
  padding-bottom: 20px;
  color: #707070;
}

.dropdown-blog {
  > a {
    color: #000000;
    cursor: pointer;
    font-size: 0.8em;
    padding-left: 5%;
    padding-right: 5%;
  }
  &:hover {
    color: #8e8e8e;
    .drop-blog-item {
      display: block;
    }
  }
}

.drop-blog-item {
  width: 100%;
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  background: #fff;
  font-size: 0.8em;
  position: absolute;
  z-index: 5;
  top: 30px;
  a {
    display: block;
    color: #495057;
    padding: 2px 10px;
    &:hover,
    &.active {
      color: #666;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  &.active {
    display: block;
  }
}

.title {
  font-weight: 500;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.text-intro {
  font-weight: 500;
  color: #666;
  font-size: 14px;
  text-align: center;
}

.carouselProduct {
  .VueCarousel-slide {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
}
</style>
