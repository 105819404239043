<template>
  <section class="py-5">
    <div class="container">
      <div class="row flex-wrap">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/computer-qualitative.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.qualitative
                  : "Qualitative"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.communities
                  : "Communities"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .interact_with_your_targets
                  : "Interact with your targets in an online environment. Create tasks with researches, direct answers, and other resources to obtain valuable insights for your business. "
              }}.
            </p>
            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Qualitative - Communities' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/focus-qualitative.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.qualitative
                  : "Qualitative"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.focus_group
                  : "Focus Group"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .talk_to_your_consumers
                  : " Talk to your consumers and perceive how they express their opinions. Identify what makes them change their minds to create more effective business strategies. "
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Qualitative - Focus Group' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/calendar-qualitative.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.qualitative
                  : "Qualitative"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.consumer_diaries
                  : "Consumer diaries"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions.comprehend_details
                  : "Comprehend the details about your client's consumption habits. Know what they buy, on which frequency, and how your product is a part of these behaviors."
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Qualitative - Consumer diaries' }
                }"
                >{{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="img-box">
              <img src="@/assets/depth.svg" alt="" />
            </div>
            <p class="type mb-4">
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.qualitative
                  : "Qualitative"
              }}
            </p>
            <h3>
              {{
                $store.getters.trans
                  ? $store.getters.trans.home.tests.in_depth_interview
                  : "In-depth Interview"
              }}
            </h3>
            <p class="description">
              {{
                $store.getters.trans
                  ? $store.getters.trans.research_solutions
                      .conduct_in_depth_interviews
                  : "Conduct in-depth interviews with consumers and know their opinions regarding your brand. "
              }}.
            </p>

            <div class="getQuote">
              <router-link
                :to="{
                  name: 'requestabid',
                  query: { path: 'Qualitative - In-depth Interview' }
                }"
              >
                {{
                  $store.getters.trans
                    ? $store.getters.trans.research_solutions.quantitative
                        .request_bid
                    : "Request a bid"
                }}
                ></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  width: 288px;
  min-height: 360px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 5px rgba(42, 109, 172, 0.1);
  padding: 40px 20px 25px 20px;
  margin-bottom: 40px;
  margin-left: calc(50% - 144px);
  .img-box {
    min-height: 84px;
  }
  .type {
    font-weight: 500;
    font-size: 0.625rem;
    color: #eb858c;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  h3 {
    font-weight: 700;
    font-size: 1.187rem;
    color: #3f4451;
  }
  .description {
    font-weight: 400;
    font-size: 0.8125rem;
    color: #3f4451;
    min-height: 114px;
  }
  .getQuote a {
    font-weight: 600;
    font-size: 0.8rem;
    color: #eb858c;
  }
  .getQuote a:hover {
    text-decoration: none;
    color: #eb858c;
  }
}
</style>
